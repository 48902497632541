import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import Head from "next/head";

import { DefaultSeo } from "next-seo";

import "@/styles/globals.css";
import "@/styles/fonts/nunito.css";
import "@/styles/fonts/roboto.css";
import "@/styles/fonts/twemoji.css";

import { GOOGLE_ANALYTICS, MICROSOFT_CLARITY, COMMIT_HASH, SHOW_COMMIT_HASH } from "@/constants";
import { PETROL, THEME } from "@/colors";

import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";

import { tailwindCascade } from "@/helpers/tailwindCascade";
import useSEO from "@/hooks/useSEO";

import Layout from "@/components/Layout";
import ErrorBoundary from "@/components/ErrorBoundary";
import useAuthStore from "@/stores/auth";
import usePlayStore from "@/stores/play";
import { getUserSelf } from "@/api/user";
import { useRouter } from "next/router";
import { googleAnalyticsEvent } from "@/helpers/gtag";
import HostBrowsinQuizzesSocket from "@/components/HostBrowsinQuizzesSocket";
import LayoutBackground from "@/components/LayoutBackground";
import isUUID from "@/helpers/isUUID";
import useAIStore from "@/stores/ai";
import useStatusWithProgressStore from "@/stores/statusWithProgress";

function GoogleAnalytics() {
	if (GOOGLE_ANALYTICS) {
		return (
			<>
				{/* Global Site Tag (gtag.js) - Google Analytics */}
				<script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS}`} />
				<script
					dangerouslySetInnerHTML={{
						__html: `
							window.dataLayer = window.dataLayer || [];
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							gtag('config', '${GOOGLE_ANALYTICS}', {
								page_path: window.location.pathname,
							});
						`,
					}}
				/>
			</>
		);
	}

	return null;
}

function MicrosoftClarity() {
	if (MICROSOFT_CLARITY) {
		return (
			<>
				<script
					dangerouslySetInnerHTML={{
						__html: `
							(function(c,l,a,r,i,t,y){
								c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
								t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
								y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
							})(window, document, "clarity", "script", "${MICROSOFT_CLARITY}");
						`,
					}}
				/>
			</>
		);
	}

	return null;
}

export default function App({ Component, pageProps }) {
	const layoutProps = useMemo(() => {
		let layoutProps = Component ? (Component.getLayoutProps ? Component.getLayoutProps(pageProps) : {}) : {};
		if (!isObject(layoutProps)) {
			layoutProps = {};
		}
		return layoutProps;
	}, [Component, pageProps]);

	const [commitHashHidden, setCommitHashHidden] = useState(false);
	const onClickCommitHash = useCallback(() => void setCommitHashHidden(!commitHashHidden), [commitHashHidden]);

	const user = useAuthStore((state) => state.user);
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const setAuthStore = useAuthStore((state) => state.set);

	const router = useRouter();
	const seo = useSEO();

	useEffect(() => {
		(async () => {
			if (isAuthenticated) {
				if (!user || isEmpty(user)) {
					setAuthStore(async (draft) => {
						draft.user = await getUserSelf();
					});
				}
			} else {
				setAuthStore(async (draft) => {
					draft.user = null;
				});
			}
		})();
	}, [isAuthenticated, user, setAuthStore]);

	useEffect(() => {
		let isPlayQuizPage = false;
		let isAIPage = false;
		if (router.asPath.startsWith("/play/")) {
			const split = router.asPath.split("/");
			if (split.length >= 3) {
				const page = split[2];
				if (page && (isUUID(page) || page === "vote" || page === "ai")) {
					isPlayQuizPage = true;
					if (page === "ai") {
						isAIPage = true;
					}
				}
			}
		}
		if (!isPlayQuizPage) {
			usePlayStore.getState().reset(null, false, true);
			useStatusWithProgressStore.getState().reset();
			// Empty AI store
			if (!isAIPage) {
				useAIStore.getState().reset();
			}
		}
	}, [router.asPath]);

	useEffect(() => {
		const handleRouteChange = (url) => void googleAnalyticsEvent(url);
		router.events.on("routeChangeComplete", handleRouteChange);

		return () => {
			router.events.off("routeChangeComplete", handleRouteChange);
		};
	}, [router.events]);

	useEffect(() => {
		if (typeof window !== "undefined" && typeof window.history !== "undefined") {
			const scrollRestoration = window.history.scrollRestoration;
			window.history.scrollRestoration = "manual";
			return () => void (window.history.scrollRestoration = scrollRestoration);
		}
	}, []);

	useEffect(() => {
		if (typeof window !== "undefined" && window.visualViewport) {
			const onResize = () => {
				const { width, height } = window.visualViewport;
				const documentElement = document.documentElement;
				if (documentElement) {
					documentElement.style.setProperty("--viewport-width", `${width}px`);
					documentElement.style.setProperty("--viewport-height", `${height}px`);
				}
			};

			onResize(); // Initial resize

			window.visualViewport.addEventListener("resize", onResize);
			return () => void window.visualViewport.removeEventListener("resize", onResize);
		}
	}, []);

	return (
		<>
			<Head>
				<GoogleAnalytics />
				<MicrosoftClarity />
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
				<link rel="icon" type="image/x-icon" sizes="16x16 32x32 48x48" href="/favicon/favicon-2.ico" />
				<link
					rel="apple-touch-icon"
					type="image/png"
					sizes="180x180"
					href="/favicon/apple-touch-icon-180x180-2.png"
				/>
				<link rel="icon" type="image/png" sizes="48x48" href="/favicon/favicon-48x48-2.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32-2.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16-2.png" />
				<link rel="manifest" href="/favicon/site-2.webmanifest" />
			</Head>
			<DefaultSeo {...seo} />
			<ErrorBoundary>
				<LayoutBackground color={THEME} />
				<Layout {...layoutProps}>
					<Component {...pageProps} />
				</Layout>
				<HostBrowsinQuizzesSocket {...layoutProps} />
			</ErrorBoundary>
			{SHOW_COMMIT_HASH && COMMIT_HASH && (
				<button
					onClick={onClickCommitHash}
					className={tailwindCascade(
						"right-2",
						"bottom-2",
						"bg-coal-dark",
						"fixed",
						"p-2",
						"text-xs",
						"text-white",
						"rounded-md",
						{ hidden: commitHashHidden }
					)}
				>
					{COMMIT_HASH.substring(0, 8)}
				</button>
			)}
		</>
	);
}
