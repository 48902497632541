import status from "statuses";

export default class ResponseError extends Error {
	constructor(responseStatus, isPrivate = false) {
		super(status(responseStatus));
		this.name = "ResponseError";
		this.status = responseStatus;
		this.private = isPrivate;
	}
}
