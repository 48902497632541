import { postData } from "@/api/helpers";
import useAuthStore from "@/stores/auth";
import { API_URL } from "@/constants";

export async function login(email, password) {
	const auth = await postData(
		"/auth/login/",
		{
			email,
			password,
		},
		false
	);

	if (auth && auth.accessToken && auth.refreshToken) {
		useAuthStore.getState().signIn(auth.accessToken, auth.refreshToken, auth.expiresIn, auth.user);
		return true;
	}

	useAuthStore.getState().signOut();
	return false;
}

export async function register(name, email, password) {
	const auth = await postData(
		"/auth/register/",
		{
			name,
			email,
			password,
		},
		false
	);

	if (auth && auth.accessToken && auth.refreshToken) {
		useAuthStore.getState().signIn(auth.accessToken, auth.refreshToken, auth.expiresIn, auth.user);
		return true;
	}

	useAuthStore.getState().signOut();
	return false;
}

export async function getNewAccessToken() {
	const refreshToken = useAuthStore.getState().refreshToken;
	if (refreshToken) {
		const response = await window.fetch(`${API_URL}/auth/refreshtoken/`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${refreshToken}`,
			},
		});
		if (response.ok) {
			const auth = await response.json();
			if (auth && auth.accessToken && auth.refreshToken) {
				useAuthStore.getState().signIn(auth.accessToken, auth.refreshToken, auth.expiresIn, auth.user);
				return auth.expiresIn;
			}
		}
	}

	return null;
}

export async function resetPassword(email) {
	const response = await postData(
		"/auth/password/",
		{
			email,
		},
		false
	);

	return response.success;
}

export async function googleToken(code) {
	const response = await postData(
		"/auth/google/token/",
		{
			code,
		},
		false
	);

	if (response && response.accessToken && response.refreshToken && response.expiresIn) {
		useAuthStore.getState().signIn(response.accessToken, response.refreshToken, response.expiresIn, response.user);
		return true;
	}

	useAuthStore.getState().signOut();
	return false;
}

export async function sendEmailConfirmation(email) {
	const response = await postData("/auth/email/confirmation/", {}, true);
	return response.success;
}

export function continueAsGuest() {
	useAuthStore.getState().set((state) => void (state.isGuestUser = true));
}
