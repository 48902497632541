import { useState, useEffect, useRef } from "react";

export default function useRefMounted(initialValue = false) {
	const [mounted, setMounted] = useState(initialValue);
	const mountedRef = useRef(mounted);
	useEffect(() => {
		setMounted((mountedRef.current = true));
		return () => void setMounted((mountedRef.current = false));
	}, []);

	return [mounted, mountedRef];
}
