import React, { Component } from "react";

import { isString } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";

import { ERROR_REQUEST_ENDPOINT } from "@/constants";

function generateErrorMessage(event) {
	try {
		const contains = (message, pattern, index = -1) =>
			message &&
			pattern &&
			(index === -1 ? (message.indexOf(pattern) !== index) !== -1 : message.indexOf(pattern) === index);

		const message = event?.message ?? null;
		const lineno = lineno ?? 0;
		const colno = event?.colno ?? 0;

		// Ignore VivoBrowser: "Uncaught ReferenceError: processRandomSelector is not defined"
		if (contains(message, "Uncaught ReferenceError: processRandomSelector is not defined", 0)) {
			// console.error(event);
			return null;
		}

		if (contains(message, "ResizeObserver", 0)) {
			// console.error(event);
			return null;
		}

		// Ignore react minify error: https://reactjs.org/docs/error-decoder.html?invariant=423
		if (contains(message, "Uncaught Error: Minified React error #423;", 0)) {
			// console.error(event);
			return null;
		}

		// Dont't log Zalo error, Some site requesting our site with this url: https://quiz.com/?zarsrc=31&utm_source=zalo&utm_medium=zalo&utm_campaign=zalo
		if (contains(message, "zaloJSV2")) {
			// console.error(event);
			return null;
		}
		if (contains(message, "utm_source=zalo")) {
			// console.error(event);
			return null;
		}

		// Generic error, ignored
		if (contains(message, "Script error.", 0) && lineno === 0 && colno === 0) {
			// console.error(event);
			return null;
		}

		let output = "[Error start]----------------------------\n";

		output += `Url: ${window.location}\n`;
		output += `User agent: ${window.navigator.userAgent}\n`;

		if (message) {
			output += `Message: ${message}\n`;
		}

		if (event?.filename) {
			output += `Filename: ${event.filename}\n`;
		}

		if (lineno) {
			output += `Line: ${lineno}\n`;
		}

		if (colno) {
			output += `Column: ${colno}\n`;
		}

		if (event?.reason) {
			output += `Reason:\n${JSON.stringify(event.reason)}\n`;
		}

		if (event?.error) {
			if (isString(event.error)) {
				output += `Error:\n${event.error}\n`;
			} else {
				if (event.error.message) {
					output += `Error message:\n${JSON.stringify(event.error.message)}\n`;
				}
				if (event.error.stack) {
					output += `Error stack:\n${JSON.stringify(event.error.stack)}\n`;
				}
			}
		}

		if (event?.errorInfo?.componentStack) {
			output += `Component stack:\n${event.errorInfo.componentStack}\n`;
		}

		output = "------------------------------[Error end]\n\n";

		return output;
	} catch (error) {
		//
	}

	return null;
}

let previousEvent = null;

const ErrorBoundary =
	process.env.NODE_ENV === "production"
		? class ErrorBoundary extends Component {
				constructor(props) {
					super(props);

					this._bindedOnError = null;
					this._bindedOnUnhandledRejection = null;
				}

				_onError(event) {
					// Don't log multiple errors
					if (event) {
						const clonedPreviousEvent = previousEvent ? cloneDeep(previousEvent) : null;
						const clonedEvent = cloneDeep(event);
						previousEvent = clonedEvent;

						if (isObject(clonedEvent) && isObject(clonedPreviousEvent)) {
							clonedEvent.timeStamp = null;
							clonedPreviousEvent.timeStamp = null;
							if (isEqual(clonedEvent, clonedPreviousEvent)) {
								return;
							}
						}
					}

					const errorMessage = generateErrorMessage(event);
					if (errorMessage) {
						fetch(ERROR_REQUEST_ENDPOINT, {
							method: "POST",
							body: generateErrorMessage(event),
						}).catch((error) => console.error(error));
					}
				}

				_onUnhandledRejection(event) {
					const errorMessage = generateErrorMessage(event);
					if (errorMessage) {
						console.error(errorMessage);
					}
				}

				componentDidCatch(error, errorInfo) {
					const errorMessage = generateErrorMessage({ error, errorInfo });
					if (errorMessage) {
						console.error(errorMessage);
					}
				}

				componentDidMount() {
					window.addEventListener("error", (this._bindedOnError = (event) => this._onError(event)));
					window.addEventListener(
						"unhandledrejection",
						(this._bindedOnUnhandledRejection = (event) => this._onUnhandledRejection(event))
					);
				}

				componentWillUnmount() {
					if (this._bindedOnError) {
						window.removeEventListener("error", this._bindedOnError);
						this._bindedOnError = null;
					}

					if (this._bindedOnUnhandledRejection) {
						window.removeEventListener("unhandledrejection", this._bindedOnUnhandledRejection);
						this._bindedOnUnhandledRejection = null;
					}
				}

				render() {
					return this.props.children;
				}
		  }
		: function ErrorBoundary({ children }) {
				return children;
		  };

export default ErrorBoundary;
