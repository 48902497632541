import React, { useEffect, useMemo, useState } from "react";

import InfoBar from "@/components/pages/play/InfoBar";

import { getConnectedPlayers } from "@/helpers/player";

import usePlayStore from "@/stores/play";
import useSettingsStore from "@/stores/settings";
import useStatusWithProgressStore from "@/stores/statusWithProgress";
import useWebSocketStore from "@/stores/webSocket";

export default function InfoBarHost({ isHost = true, playMode = false, showInforBarIfDisconnected = false }) {
	const quiz = usePlayStore((state) => state.quiz);
	const slideIndex = usePlayStore((state) => state.slideIndex);
	const statusName = useStatusWithProgressStore((state) => state.name);
	const players = usePlayStore((state) => state.players);
	const code = usePlayStore((state) => state.code);

	const connected = useWebSocketStore((state) => state.connected);

	const showCode = useSettingsStore((state) => !!state.showCode);
	const joinOpen = useSettingsStore((state) => !!state.joinOpen);
	const numQuestionsPerRound = useSettingsStore((state) => state.numQuestionsPerRound);

	const slideType = useMemo(() => quiz?.slides?.[slideIndex]?.type || null, [quiz, slideIndex]);
	const quizName = useMemo(() => quiz?.name || "", [quiz]);
	const numberOfSlides = useMemo(() => quiz?.slides?.length || 0, [quiz]);

	const connectedPlayers = useMemo(() => getConnectedPlayers([...players.values()]), [players]);
	const numberOfPlayers = useMemo(() => connectedPlayers.length, [connectedPlayers]);
	const numberOfPlayersAnswered = useMemo(
		() => connectedPlayers.filter((player) => slideIndex in player.history ?? {}).length,
		[connectedPlayers, slideIndex]
	);
	const quickMode = usePlayStore((state) => state.quickMode);
	const previewMode = usePlayStore((state) => state.previewMode);

	return (
		<InfoBar
			connected={connected}
			isHost={isHost}
			playMode={playMode}
			statusName={statusName}
			code={code}
			quizName={quizName}
			slideType={slideType}
			slideIndex={slideIndex}
			numberOfPlayers={numberOfPlayers}
			numberOfPlayersAnswered={numberOfPlayersAnswered}
			numberOfSlides={numberOfSlides}
			showCode={showCode}
			joinOpen={joinOpen}
			mute={false}
			quickMode={quickMode}
			numQuestionsPerRound={numQuestionsPerRound}
			previewMode={previewMode}
			showInforBarIfDisconnected={showInforBarIfDisconnected}
		/>
	);
}
