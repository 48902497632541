import React, { memo } from "react";

import isEqual from "lodash/isEqual";

import shade from "@/helpers/shade";
import withSideEffect from "@/helpers/withSideEffect";

import { THEME } from "@/colors";

const initialState = {
	color: THEME,
	duration: 0,
};

function reducePropsToState(propsList) {
	const state = { ...initialState };
	if (propsList && propsList.length > 0) {
		for (let i = propsList.length - 1, keys = Object.keys(initialState); i >= 0; i--) {
			for (let j = 0; j < keys.length; j++) {
				if (Object.prototype.hasOwnProperty.call(propsList[i], keys[j])) {
					state[keys[j]] = propsList[i][keys[j]];
				}
			}
		}
	}
	return state;
}

function handleStateChangeOnClient(state) {
	const documentElement = document.documentElement;
	if (documentElement) {
		documentElement.style.setProperty("--background-color", state.color);
		documentElement.style.setProperty("--background-color-light", shade(state.color, 0.125));
		documentElement.style.setProperty("--background-color-dark", shade(state.color, -0.1));
		documentElement.style.setProperty("--background-transition-duration", `${state.duration}s`);
	}

	const tileColor = document.querySelector(`head>meta[name="msapplication-TileColor"]`);
	if (tileColor) {
		tileColor.setAttribute("content", state.color);
	}

	const themeColor = document.querySelector(`head>meta[name="theme-color"]`);
	if (themeColor) {
		themeColor.setAttribute("content", state.color);
	}
}

const LayoutBackground = () => null;
const LayoutBackgroundWithSideEffect = withSideEffect(
	reducePropsToState,
	handleStateChangeOnClient,
	() => LayoutBackgroundWithSideEffect.rewind() || { ...initialState }
)(LayoutBackground);

function getInitialProps() {
	const props = LayoutBackgroundWithSideEffect.rewind();
	const initialProps = { ...initialState };
	if (props) {
		for (let i = 0, keys = Object.keys(initialState); i < keys.length; i++) {
			if (Object.prototype.hasOwnProperty.call(props, keys[i])) {
				initialProps[keys[i]] = props[keys[i]];
			}
		}
	}

	initialProps.htmlStyleAttribute = {
		"--background-color": initialProps.color,
		"--background-color-light": shade(initialProps.color, 0.125),
		"--background-color-dark": shade(initialProps.color, -0.1),
		"--background-transition-duration": `${initialProps.duration}s`,
	};

	return initialProps;
}

const MemorizedLayoutBackgroundWithSideEffect = memo(LayoutBackgroundWithSideEffect, isEqual);
MemorizedLayoutBackgroundWithSideEffect.getInitialProps = getInitialProps;

export default MemorizedLayoutBackgroundWithSideEffect;
