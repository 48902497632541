import { Howl } from "@/audio/howler/howler";
import isFinite from "lodash/isFinite";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import sample from "lodash/sample";
import { COMMIT_HASH } from "@/constants";

const MAX_SFX_VOLUME = 0.5;

class SoundSpritePlayer {
	constructor(url, maxVolume = MAX_SFX_VOLUME, queueCommands = false) {
		this._url = url;
		this._maxVolume = maxVolume;
		this._queueCommands = queueCommands;

		this._sound = null;
		this._soundIds = new Map();
		this._loading = false;
		this._queue = [];
	}

	_queueCommand(command, ...args) {
		if (this._queueCommands) {
			this._queue.push([command, args]);
		}
	}

	_flushQueue() {
		while (this._queue.length) {
			const command = this._queue.shift();
			this[command[0]].apply(this, command[1]);
		}
	}

	_load(volume) {
		if (!this._sound && !this._loading) {
			this._loading = true;

			const self = this;
			fetch(this._url)
				.then((response) => response.json())
				.then((options) => {
					if (COMMIT_HASH) {
						options.src = `${options.src}?hash=${encodeURIComponent(COMMIT_HASH.substring(0, 8))}`;
					}
					const sound = new Howl({
						...options,
						preload: true,
						volume: volume * self._maxVolume,
						onload: () => {
							self._loading = false;
							self._sound = sound;
							self._flushQueue();
						},
						onloaderror: () => {
							self._loading = false;
							self._sound = null;
						},
						onend: (id) => {
							for (const [key, value] of self._soundIds.entries()) {
								if (id === value) {
									self._soundIds.delete(key);
								}
							}
						},
					});
				});
		}
	}

	preload(volume) {
		this._load(volume);
	}

	unload() {
		this._sound = null;
	}

	volume(volume) {
		if (this._sound) {
			try {
				this._sound.volume(volume * this._maxVolume);
			} catch (error) {
				console.error(error);
			}
		}
	}

	play(nameOrId = null, loop = false, lowerVolume) {
		if (isArray(nameOrId)) {
			nameOrId = sample(nameOrId); // Sample gets a random element from an array.
		}

		try {
			if (this._sound) {
				let id = -1;
				if (isFinite(nameOrId) || isString(nameOrId)) {
					try {
						id = this._sound.play(nameOrId);
						if (id !== -1 && lowerVolume) {
							const currentVolume = this._sound.volume();
							const newVolume = currentVolume * lowerVolume;
							this._sound.volume(newVolume, id);
						}
					} catch (error) {
						console.error(error);
					}
					if (isString(nameOrId) && id !== -1) {
						this._soundIds.set(nameOrId, id);
					}
				} else {
					try {
						id = this._sound.play();
					} catch (error) {
						// console.error(error);
					}
				}
				if (isFinite(id) && id !== -1) {
					try {
						this._sound.loop(loop, id);
					} catch (error) {
						console.error(error);
					}
					return id;
				}
			}
		} catch (error) {
			console.error(error);
		}

		return -1;
	}

	pause(nameOrId = null) {
		if (this._sound) {
			if (isFinite(nameOrId)) {
				if (nameOrId !== -1) {
					try {
						this._sound.pause(nameOrId);
					} catch (error) {
						console.error(error);
					}
				}
			} else if (isString(nameOrId) && this._soundIds.has(nameOrId)) {
				try {
					this._sound.pause(this._soundIds.get(nameOrId));
				} catch (error) {
					console.error(error);
				}
			} else {
				try {
					this._sound.pause();
				} catch (error) {
					console.error(error);
				}
			}
		}
	}

	stop(nameOrId = null) {
		if (this._sound) {
			if (isFinite(nameOrId)) {
				if (nameOrId !== -1) {
					try {
						this._sound.stop(nameOrId);
					} catch (error) {
						console.error(error);
					}
				}
			} else if (isString(nameOrId) && this._soundIds.has(nameOrId)) {
				try {
					this._sound.stop(this._soundIds.get(nameOrId));
				} catch (error) {
					console.error(error);
				}
			} else {
				try {
					this._sound.stop();
				} catch (error) {
					console.error(error);
				}
			}
		}
	}
}

let url = "/audio/sfx.json";
if (COMMIT_HASH) {
	url = `${url}?hash=${encodeURIComponent(COMMIT_HASH.substring(0, 8))}`;
}

export const sfx = new SoundSpritePlayer(url, MAX_SFX_VOLUME);

export const WRONG_ANSWER_SOUNDS = [
	"wrongAnswer1",
	"wrongAnswer2",
	"wrongAnswer3",
	"wrongAnswer4",
	"wrongAnswer5",
	"wrongAnswer6",
	"wrongAnswer7",
	"wrongAnswer8",
	"wrongAnswer9",
	"wrongAnswer10",
	"wrongAnswer11",
	"wrongAnswer12",
	"wrongAnswer13",
	"wrongAnswer14",
];

export const ANSWER_RECEIVED_SOUNDS = [
	"answerRecieved1",
	"answerRecieved2",
	"answerRecieved3",
	"answerRecieved4",
	"answerRecieved5",
];

class VoicePlayer {
	constructor(maxVolume = MAX_SFX_VOLUME) {
		this._maxVolume = maxVolume;
		this._volume = 1.0;
		this._sounds = new Set(); // all the sounds which have been loaded
	}

	setVolume(volume) {
		this._volume = Math.min(Math.max(volume, 0.0), 1.0);

		for (const sound of this._sounds) {
			try {
				sound.volume(this._volume * this._maxVolume);
			} catch (error) {
				console.error(error);
			}
		}
	}

	load(url = null, callback = null) {
		const sound = new Howl({
			src: [url],
			volume: this._volume * this._maxVolume,
			preload: true,
			onload: () => {
				if (callback) {
					callback(null, sound);
				}
			},
			onloaderror: (soundId, errorMessage) => {
				if (callback) {
					callback(errorMessage);
				}
			},
			onplayerror: () => {
				if (callback) {
					callback("playerror");
				}
			},
		});

		this._sounds.add(sound);

		return sound;
	}

	unload(sound) {
		if (sound) {
			try {
				sound.unload();
			} catch (error) {
				console.error(error);
			}

			if (this._sounds.has(sound)) {
				this._sounds.delete(sound);
			}
		}
	}

	play(sound, onEnd = () => {}, onError = () => {}) {
		if (sound) {
			try {
				sound
					.on("end", () => {
						sound.seek(0); // so resume() knows not to play this sound
						onEnd();
					})
					.on("playerror", () => void onError());
				sound.play();
			} catch (error) {
				console.error(error);
				onError();
			}
		}
	}

	stop(sound) {
		if (sound) {
			try {
				sound.stop();
			} catch (error) {
				console.error(error);
			}
		}
	}

	pause() {
		for (const sound of this._sounds) {
			try {
				sound.pause();
			} catch (error) {
				console.error(error);
			}
		}
	}

	resume() {
		for (const sound of this._sounds) {
			try {
				if (sound.seek() > 0) {
					sound.play();
				}
			} catch (error) {
				console.error(error);
			}
		}
	}
}

export const voicePlayer = new VoicePlayer(1.0);
