import create from "zustand";
import { persist } from "zustand/middleware";
import { CookieStorage } from "cookie-storage";
import produce from "immer";

const expires = new Date();
expires.setDate(expires.getDate() + 30); // 30 days

const cookieStorage = new CookieStorage({
	path: "/",
	// domain: "example.com",
	expires: expires,
	// secure: true,
	sameSite: "Strict", // Can be 'Strict' or 'Lax'.
});

const useAuthStore = create(
	persist(
		(set) => {
			return {
				accessToken: null,
				refreshToken: null,
				expiresIn: null,
				isAuthenticated: false,
				registerRedirectUrl: null,
				user: null,
				isGuestUser: false,
				adminFeaturesEnabled: false,

				updateRegisterRedirectUrl: (registerRedirectUrl) => set({ registerRedirectUrl }),
				updateUser: (user) => set(produce((draft) => void (draft.user = user))),

				signIn: (accessToken, refreshToken, expiresIn, user) => {
					return set({
						accessToken,
						expiresIn,
						refreshToken,
						isAuthenticated: !!(accessToken && refreshToken),
						user,
						isGuestUser: false,
						adminFeaturesEnabled: false,
					});
				},
				signOut: () => {
					return set({
						accessToken: null,
						expiresIn: null,
						refreshToken: null,
						isAuthenticated: false,
						user: null,
						isGuestUser: false,
						adminFeaturesEnabled: false,
					});
				},
				set: (recipe) => set(produce(recipe)),
			};
		},
		{
			name: "auth",
			getStorage: () => cookieStorage,
		}
	)
);

export default useAuthStore;
