import React, { useCallback, useEffect, useRef } from "react";

import Modal from "@/components/Modal";
import Settings from "@/components/pages/play/Settings";

import useSettingModalStore from "@/stores/settingsModal";

export default function SettingsModal({ isHost = true, mute = false }) {
	const visible = useSettingModalStore((state) => state.visible);
	const hide = useSettingModalStore((state) => state.hide);
	const onCancel = useCallback(() => void hide(false), [hide]);

	const hideRef = useRef(hide);
	useEffect(() => void (hideRef.current = hide), [hide]);
	useEffect(() => {
		if (hideRef.current) {
			hideRef.current(); // Initially hidden
		}
	}, []);

	return (
		visible && (
			<Modal className="md:mt-11 mt-9 z-2" onCancel={onCancel}>
				<div className="bg-petrol-dark md:right-4 top-4 md:max-w-sm absolute w-full rounded-lg max-h-[calc(100%-2.25rem)] overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white-50">
					<Settings isHost={isHost} mute={mute} inGame={true} onCancel={onCancel} />
				</div>
			</Modal>
		)
	);
}
