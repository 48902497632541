import trans from "@/helpers/trans";

import { COMMIT_HASH } from "@/constants";

let imageUrl = "https://quiz.com/images/share/default-1200x630.jpg";
if (COMMIT_HASH) {
	imageUrl = `${imageUrl}?hash=${encodeURIComponent(COMMIT_HASH.substring(0, 8))}`;
}

export default {
	titleTemplate: trans("%s - Quiz.com"),
	defaultTitle: trans("Quiz.com"),
	description: trans(
		"Play 1000s of free quizzes, or create your own. Challenge your friends on any device. Stream friendly."
	),

	openGraph: {
		type: "website",
		title: trans("Quiz.com"),
		description: trans(
			"Play 1000s of free quizzes, or create your own. Challenge your friends on any device. Stream friendly."
		),
		site_name: trans("Quiz.com"),
		images: [
			{
				url: imageUrl,
				width: 1200,
				height: 630,
			},
		],
	},

	twitter: {
		handle: "@handle",
		site: "@site",
		cardType: "summary_large_image",
	},

	additionalMetaTags: [
		{
			name: "apple-mobile-web-app-title",
			content: trans("Quiz.com"),
		},
		{
			name: "application-name",
			content: trans("Quiz.com"),
		},
	],
};
