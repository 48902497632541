import { useCallback } from "react";

import usePlayStore from "@/stores/play";
import useAudioStore from "@/stores/audio";
import useWebSocketStore from "@/stores/webSocket";
import useAIStore from "@/stores/ai";
import useStatusWithProgressStore from "@/stores/statusWithProgress";

export default function useStopHosting() {
	const stopAudio = useAudioStore((state) => state.stopAudio);

	const closeCurrentRoom = useWebSocketStore((state) => state.closeCurrentRoom);
	const disconnect = useWebSocketStore((state) => state.disconnect);

	return useCallback(
		(stopMusic) => {
			stopAudio(stopMusic);
			usePlayStore.getState().reset(null, true, true);
			useStatusWithProgressStore.getState().reset();
			useAIStore.getState().reset();
			closeCurrentRoom();
			disconnect(true);
		},
		[closeCurrentRoom, disconnect, stopAudio]
	);
}
