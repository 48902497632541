let localStorage = null;
try {
	if (typeof window !== "undefined") {
		localStorage = window.localStorage;
	}
} catch (error) {
	console.error(error);
}

const valuesMap = new Map();

function isQuotaExceeded(error) {
	if (error && error.code) {
		switch (error.code) {
			case 22:
				return true;
			case 1014:
				// Firefox
				if (error.name === "NS_ERROR_DOM_QUOTA_REACHED") {
					return true;
				}
		}
	}
	return false;
}

class LocalStorageGuard {
	get length() {
		if (localStorage) {
			return localStorage.length();
		} else {
			return valuesMap.size;
		}
	}

	clear() {
		if (localStorage) {
			localStorage.clear();
		} else {
			valuesMap.clear();
		}
	}

	getItem(key) {
		if (localStorage) {
			return localStorage.getItem(key);
		} else {
			const stringKey = String(key);
			if (valuesMap.has(key)) {
				return String(valuesMap.get(stringKey));
			}
			return null;
		}
	}

	key(index) {
		if (localStorage) {
			return localStorage.key(index);
		} else {
			if (arguments.length === 0) {
				throw new TypeError("Failed to execute 'key' on 'Storage': 1 argument required, but only 0 present."); // this is a TypeError implemented on Chrome, Firefox throws Not enough arguments to Storage.key.
			}
			var arr = Array.from(valuesMap.keys());
			return arr[index];
		}
	}

	removeItem(key) {
		if (localStorage) {
			localStorage.removeItem(key);
		} else {
			valuesMap.delete(key);
		}
	}

	setItem(key, value) {
		if (localStorage) {
			try {
				localStorage.setItem(key, value);
			} catch (error) {
				console.error(error);
				if (isQuotaExceeded(error)) {
					// Storage full, copy to valuesMap and clear
					try {
						valuesMap.clear();
						const keys = Object.keys(localStorage);
						for (let i = 0; i < keys.length; i++) {
							const key = keys[i];
							const value = localStorage.getItem(key);
							valuesMap.set(String(key), String(value));
						}
						localStorage.clear();
					} catch (error) {
						console.error(error);
					}
					valuesMap.set(String(key), String(value));
					localStorage = null;
				}
			}
		} else {
			valuesMap.set(String(key), String(value));
		}
	}
}

const localStorageGuard = new Proxy(new LocalStorageGuard(), {
	defineProperty(target, p, attributes) {
		target.setItem(p.toString(), String(attributes.value));
		return true;
	},
	deleteProperty(target, p) {
		target.removeItem(p.toString());
		return true;
	},
	get(target, p) {
		if (typeof p === "string" && p in target) {
			return target[p];
		}
		const result = target.getItem(p.toString());
		return result !== null ? result : undefined;
	},
	getOwnPropertyDescriptor(target, p) {
		if (p in target) {
			return undefined;
		}
		return {
			configurable: true,
			enumerable: true,
			value: target.getItem(p.toString()),
			writable: true,
		};
	},
	has(target, p) {
		if (typeof p === "string" && p in target) {
			return true;
		}
		return target.getItem(p.toString()) !== null;
	},
	ownKeys(target) {
		const keys = [];
		for (let i = 0; i < target.length; i++) {
			const key = target.key(i);
			if (key !== null) {
				keys.push(key);
			}
		}
		return keys;
	},
	preventExtensions() {
		throw new TypeError("can't prevent extensions on this proxy object");
	},
	set(target, p, value) {
		target.setItem(p.toString(), String(value));
		return true;
	},
});

export default localStorageGuard;
