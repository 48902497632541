import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { enableMapSet } from "immer";
import isBoolean from "lodash/isBoolean";
import isEqual from "lodash/isEqual";
import router from "next/router";
import { useImmer } from "use-immer";

import Header from "@/components/Header";
import Button from "@/components/interactives/Button";
import Button2 from "@/components/interactives/Button2";
import CheckboxSettings from "@/components/interactives/CheckboxSettings";
import SearchInput from "@/components/interactives/SearchInput";
import Selector from "@/components/interactives/Selector";
import Slider from "@/components/interactives/Slider";

import { languages } from "@/data/languages";

import isApp from "@/helpers/isApp";
import { getConnectedPlayers, isPlayerConnected } from "@/helpers/player";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import useStopHosting from "@/hooks/useStopHosting";

import ChevronLeftIcon from "@/images/icons/chevron-left.svg";
import CorrectIcon from "@/images/icons/icon-correct-checkbox-multicolor.svg";
import WrongIcon from "@/images/icons/icon-wrong-checkbox-multicolor.svg";

import useAudioStore from "@/stores/audio";
import useAuthStore from "@/stores/auth";
import usePlayStore from "@/stores/play";
import useSettingsStore from "@/stores/settings";
import useStatusWithProgressStore from "@/stores/statusWithProgress";
import useWebSocketStore from "@/stores/webSocket";

import { VOICES } from "@/app-constants.mjs";
import {
	DEFAULT_MEDIA_VOLUME,
	DEFAULT_MUSIC_VOLUME,
	DEFAULT_SFX_VOLUME,
	DEFAULT_VOICE_VOLUME,
	PLAY_STATUS_LOBBY,
	QUIZ_VOTEMODE_ID,
} from "@/constants";

enableMapSet();

function SettingsCheckbox({ name, options, description = "", comment = null, enabled = null, override, newBadge }) {
	const setSettingsStore = useSettingsStore((state) => state.set);
	const value = useSettingsStore((state) => state[name]);

	const overrideValue = override ? override() : undefined;

	return (
		<>
			<div>
				<CheckboxSettings
					id={name}
					className={`${comment ? "pt-0.5" : "pt-0"}`}
					checked={isBoolean(overrideValue) ? overrideValue : value === options[0]}
					onChange={(isChecked) => {
						if (!isBoolean(overrideValue)) {
							setSettingsStore((draft) => void (draft[name] = options[isChecked ? 0 : 1]));
						}
					}}
					disabled={enabled && !enabled()}
				/>
			</div>
			<div
				className={tailwindCascade("flex flex-col -space-y-0.5 text-base text-white", {
					"opacity-50": enabled && !enabled(),
				})}
			>
				<div className="flex flex-row items-center space-x-3">
					<span className={`mb-1 font-bold leading-[1.1] group-hover:underline ${comment ? "mt-0" : "mt-1"}`}>
						{description}
					</span>
					{newBadge && (
						<span className="bg-pink-light flex justify-center w-12 h-5 mb-2 text-sm font-bold rounded-md">
							{trans("NEW!")}
						</span>
					)}
				</div>
				{comment && <span className="text-sm leading-[1.1] text-white opacity-50">{comment}</span>}
			</div>
		</>
	);
}
function SettingsSelector({
	name,
	options,
	description = "",
	enabled = null,
	loadFunc = (value) => value,
	saveFunc = (value) => value,
}) {
	const setSettingsStore = useSettingsStore((state) => state.set);
	const value = useSettingsStore((state) => state[name]);

	return (
		<div className="flex flex-col items-start w-full">
			{description && <div className="pb-2 text-base text-white">{description}</div>}
			<Selector
				disabled={enabled && !enabled()}
				innerClassName="py-4"
				value={loadFunc(value)}
				onChange={(event) => {
					void setSettingsStore((draft) => void (draft[name] = saveFunc(event.target.value)));
				}}
			>
				{options.map((option, index) => (
					<option className="bg-petrol-dark" key={index} value={loadFunc(option.value)}>
						{option.text}
					</option>
				))}
			</Selector>
		</div>
	);
}
function SettingsSliderGroup({ items, enabled, playHoverFeedback }) {
	return (
		<div className="flex flex-col w-full">
			{items.map(
				(setting, index) =>
					setting.range && (
						<SettingsSlider
							key={index}
							name={setting.key}
							range={setting.range}
							description={setting.description}
							first={index === 0}
							last={index === items.length - 1}
							enabled={enabled}
							playHoverFeedback={playHoverFeedback}
						/>
					)
			)}
		</div>
	);
}

function SettingsSlider({ name, range, description = "", enabled = null, first, last, playHoverFeedback }) {
	const setSettingsStore = useSettingsStore((state) => state.set);
	const value = useSettingsStore((state) => state[name]);

	return (
		<div
			className="flex flex-row items-center flex-grow px-0 py-0 overflow-hidden"
			onMouseEnter={playHoverFeedback}
		>
			<label className="w-24 font-bold text-white">{description}</label>
			<Slider
				className="settings-slider my-2"
				value={value}
				setValue={(value) => void setSettingsStore((draft) => void (draft[name] = value))}
				{...range}
				disabled={enabled && !enabled()}
			/>
		</div>
	);
}

function PlayerItem({ connectionId, onChange, isChecked }) {
	const player = usePlayStore(useCallback((state) => state.players.get(connectionId), [connectionId]));

	const onChangeCheckBox = useCallback(
		(isChecked) => void (onChange && onChange(player, isChecked)),
		[player, onChange]
	);

	return (
		<div className="flex flex-row items-center h-8 space-x-4">
			<CheckboxSettings checked={isChecked} onChange={onChangeCheckBox} />
			<div className="font-bold text-white">{player?.name}</div>
		</div>
	);
}
const MemorizedPlayerItem = memo(PlayerItem, isEqual); // Use deep compariso

function KickPlayers({ players, kickSearchFilter, onKick }) {
	const [selectedPlayers, updateSelectedPlayers] = useImmer(new Map());
	const [numSelectedPlayers, setNumSelectedPlayers] = useState(0);
	const [selectAll, setSelectAll] = useState(false);

	const removePlayer = usePlayStore((state) => state.removePlayer);

	const onPlayerChange = useCallback(
		(player, selected) => {
			updateSelectedPlayers((draft) => draft.set(player.connectionId, selected));
			if (!selected) {
				setSelectAll(false);
			}
		},
		[updateSelectedPlayers]
	);

	useEffect(() => {
		for (const [key, player] of players) {
			if (selectedPlayers.has(key)) {
				if (!isPlayerConnected(player)) {
					updateSelectedPlayers((draft) => {
						draft.delete(key);
					});
				}
			} else {
				if (isPlayerConnected(player)) {
					updateSelectedPlayers((draft) => {
						draft.set(key, false);
					});
				}
			}
		}
		for (const [key, player] of selectedPlayers) {
			if (!players.has(key)) {
				updateSelectedPlayers((draft) => {
					draft.delete(key);
				});
			}
		}
	}, [players, selectedPlayers, updateSelectedPlayers]);

	useEffect(() => {
		let nrOfSelected = 0;
		for (const key of selectedPlayers.keys()) {
			if (selectedPlayers.get(key) === true) {
				nrOfSelected++;
			}
		}
		setNumSelectedPlayers(nrOfSelected);
	}, [selectedPlayers]);

	const banPlayerOnKick = useSettingsStore((state) => state.banPlayerOnKick);

	const onClickKickPlayers = useCallback(() => {
		for (const [key, selected] of selectedPlayers) {
			if (selected) {
				removePlayer(key);
				const { sendRoomMessage } = useWebSocketStore.getState();
				const roomMessage = banPlayerOnKick ? { ban: { connectionId: key } } : { kick: { connectionId: key } };
				sendRoomMessage(roomMessage, key);
			}
		}
		setSelectAll(false);
		if (onKick) {
			onKick();
		}
	}, [onKick, selectedPlayers, removePlayer, banPlayerOnKick]);

	const kickPlayers = useMemo(
		() =>
			getConnectedPlayers([...players.values()]).filter(
				(player) => player.name && player.name.toLowerCase().indexOf(kickSearchFilter.toLowerCase()) !== -1
			),
		[players, kickSearchFilter]
	);

	return (
		<div className="flex flex-col space-y-2">
			<label className="flex flex-row space-x-4">
				{kickSearchFilter.length === 0 && (
					<>
						<CheckboxSettings
							checked={selectAll}
							onChange={(isChecked) => {
								if (isChecked) {
									for (const key of selectedPlayers.keys()) {
										updateSelectedPlayers((draft) => draft.set(key, true));
									}
									setSelectAll(true);
								} else {
									for (const key of selectedPlayers.keys()) {
										updateSelectedPlayers((draft) => draft.set(key, false));
									}
									setSelectAll(false);
								}
							}}
						/>
						<p className="font-bold text-white">{trans("Select All")}</p>
					</>
				)}
			</label>
			<label className="pb-4">
				{kickPlayers.map((player) => {
					const isChecked = selectedPlayers.has(player.connectionId)
						? selectedPlayers.get(player.connectionId)
						: false;
					return (
						<MemorizedPlayerItem
							key={player.connectionId}
							connectionId={player.connectionId}
							onChange={onPlayerChange}
							checked={isChecked}
						/>
					);
				})}
			</label>
			<div className="w-full">
				<Button
					onClick={onClickKickPlayers}
					color="answer4"
					className="text-base text-black"
					border={true}
					disabled={numSelectedPlayers < 1}
				>
					{numSelectedPlayers > 0
						? numSelectedPlayers == 1
							? banPlayerOnKick
								? trans("Ban 1 player")
								: trans("Kick 1 player")
							: banPlayerOnKick
							? trans("Ban %d players", numSelectedPlayers)
							: trans("Kick %d players", numSelectedPlayers)
						: banPlayerOnKick
						? trans("Ban selected players")
						: trans("Kick selected players")}
				</Button>
			</div>
		</div>
	);
}
export default function Settings({
	className,
	mute = false,
	reset,
	inGame = false,
	isHost,
	quickMode,
	onCancel,
	disableYoutubeSetting,
	playHoverFeedback,
	...props
}) {
	const settingsStore = useSettingsStore((state) => state);
	const setSettingsStore = useSettingsStore((state) => state.set);
	const players = usePlayStore((state) => state.players);

	const musicVolume = useSettingsStore((state) => state.musicVolume);
	const setMusicVolume = useAudioStore((state) => state.setMusicVolume);
	const soundVolume = useSettingsStore((state) => state.soundVolume);
	const setSoundVolume = useAudioStore((state) => state.setSoundVolume);
	const voiceVolume = useSettingsStore((state) => state.voiceVolume);
	const setVoiceVolume = useAudioStore((state) => state.setVoiceVolume);

	const [enableVolumeBars, setEnableVolumeBars] = useState(false);
	const previewMode = usePlayStore((state) => state.previewMode);

	const teamMode = useSettingsStore((state) => state.teamMode);
	const joinOpen = useSettingsStore((state) => state.joinOpen);

	const settings = useMemo(
		() => [
			{
				header: "Play mode",
				enabled: () => useStatusWithProgressStore.getState().name === PLAY_STATUS_LOBBY,
				visible: () => false && isHost && quickMode,
			},
			{
				key: "numRoundsPerGame",
				options: [2, 3, 4].map((value) => ({ value, text: trans("%d rounds", value) })),
				defaultValue: 3,
				setDefaultIfNotVisible: true,
				enabled: () => useStatusWithProgressStore.getState().name === PLAY_STATUS_LOBBY,
				visible: () => false && useAuthStore.getState().user?.role === "admin" && isHost && quickMode,
				type: "integer",
			},
			{
				key: "quizSelectMode",
				options: [
					{ value: "quiz/vote", text: trans("Let players select quiz together") },
					// { value: "quiz/host", text: trans("Only the host can select") }
					{ value: "questions/random", text: trans("Play random questions, no selecting") },
				],
				defaultValue: "quiz/vote",
				enabled: () => useStatusWithProgressStore.getState().name === PLAY_STATUS_LOBBY,
				visible: () => false && isHost && quickMode,
			},
			{
				key: "numQuestionsPerRound",
				options: [{ value: 1, text: trans("Select new quiz after every question") }].concat(
					[2, 3, 4, 5].map((value) => ({ value, text: trans("Select new quiz after %d questions", value) }))
				),
				defaultValue: 4,
				setDefaultIfNotVisible: true,
				enabled: () =>
					useStatusWithProgressStore.getState().name === PLAY_STATUS_LOBBY &&
					useSettingsStore.getState().quizSelectMode.startsWith("quiz/"),
				visible: () => false && useAuthStore.getState().user?.role === "admin" && isHost && quickMode,
				type: "integer",
			},
			{
				header: "General",
				visible: () => isHost && !previewMode && inGame,
			},
			{
				description: trans("Joining is open"),
				key: "joinOpen",
				options: [true, false], // Checked, unchecke
				defaultValue: true,
				isSticky: false,
				visible: () => isHost && !previewMode && inGame,
			},
			{
				description: trans("Show PIN code"),
				key: "showCode",
				options: [true, false], // Checked, unchecke
				defaultValue: true,
				isSticky: false,
				visible: () => isHost && !previewMode && inGame,
				enabled: () => joinOpen,
			},
			{
				header: "Sound",
				visible: () => true,
			},
			{
				message: trans("Sound is disabled by host."),
				enabled: () => mute,
				visible: () => !isHost,
			},
			{
				description: "",
				key: "volumeSettings",
				items: [
					{
						description: trans("Music"),
						key: "musicVolume",
						range: { min: 0, max: 1, step: 0.01 },
						defaultValue: DEFAULT_MUSIC_VOLUME,
						isSticky: true,
					},
					{
						description: trans("YouTube"),
						key: "mediaVolume",
						range: { min: 0, max: 1, step: 0.01 },
						defaultValue: DEFAULT_MEDIA_VOLUME,
						isSticky: true,
					},
					{
						description: trans("Voice"),
						key: "voiceVolume",
						range: { min: 0, max: 1, step: 0.01 },
						defaultValue: DEFAULT_VOICE_VOLUME,
						isSticky: true,
					},
					{
						description: trans("Effects"),
						key: "soundVolume",
						range: { min: 0, max: 1, step: 0.01 },
						defaultValue: DEFAULT_SFX_VOLUME,
						isSticky: true,
					},
				],
				enabled: () => isHost || !mute,
				visible: () => true,
			},
			{
				header: "Gameplay",
				visible: () => isHost && !previewMode,
			},
			{
				description: trans("Team mode"),
				comment: trans("All players compete in teams"),
				key: "teamMode",
				options: [true, false], // Checked, unchecke
				defaultValue: false,
				visible: () => isHost && !previewMode,
				enabled: () => !inGame,
				newBadge: true,
			},
			{
				description: trans("Hide leaderboard"),
				comment: trans("Hide during game"),
				key: "leaderboardSetting",
				options: [false, true], // Checked, unchecke
				defaultValue: true,
				isSticky: true,
				visible: () => isHost && !previewMode,
			},
			{
				description: trans("Hide country flags"),
				comment: trans("Hide country flag beside username on the leaderboard"),
				key: "hidePlayerCountry",
				options: [true, false], // Checked, unchecke
				defaultValue: false,
				isSticky: true,
				visible: () => isHost && !previewMode,
				enabled: () => !teamMode,
			},
			{
				description: trans("No Youtube media"),
				comment: disableYoutubeSetting
					? trans("This quiz only have questions with Youtube media")
					: trans("Don't show questions with Youtube media"),
				key: "excludeYoutube",
				options: [true, false], // Checked, unchecke
				defaultValue: false,
				isSticky: true,
				visible: () => isHost && !previewMode,
				enabled: () =>
					!disableYoutubeSetting && useStatusWithProgressStore.getState().name === PLAY_STATUS_LOBBY,
			},
			{
				description: trans("Mute sound on players devices"),
				comment: trans("If it gets too noisy"),
				key: "muteGuest",
				options: [true, false], // Checked, unchecke
				defaultValue: false,
				isSticky: true,
				visible: () => isHost && !previewMode,
			},
			{
				description: trans("Optimize performance"),
				comment: trans("Minimize performance craving effects"),
				key: "reducedMotion",
				options: [true, false], // Checked, unchecke
				defaultValue: false,
				isSticky: true,
				visible: () => isHost && !previewMode,
			},
			{
				header: "Safety",
				visible: () => isHost && !previewMode,
			},
			{
				description: trans("Only safe player names"),
				comment: trans("Disables the ability to type in names"),
				key: "safeNames",
				options: [true, false], // Checked, unchecke
				defaultValue: false,
				isSticky: true,
				visible: () => isHost && !previewMode,
			},
			{
				description: trans("Hide incorrect type-answers"),
				comment: trans("Don't print incorrect answers on screen"),
				key: "hideIncorrectTypeAnswers",
				options: [true, false], // Checked, unchecke
				defaultValue: false,
				isSticky: true,
				visible: () => isHost && !previewMode,
			},
			{
				description: trans("Don't read out player names"),
				comment: trans("Disables reading names of players when joining"),
				key: "mutePlayerNames",
				options: [true, false], // Checked, unchecke
				defaultValue: false,
				isSticky: true,
				visible: () => isHost && !previewMode,
			},
			{
				description: trans("Ban kicked players"),
				comment: trans("Kicking a user will result in a 10 minute ban"),
				key: "banPlayerOnKick",
				options: [true, false], // Checked, unchecke
				defaultValue: false,
				isSticky: true,
				visible: () => isHost && !previewMode,
			},
			/*{
				header: "Voice-over",
				visible: () => isHost && !previewMode,
			},
			{
				//	description: trans("Voice selection"),
				key: "voiceOverride",
				options: [
					{ value: "Default", text: trans("Quiz setting") },
					{ value: "Mute", text: trans("No voice") },
				].concat(
					VOICES.map((voice) => {
						return {
							value: voice.name,
							text: `${languages[voice.lang.split("-")[0]].name} – ${voice.name}`,
						};
					})
				),
				defaultValue: "Default",
				enabled: () => {
					return useStatusWithProgressStore.getState().name === PLAY_STATUS_LOBBY;
				},
				visible: () => isHost && !previewMode,
			},*/
		],
		[disableYoutubeSetting, isHost, quickMode, previewMode, inGame, joinOpen, mute, teamMode]
	);

	const isDefaultSettings = useMemo(() => {
		if (settings) {
			const isDefaultValue = (setting) => {
				const enabled = !setting.enabled || setting.enabled();
				if (!enabled) {
					return true;
				}

				const defaultValue = setting.defaultValue;
				if (settingsStore[setting.key] !== defaultValue) {
					return false;
				}

				return true;
			};

			for (let i = 0; i < settings.length; i++) {
				if (settings[i].items) {
					for (let j = 0; j < settings[i].items.length; j++) {
						if (!isDefaultValue(settings[i].items[j])) {
							return false;
						}
					}
				} else {
					if (!isDefaultValue(settings[i])) {
						return false;
					}
				}
			}
		}

		return true;
	}, [settings, settingsStore]);

	const onClickResetSettings = useCallback(() => {
		setSettingsStore((draft) => {
			for (const setting of settings) {
				if (setting.items) {
					for (const item of setting.items) {
						draft[item.key] = item.defaultValue;
					}
				} else {
					draft[setting.key] = setting.defaultValue;
				}
			}
		});
		setEnableVolumeBars(true);
	}, [setSettingsStore, settings]);

	const stopHosting = useStopHosting();
	const stopHostingRef = useRef(stopHosting);
	useEffect(() => void (stopHostingRef.current = stopHosting), [stopHosting]);

	const onClickStopHosting = useCallback(() => {
		if (stopHostingRef.current) {
			stopHostingRef.current();
		}
		router.push("/");
	}, []);

	const numberOfPlayers = useMemo(() => getConnectedPlayers([...players.values()], false).length, [players]);

	useEffect(() => {
		if (enableVolumeBars) {
			setMusicVolume(musicVolume);
		}
	}, [musicVolume, setMusicVolume, enableVolumeBars]);

	useEffect(() => {
		if (enableVolumeBars) {
			setSoundVolume(soundVolume);
		}
	}, [soundVolume, setSoundVolume, enableVolumeBars]);

	useEffect(() => {
		if (enableVolumeBars) {
			if (mute) {
				setVoiceVolume(0);
			} else {
				setVoiceVolume(voiceVolume);
			}
		}
	}, [voiceVolume, setVoiceVolume, enableVolumeBars, mute]);

	useEffect(() => {
		if (reset === "soft") {
			setSettingsStore((draft) => {
				for (const setting of settings) {
					if (setting.items) {
						for (const item of setting.items) {
							if (!item.isSticky) {
								draft[item.key] = item.defaultValue;
							}
						}
					} else {
						if (!setting.isSticky) {
							draft[setting.key] = setting.defaultValue;
						}
					}
				}
			});
			setEnableVolumeBars(true);
		} else {
			setEnableVolumeBars(true);
		}
	}, [settings, reset, setSettingsStore]);

	useEffect(() => {
		setSettingsStore((draft) => {
			for (const setting of settings) {
				if (setting.items) {
					for (const item of setting.items) {
						if (item.setDefaultIfNotVisible) {
							draft[item.key] = item.defaultValue;
						}
					}
				} else {
					if (setting.setDefaultIfNotVisible) {
						draft[setting.key] = setting.defaultValue;
					}
				}
			}
		});
	}, [settings, setSettingsStore]);

	const [kickSearchFilter, setKickSearchFilter] = useState("");

	const statusWithProgress = useStatusWithProgressStore((state) => state);
	const banPlayerOnKick = useSettingsStore((state) => state.banPlayerOnKick);

	return (
		<div className={className}>
			<div
				className={tailwindCascade("relative", "flex", "flex-col", "space-y-4", {
					"pb-8": inGame,
				})}
				{...props}
			>
				{inGame && (
					<div className="flex flex-row items-start justify-center p-4">
						<Button2 className="bg-opacity-20 p-4 bg-black" onClick={onCancel}>
							<div className="flex flex-row items-center justify-start">
								<ChevronLeftIcon className="w-4 h-4 text-white" />
								<div className="whitespace-nowrap pl-2 leading-4">{trans("Close settings")}</div>
							</div>
						</Button2>
					</div>
				)}

				<div
					className={tailwindCascade("relative", "flex", "flex-col", "space-y-3", {
						"px-4": inGame,
						"mt-4": !isApp && isHost && statusWithProgress.name !== PLAY_STATUS_LOBBY,
					})}
				>
					{settings
						.filter(({ visible }) => visible && visible())
						.map((setting, index) => (
							<label
								key={index}
								className="group flex flex-row items-start pl-1 space-x-3 cursor-pointer"
								onMouseEnter={setting.header || setting.items ? null : playHoverFeedback}
							>
								{setting.options &&
									(setting.options.length === 2 && setting.options.every(isBoolean) ? (
										<SettingsCheckbox
											name={setting.key}
											options={setting.options}
											description={setting.description}
											comment={setting.comment}
											enabled={setting.enabled}
											override={setting.override}
											newBadge={setting.newBadge}
										/>
									) : (
										<SettingsSelector
											name={setting.key}
											options={setting.options}
											description={setting.description}
											enabled={setting.enabled}
											loadFunc={
												setting.type === "integer" ? (value) => value.toString() : undefined
											}
											saveFunc={
												setting.type === "integer" ? (value) => parseInt(value) : undefined
											}
										/>
									))}
								{setting.range && (
									<SettingsSlider
										name={setting.key}
										range={setting.range}
										description={setting.description}
										enabled={setting.enabled}
									/>
								)}
								{setting.items && (
									<SettingsSliderGroup items={setting.items} enabled={setting.enabled} />
								)}
								{setting.header && (
									<Header
										className={tailwindCascade(
											"xl:text-2xl -mb-1 text-xl font-bold text-white mt-2",
											{
												"mt-0": index === 0,
											}
										)}
									>
										{setting.header}
									</Header>
								)}
								{setting.message && (!setting.enabled || setting.enabled()) && (
									<p className="text-white">{setting.message}</p>
								)}
							</label>
						))}
				</div>
			</div>

			{!inGame && !isDefaultSettings && (
				<div className="pb-4 mt-6">
					<Header className="xl:text-2xl mb-2 text-xl font-bold text-white">
						{trans("You got custom settings")}
					</Header>
					<Button
						onClick={onClickResetSettings}
						color="answer4"
						className="text-base text-black"
						border={true}
					>
						{trans("Reset all settings")}
					</Button>
				</div>
			)}

			{isHost && numberOfPlayers > 0 && !previewMode && (
				<div className={tailwindCascade("mt-6", { "pb-4 px-4": inGame, "pb-4 px-2": !inGame })}>
					<Header className="xl:text-2xl mb-2 text-xl font-bold text-white">
						{banPlayerOnKick ? trans("Ban Players") : trans("Kick Players")}
					</Header>
					{players && players.length > 10 && (
						<div className="pb-8">
							<SearchInput
								//ref={searchInputRef
								className="w-full p-0 m-auto"
								inputClassName="bg-black bg-opacity-30"
								value={kickSearchFilter}
								onChange={(event) => void setKickSearchFilter(event.target.value)}
								//onSearch={(str) => setKickSearchFilter(str)
								onReset={() => setKickSearchFilter("")}
							/>
						</div>
					)}
					<div className={tailwindCascade("w-full", { "pt-2": !(players && players.length > 10) })}>
						<KickPlayers
							players={players}
							kickSearchFilter={kickSearchFilter}
							onKick={() => {
								setTimeout(() => {
									setKickSearchFilter("");
								}, 500);
							}}
						/>
					</div>
				</div>
			)}

			{isHost && !isApp && statusWithProgress.name !== PLAY_STATUS_LOBBY && !previewMode && (
				<div className="bg-pink-dark p-4 mt-4 rounded-b-lg">
					<Button onClick={onClickStopHosting} color="pink" className="w-full text-white">
						{trans("Stop hosting")}
					</Button>
				</div>
			)}
		</div>
	);
}
