import React, { useRef, useEffect, forwardRef } from "react";

import useForwardRef from "@/hooks/useForwardRef";
import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import isBoolean from "lodash/isBoolean";
import isFinite from "lodash/isFinite";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import colors, { GREEN_LIGHT, PINK } from "@/colors";
import { constantCase } from "change-case";
import onWindowResize from "@/helpers/onWindowResize";
import clamp from "lodash/clamp";

export const BUTTON_RADIUS = 12;
export const BUTTON_BORDER_RADIUS = 50;

const FONT_SIZES = [
	[1.5, { lineHeight: `${2 / 1.5}em`, letterSpacing: "0.03em" }],
	[1.25, { lineHeight: `${1.75 / 1.25}em`, letterSpacing: "0.03em" }],
	[1.125, { lineHeight: `${1.75 / 1.125}em`, letterSpacing: "0.03em" }],
	[1, { lineHeight: `${1.5 / 1.1}em`, letterSpacing: "0.03em" }],
	[0.875, { lineHeight: `${1.25 / 0.87}em`, letterSpacing: "0.03em" }],
	[0.75, { lineHeight: `${1 / 0.75}em`, letterSpacing: "0.03em" }],
	[0.625, { lineHeight: `${0.75 / 0.635}em`, letterSpacing: "0.03em" }],
	[0.5, { lineHeight: `${0.5 / 0.5}em`, letterSpacing: "0.03em" }],
	[0.375, { lineHeight: `${0.5 / 0.375}em`, letterSpacing: "0.03em" }],
	[0.25, { lineHeight: `${0.375 / 0.25}em`, letterSpacing: "0.03em" }],
];

export default forwardRef(function Button(
	{
		className,
		type,
		href,
		color = "white",
		align = "center",
		borderRadius = undefined,
		hoverClassName = "",
		elementType,
		icon,
		iconAlign = "left",
		roundedLeft = true,
		roundedRight = true,
		pressed = false,
		border = false,
		borderColor = null,
		solid = false,
		children,
		outlineCorrectness = undefined,
		outline = null,
		maxHeight = null,
		maxFontSize = 1.5,
		progress = 0,
		disabled,
		disabledOpacity = true,
		style,
		...props
	},
	forwardedRef
) {
	const ref = useForwardRef(forwardedRef);

	const wrapperRef = useRef(null);
	const textRef = useRef(null);

	const transparent = color === "transparent";

	const backgroundColor = !transparent
		? color && color.indexOf("#") === 0
			? color
			: colors[constantCase(color)]
		: null;

	const borderSize = isFinite(border) ? border : border ? 4 : 0;

	if (!isFinite(borderRadius)) {
		borderRadius = border ? BUTTON_BORDER_RADIUS : BUTTON_RADIUS;
	}

	const borderRadiusLeft = roundedLeft ? borderRadius : 0;
	const borderRadiusRight = roundedRight ? borderRadius : 0;

	const Element = elementType || "button";
	const innerProps = {};
	if (!type && (elementType || "button") === "button") {
		innerProps.type = "button";
	}

	useIsomorphicLayoutEffect(() => {
		let mounted = true;

		if (maxHeight && textRef.current && textRef.current) {
			const wrapper = wrapperRef.current;
			const text = textRef.current;

			const resize = () => {
				wrapper.style.position = "absolute";

				for (let i = 0; i < FONT_SIZES.length; i++) {
					text.style.fontSize = `${FONT_SIZES[i][0]}rem`;
					text.style.lineHeight = FONT_SIZES[i][1].lineHeight;
					text.style.letterSpacing = FONT_SIZES[i][1].letterSpacing;

					if (text.offsetHeight <= maxHeight) {
						break;
					}
				}

				wrapper.style.position = null;
			};

			resize(); // Initial resize
			setTimeout(() => {
				if (mounted) {
					resize();
				}
			}, 0); // Delayed resize

			const disposeOnWindowResize = onWindowResize(resize);

			return () => {
				mounted = false;
				disposeOnWindowResize();
				text.style.fontSize = FONT_SIZES[0][0];
				text.style.lineHeight = FONT_SIZES[0][1].lineHeight;
				text.style.letterSpacing = FONT_SIZES[0][1].letterSpacing;
			};
		}
	}, [children, maxHeight, maxFontSize]);

	return (
		<Element
			ref={ref}
			className={tailwindCascade(
				"relative flex group",
				"text-lg",
				"font-black",
				"leading-6",
				"text-black",
				"px-8 py-3",
				"touch-manipulation",
				{
					"opacity-50": disabled && disabledOpacity,
				},
				{
					"cursor-pointer": !disabled,
					"pointer-events-none": disabled,
					"pointer-events-auto": !disabled,
					"active:opacity-80": solid,
				},
				className
			)}
			style={{
				...style,
				borderRadius: solid
					? `${Math.max(borderRadiusLeft - borderSize, 0) / 16}rem ${
							Math.max(borderRadiusRight - borderSize, 0) / 16
					  }rem ${Math.max(borderRadiusRight - borderSize, 0) / 16}rem ${
							Math.max(borderRadiusLeft - borderSize, 0) / 16
					  }rem`
					: 0,
			}}
			disabled={disabled}
			{...innerProps}
			{...props}
		>
			<div
				className="-inset-1 absolute z-0"
				style={{
					borderRadius: `${Math.max(borderRadiusLeft - (border ? borderSize : 2), 0) / 16}rem ${
						Math.max(borderRadiusRight - (border ? borderSize : 2), 0) / 16
					}rem ${Math.max(borderRadiusRight - (border ? borderSize : 2), 0) / 16}rem ${
						Math.max(borderRadiusLeft - (border ? borderSize : 2), 0) / 16
					}rem`,
					backgroundColor: isBoolean(outlineCorrectness)
						? outlineCorrectness
							? GREEN_LIGHT
							: PINK
						: undefined,
				}}
			/>

			{!solid && (
				<div
					className={tailwindCascade(
						"absolute",
						"inset-x-0",
						"top-0",
						"bottom-0",
						"transform",
						"group-active:translate-y-0.5",
						"group-active:bottom-0.5",
						"z-1",
						{
							"translate-y-0.5 bottom-0.5": pressed,
							"bg-black": borderColor ? false : border,
							"bg-pink": outlineCorrectness === false,
							"bg-green-light": outlineCorrectness === true,
						}
					)}
					style={{
						borderRadius: border
							? `${borderRadiusLeft / 16}rem ${borderRadiusRight / 16}rem ${borderRadiusRight / 16}rem ${
									borderRadiusLeft / 16
							  }rem`
							: 0,
						padding: border ? `${borderSize / 16}rem` : 0,
						backgroundColor: borderColor || null,
					}}
				>
					<div className="relative w-full h-full">
						{!border && (
							<div
								className="absolute inset-0 overflow-hidden shadow-hard-0.5"
								style={{
									borderRadius: `${Math.max(borderRadiusLeft - borderSize, 0) / 16}rem ${
										Math.max(borderRadiusRight - borderSize, 0) / 16
									}rem ${Math.max(borderRadiusRight - borderSize, 0) / 16}rem ${
										Math.max(borderRadiusLeft - borderSize, 0) / 16
									}rem`,
								}}
							/>
						)}
						<div
							className="top-1 absolute inset-x-0 bottom-0 overflow-hidden"
							style={{
								backgroundColor,
								borderRadius: `${Math.max(borderRadiusLeft - 5, 0) / 16}rem ${
									Math.max(borderRadiusRight - 5, 0) / 16
								}rem ${Math.max(borderRadiusRight - 5, 0) / 16}rem ${
									Math.max(borderRadiusLeft - 5, 0) / 16
								}rem`,
							}}
						>
							<div className="bg-opacity-30 absolute inset-0 bg-black" />
						</div>
						<div
							className={tailwindCascade(
								"bottom-1",
								"absolute",
								"inset-x-0",
								"top-0",
								"overflow-hidden",
								"group-active:bottom-0.5",
								{ "bottom-0.5": pressed }
							)}
							style={{
								backgroundColor,
								borderRadius: `${Math.max(borderRadiusLeft - 5, 0) / 16}rem ${
									Math.max(borderRadiusRight - 5, 0) / 16
								}rem ${Math.max(borderRadiusRight - 5, 0) / 16}rem ${
									Math.max(borderRadiusLeft - 5, 0) / 16
								}rem`,
							}}
						>
							<div
								className={tailwindCascade(
									{ "group-hover:bg-opacity-20": !disabled },
									"bg-fff",
									"absolute",
									"inset-0 bg-opacity-0",
									hoverClassName
								)}
							/>
						</div>
					</div>
				</div>
			)}

			<div
				className={`z-1 absolute inset-0 overflow-hidden ${progress > 0 ? "block" : "hidden"}`}
				style={{
					borderRadius: `${Math.max(borderRadiusLeft - 5, 0) / 16}rem ${
						Math.max(borderRadiusRight - 5, 0) / 16
					}rem ${Math.max(borderRadiusRight - 5, 0) / 16}rem ${Math.max(borderRadiusLeft - 5, 0) / 16}rem`,
				}}
			>
				<div
					className="bg-opacity-20 absolute top-0 left-0 w-full h-full bg-black"
					style={{
						left: `${(clamp(progress || 0, 0, 1) - 1) * 100}%`,
					}}
				></div>
			</div>

			<div
				className={tailwindCascade(
					"relative",
					"flex",
					iconAlign === "left" ? "flex-row" : "flex-row-reverse",
					"gap-x-4",
					"items-center",
					"w-full",
					"min-h-full",
					"pointer-events-none",
					"z-2",
					"transform",
					"-translate-y-0.5",
					{
						"group-active:translate-y-0": !solid,
						"group-active:opacity-80": solid,
						"translate-y-0": !solid && pressed,
					}
				)}
				style={{
					padding: border ? `${borderSize / 16}rem` : 0,
				}}
			>
				{icon && <div className="relative flex-grow-0">{icon}</div>}
				<div
					className={tailwindCascade(
						"flex",
						"flex-col",
						"flex-1",
						align === "left" ? "items-start" : align === "right" ? "items-end" : "items-center"
					)}
				>
					<div ref={wrapperRef} className="relative">
						<div ref={textRef} className={tailwindCascade("relative", { "text-left": align === "left" })}>
							{children ? (
								<>{children}</>
							) : (
								<span className="inline-block w-0 h-4 overflow-hidden">&nbsp;</span>
							)}
						</div>
					</div>
				</div>
			</div>
			{isBoolean(outlineCorrectness) && (
				<div
					className={tailwindCascade("bg-opacity-30 z-3 absolute inset-0", {
						"bg-green-light": outlineCorrectness,
						"bg-pink": !outlineCorrectness,
					})}
					style={{
						borderRadius: `${Math.max(borderRadiusLeft - 5, 0) / 16}rem ${
							Math.max(borderRadiusRight - 5, 0) / 16
						}rem ${Math.max(borderRadiusRight - 5, 0) / 16}rem ${
							Math.max(borderRadiusLeft - 5, 0) / 16
						}rem`,
					}}
				></div>
			)}

			{outline && (
				<>
					<div
						className="-inset-1 absolute z-0"
						style={{
							borderRadius: `${Math.max(borderRadiusLeft + 4, 0) / 16}rem ${
								Math.max(borderRadiusRight + 4, 0) / 16
							}rem ${Math.max(borderRadiusRight + 4, 0) / 16}rem ${
								Math.max(borderRadiusLeft + 4, 0) / 16
							}rem`,
							backgroundColor: outline,
						}}
					></div>
				</>
			)}
		</Element>
	);
});
