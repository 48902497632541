import React, { useCallback, useRef } from "react";
import { createPortal } from "react-dom";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import useRefMounted from "@/hooks/useRefMounted";

export default function Modal({ className, onCancel, style, ...props }) {
	const [mounted] = useRefMounted();

	const containerRef = useRef(null);

	useIsomorphicLayoutEffect(() => {
		if (containerRef.current) {
			document.documentElement.setAttribute("data-modal-active", "true");
			return () => void document.documentElement.setAttribute("data-modal-active", "false");
		}
	}, [mounted]);

	useIsomorphicLayoutEffect(() => {
		// Exclude all underlying elements from tabbing when modal is open
		if (containerRef.current) {
			const elementsWithTabindex = document.querySelectorAll("a, button, input, textarea, select");
			const savedTabIndices = [];

			for (const el of elementsWithTabindex) {
				if (!containerRef.current.contains(el)) {
					savedTabIndices.push({ element: el, tabIndex: el.getAttribute("tabIndex") });
					el.setAttribute("tabIndex", -1);
				}
			}

			const activeElement = document.activeElement;
			if (activeElement) {
				activeElement.blur();
			}

			return () => {
				for (const { element, tabIndex } of savedTabIndices) {
					if (tabIndex === null) {
						element.removeAttribute("tabIndex");
					} else {
						element.setAttribute("tabIndex", tabIndex);
					}
				}
				if (activeElement) {
					activeElement.focus();
				}
			};
		}
	}, [mounted]);

	useIsomorphicLayoutEffect(() => {
		// Allow pressing "Escape" to cancel the requester

		if (onCancel) {
			const onKeydown = (ev) => {
				if (ev.key === "Escape") {
					ev.preventDefault();
					onCancel && onCancel();
				}
			};

			window.addEventListener("keydown", onKeydown);
			return () => {
				window.removeEventListener("keydown", onKeydown);
			};
		}
	}, [mounted, onCancel]);

	const onCancelPortal = useCallback((event) => {
		if (event.target === containerRef.current && onCancel) {
			onCancel();
		}
	}, []);

	return mounted
		? createPortal(
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
				<div
					ref={containerRef}
					className={tailwindCascade(
						"fixed inset-0 flex items-center justify-center p-4 overflow-auto",
						"bg-black bg-opacity-80",
						className
					)}
					onClick={onCancelPortal}
					style={style}
				>
					{props.children}
				</div>,
				document.getElementById("__next")
		  )
		: null;
}
