import create from "zustand";
import { persist } from "zustand/middleware";
import produce from "immer";

import localStorageGuard from "@/helpers/localStorageGuard";

const useUserCacheStore = create(
	persist(
		(set) => {
			return {
				quizzesPlayed: new Map(),
				update: (recipe) => set(produce(recipe)),
			};
		},
		{
			name: "user",
			version: 1,

			getStorage: () => localStorageGuard,

			serialize: (obj) => {
				const str = JSON.stringify({
					...obj,
					state: {
						...obj.state,
						quizzesPlayed: Array.from(obj.state.quizzesPlayed),
					},
				});
				return str;
			},

			deserialize: (str) => {
				const obj = JSON.parse(str);
				obj.state.quizzesPlayed = new Map(obj.state.quizzesPlayed || []);
				return obj;
			},

			migrate: (persistedState) => {
				const playedQuizzes = persistedState.playedQuizzes;
				const playDates = persistedState.playDates;

				const quizzesPlayed =
					playedQuizzes && playDates
						? new Map(playedQuizzes.map((quizId, i) => [quizId, playDates[i]]))
						: new Map();

				const newState = { quizzesPlayed };
				return newState;
			},
		}
	)
);

export default useUserCacheStore;
