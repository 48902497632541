import { gsap } from "gsap";
import { setTimeout, clearTimeout } from "@/helpers/workerTimers";

const DEFAULT_GSAP_LAG_THRESHOLD = 500;
const TIMEOUT = DEFAULT_GSAP_LAG_THRESHOLD - 100;

let uniqueId = 0;
const animationFrames = new Map();
let lastTime = Date.now();

function patcedRequestAnimationFrame(callback) {
	const currentId = uniqueId++;

	const currentTime = Date.now();
	const timeToCall = Math.max(0, TIMEOUT - (currentTime - lastTime));
	const timeoutId = setTimeout(() => {
		if (animationFrames.has(currentId)) {
			const [timeoutId, requestAnimationFrameId] = animationFrames.get(currentId);
			cancelAnimationFrame(requestAnimationFrameId);
			animationFrames.delete(currentId);
		}

		callback(currentTime + timeToCall);
	}, TIMEOUT);
	lastTime = currentTime + timeToCall;

	const requestAnimationFrameId = requestAnimationFrame((now) => {
		if (animationFrames.has(currentId)) {
			const [timeoutId, requestAnimationFrameId] = animationFrames.get(currentId);
			clearTimeout(timeoutId);
			animationFrames.delete(currentId);
		}
		callback(now);
	});

	animationFrames.set(currentId, [timeoutId, requestAnimationFrameId]);

	return currentId;
}
function patchedCancelAnimationFrame(currentId) {
	if (animationFrames.has(currentId)) {
		const [timeoutId, requestAnimationFrameId] = animationFrames.get(currentId);
		clearTimeout(timeoutId);
		cancelAnimationFrame(requestAnimationFrameId);
		animationFrames.delete(currentId);
	}
}

if (gsap.patch) {
	gsap.patch({
		requestAnimationFrame: patcedRequestAnimationFrame,
		cancelAnimationFrame: patchedCancelAnimationFrame,
	});
}

export default gsap;
