import create from "zustand";
import produce from "immer";

import { Howl } from "@/audio/howler/howler";

import { sfx, voicePlayer } from "@/helpers/audio";
import { DEFAULT_MUSIC_VOLUME, DEFAULT_SFX_VOLUME, DEFAULT_VOICE_VOLUME } from "@/constants";

const volumeFunction = (sliderPosition) => {
	/*
		Before:
		0.7 * (1 + 0.7) * 0.5 = 0.595
		0.595 * 0.8 = 0.476 (20%)

		After:
		0.7 * 0.7 = 0.4899
	*/

	const MAX_MUSIC_VOLUME = 1.0;
	// const y = sliderPosition * (1 + sliderPosition) * 0.5 * MAX_MUSIC_VOLUME;
	const y = sliderPosition * sliderPosition * MAX_MUSIC_VOLUME; // easeInQuad
	return y;
};

const SOUND_INDEX_LOBBY = 0;
const SOUND_INDEX_GAME = 1;

class GameHowlProxy {
	constructor({ volume, soundIndex = 0 }) {
		this._bpm = 146;
		this._sounds = [
			(() => {
				const sound = new Howl({
					src: ["/audio/ai-lobby.mp3"],
					volume: volume,
					autoplay: soundIndex === SOUND_INDEX_LOBBY,
					loop: true,
					onfade: () => this._onFade(sound),
				});
				return sound;
			})(),
			(() => {
				const sound = new Howl({
					src: ["/audio/ai-game.mp3"],
					volume: volume,
					autoplay: soundIndex === SOUND_INDEX_GAME,
					loop: true,
					onfade: () => this._onFade(sound),
				});
				return sound;
			})(),
		];

		this._soundIndex = soundIndex;
	}

	_onFade(sound) {
		const currentSound = this._sounds[this._soundIndex];
		if (sound !== currentSound) {
			sound.stop();
			sound.volume(currentSound.volume());
		}
	}

	setSoundIndex(soundIndex) {
		if (soundIndex !== this._soundIndex) {
			const playing = this.playing();
			const volume = this.volume();

			if (playing) {
				const currentSound = this._sounds[this._soundIndex];
				currentSound.fade(volume, 0, 2000);

				this._soundIndex = soundIndex;

				const nextSound = this._sounds[this._soundIndex];
				const seek = Math.round((60000 / this._bpm) * 4); // 4 bars
				nextSound.seek(Math.round(currentSound.seek() % seek));
				nextSound.play();
				nextSound.fade(0, volume, 2000);
			} else {
				this._soundIndex = soundIndex;

				const nextSound = this._sounds[this._soundIndex];
				nextSound.stop();
				nextSound.volume(volume);
			}
		}
	}

	play() {
		const previousSound = this._sounds[(this._soundIndex + 1) % 2];
		previousSound.stop();

		const currentSound = this._sounds[this._soundIndex];
		currentSound.play();
	}

	pause() {
		const previousSound = this._sounds[(this._soundIndex + 1) % 2];
		previousSound.stop();

		const currentSound = this._sounds[this._soundIndex];
		currentSound.pause();
	}

	stop() {
		const previousSound = this._sounds[(this._soundIndex + 1) % 2];
		previousSound.stop();

		const currentSound = this._sounds[this._soundIndex];
		currentSound.stop();
	}

	playing() {
		const currentSound = this._sounds[this._soundIndex];
		return currentSound.playing();
	}

	volume(...args) {
		if (args.length === 0) {
			const currentSound = this._sounds[this._soundIndex];
			return currentSound.volume();
		} else {
			const previousSound = this._sounds[(this._soundIndex + 1) % 2];
			previousSound.stop();

			const currentSound = this._sounds[this._soundIndex];
			currentSound.volume(...args);
		}
	}

	fade(from, to, duration) {
		const previousSound = this._sounds[(this._soundIndex + 1) % 2];
		previousSound.stop();

		const currentSound = this._sounds[this._soundIndex];
		currentSound.fade(from, to, duration);
	}
}

const useAudioStore = create((set) => ({
	game: null,
	voice: null,

	isPaused: false,
	isYoutubePlaying: false,

	typewriterName: null,
	voiceId: null,

	musicVolume: DEFAULT_MUSIC_VOLUME,
	soundVolume: DEFAULT_SFX_VOLUME,
	voiceVolume: DEFAULT_VOICE_VOLUME,

	loadAudio: (aiMode) => {
		const game = useAudioStore.getState().game;
		const soundVolume = useAudioStore.getState().soundVolume;
		const musicVolume = useAudioStore.getState().musicVolume;
		sfx.preload(soundVolume);

		if (!game || (aiMode && !(game instanceof GameHowlProxy)) || (!aiMode && game instanceof GameHowlProxy)) {
			try {
				if (game) {
					game.stop();
				}
			} catch (error) {
				console.error(error);
			}

			set({
				game: aiMode
					? new GameHowlProxy({ volume: volumeFunction(musicVolume) })
					: new Howl({
							src: ["/audio/game3.mp3"],
							volume: volumeFunction(musicVolume),
							autoplay: true,
							loop: true,
					  }),
			});
		}
	},

	setLobbyMusic: (lobby = true) => {
		const game = useAudioStore.getState().game;
		if (game instanceof GameHowlProxy) {
			game.setSoundIndex(lobby ? SOUND_INDEX_LOBBY : SOUND_INDEX_GAME);
		}
	},

	stopAudio: (stopMusic = true) => {
		const audioStoreStopMusic = useAudioStore.getState().stopMusic;
		if (stopMusic) {
			audioStoreStopMusic();
			set({ game: null, musicVolume: 0 });
		}

		try {
			sfx.stop();
		} catch (error) {
			console.error(error);
		}
	},

	pauseMusic: () => {
		const game = useAudioStore.getState().game;
		const isPaused = useAudioStore.getState().isPaused;

		try {
			if (game && game.playing() && !isPaused) {
				game.pause();
				set({ isPaused: true });
			}
		} catch (error) {
			console.error(error);
		}
	},

	resumeMusic: () => {
		const game = useAudioStore.getState().game;
		const isPaused = useAudioStore.getState().isPaused;
		try {
			if (game && !game.playing() && isPaused) {
				game.play();
				set({ isPaused: false });
			}
		} catch (error) {
			console.error(error);
		}
	},

	stopMusic: () => {
		const game = useAudioStore.getState().game;
		try {
			if (game) {
				game.stop();
			}
		} catch (error) {
			console.error(error);
		}
	},

	setMusicVolume: (vol) => {
		const isYoutubePlaying = useAudioStore.getState().isYoutubePlaying;

		vol = Math.min(Math.max(vol, 0.0), 1.0);

		set({ musicVolume: vol });

		const game = useAudioStore.getState().game;

		try {
			if (game && !isYoutubePlaying) {
				game.volume(volumeFunction(vol));
			}
		} catch (error) {
			console.error(error);
		}
	},

	setSoundVolume: (vol) => {
		vol = Math.min(Math.max(vol, 0.0), 1.0);

		set({ soundVolume: vol });

		try {
			sfx.volume(vol);
		} catch (error) {
			console.error(error);
		}
	},

	setVoiceVolume: (vol) => {
		vol = Math.min(Math.max(vol, 0.0), 1.0);

		set({ voiceVolume: vol });

		try {
			voicePlayer.setVolume(vol);
		} catch (error) {
			console.error(error);
		}
	},

	fadeOutMusic: () => {
		set({ isYoutubePlaying: true });

		const game = useAudioStore.getState().game;
		const vol = useAudioStore.getState().musicVolume;

		try {
			if (game && game.playing()) {
				game.fade(volumeFunction(vol), 0.0, 500);
			}
		} catch (error) {
			console.error(error);
		}
	},

	fadeInMusic: () => {
		const game = useAudioStore.getState().game;
		const vol = useAudioStore.getState().musicVolume;

		try {
			if (game && game.playing() && game.volume() === 0) {
				game.fade(0.0, volumeFunction(vol), 1000);
			}
		} catch (error) {
			console.error(error);
		}

		set({ isYoutubePlaying: false });
	},
}));

export default useAudioStore;
