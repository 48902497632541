import create from "zustand";
import { persist } from "zustand/middleware";
import produce, { enableMapSet } from "immer";

import { PLAY_STATUS_LOBBY } from "@/constants";

import { StatusWithProgress } from "@/types/statusWithProgress";

enableMapSet();

const useStatusWithProgressStore = create<StatusWithProgress>(
	persist(
		(set, get) => ({
			reset: () =>
				set(
					produce((state: StatusWithProgress) => {
						state.name = PLAY_STATUS_LOBBY;
						state.duration = Infinity;
						state.progress = 0;
						state.elapsedTime = 0;
					})
				),

			updateStatus: (name, duration = 0, callback = null) =>
				set(
					produce((state) => {
						state.name = name;
						state.duration = duration;
						state.progress = 0;
						state.elapsedTime = 0;

						if (callback) {
							callback(state);
						}
					})
				),

			updateDuration: (duration: number) =>
				set(
					produce((state) => {
						state.duration = duration;
					})
				),

			name: PLAY_STATUS_LOBBY,
			duration: 0,
			progress: 0,
			elapsedTime: 0,

			set: (recipe) => set(produce(recipe)),
		}),
		{
			name: "status-with-progress-storage",
			getStorage: () => sessionStorage,

			serialize: (data) => {
				let duration: string | number = data.state.duration;
				if (duration !== null) {
					duration = duration.toString(); // Allow Infinity
				}

				return JSON.stringify({
					...data,
					state: {
						...data.state,
						duration,
					},
				});
			},
			deserialize: (value) => {
				const data = JSON.parse(value);
				if (data.state.duration !== null) {
					data.state.duration = parseFloat(data.state.duration);
				}

				return data;
			},
		}
	)
);

export default useStatusWithProgressStore;
