import { useEffect, useState } from "react";
import { useRouter } from "next/router";

import cloneDeep from "lodash/cloneDeep";

import { PUBLIC_URL } from "@/constants";
import SEO from "../next-seo.config";

export default function useSEO() {
	const router = useRouter();

	let publicUrl = PUBLIC_URL || "";
	if (publicUrl.endsWith("/")) {
		publicUrl = publicUrl.substring(0, publicUrl.length - 1);
	}

	const seo = cloneDeep(SEO);

	const url = `${publicUrl}${router.asPath}`;

	seo.canonical = url ? url.split(/[?#]/)[0] : url;

	if (!seo.openGraph) {
		seo.openGraph = {};
	}
	seo.openGraph.url = url;

	const [state, setState] = useState(seo);
	useEffect(() => {
		const seo = cloneDeep(SEO);
		if (!seo.openGraph) {
			seo.openGraph = {};
		}
		seo.openGraph.url = `${publicUrl}${router.asPath}`;
		setState(seo);
	}, [publicUrl, router]);

	return state;
}
