export const SLIDE_TYPE_INFO_SLIDE = "InfoSlide";
export const SLIDE_TYPE_CLASSIC = "Classic";
export const SLIDE_TYPE_CHECK_BOXES = "CheckBoxes";
export const SLIDE_TYPE_TYPE_ANSWER = "TypeAnswer";
export const SLIDE_TYPE_LOCATION = "Location";
export const SLIDE_TYPE_RANGE = "Range";
export const SLIDE_TYPE_REORDER = "Reorder";
export const SLIDE_TYPE_PINPOINT = "Pinpoint";
export const SLIDE_TYPES = [
	SLIDE_TYPE_INFO_SLIDE,
	SLIDE_TYPE_CLASSIC,
	SLIDE_TYPE_CHECK_BOXES,
	SLIDE_TYPE_TYPE_ANSWER,
	SLIDE_TYPE_LOCATION,
	SLIDE_TYPE_RANGE,
	SLIDE_TYPE_REORDER,
	SLIDE_TYPE_PINPOINT,
];

export const MAX_LENGTH_FUN_FACT = 120;
export const MAX_LENGTH_QUIZ_NAME = 75;
export const MAX_LENGTH_QUIZ_DESCRIPTION = 250;
export const MAX_LENGTH_TAG_NAME = 75;
export const MAX_LENGTH_CATEGORY_NAME = 75;
export const MAX_LENGTH_EDITORIAL_NAME = 75;

export const DEFAULT_MAX_EDITORIALS_PER_SECTION = 12;

export const DEFAULT_MAX_QUESTION_LENGTH = 120;
export const MAX_QUESTION_LENGTH = {
	[SLIDE_TYPE_INFO_SLIDE]: 250,
	[SLIDE_TYPE_CLASSIC]: DEFAULT_MAX_QUESTION_LENGTH,
	[SLIDE_TYPE_CHECK_BOXES]: DEFAULT_MAX_QUESTION_LENGTH,
	[SLIDE_TYPE_TYPE_ANSWER]: DEFAULT_MAX_QUESTION_LENGTH,
	[SLIDE_TYPE_LOCATION]: DEFAULT_MAX_QUESTION_LENGTH,
	[SLIDE_TYPE_RANGE]: DEFAULT_MAX_QUESTION_LENGTH,
	[SLIDE_TYPE_REORDER]: DEFAULT_MAX_QUESTION_LENGTH,
	[SLIDE_TYPE_PINPOINT]: DEFAULT_MAX_QUESTION_LENGTH,
};
export const MAX_QUESTION_LENGTH_ALL = Math.max(...Object.values(MAX_QUESTION_LENGTH));
console.assert(
	SLIDE_TYPES.every((slideType) => Object.keys(MAX_QUESTION_LENGTH).includes(slideType)),
	"One ore more slide type/s missing from MAX_QUESTION_LENGTH array."
);

export const DEFAULT_MAX_ANSWER_LENGTH = 75;
export const MAX_ANSWER_LENGTH = {
	[SLIDE_TYPE_INFO_SLIDE]: 0,
	[SLIDE_TYPE_CLASSIC]: DEFAULT_MAX_ANSWER_LENGTH,
	[SLIDE_TYPE_CHECK_BOXES]: DEFAULT_MAX_ANSWER_LENGTH,
	[SLIDE_TYPE_TYPE_ANSWER]: DEFAULT_MAX_ANSWER_LENGTH,
	[SLIDE_TYPE_LOCATION]: DEFAULT_MAX_ANSWER_LENGTH,
	[SLIDE_TYPE_RANGE]: DEFAULT_MAX_ANSWER_LENGTH,
	[SLIDE_TYPE_REORDER]: DEFAULT_MAX_ANSWER_LENGTH,
	[SLIDE_TYPE_PINPOINT]: DEFAULT_MAX_ANSWER_LENGTH,
};
export const RECOMMENDED_TYPE_ANSWER_LENGTH = 20;
export const MAX_ANSWER_LENGTH_ALL = Math.max(...Object.values(MAX_ANSWER_LENGTH));
console.assert(
	SLIDE_TYPES.every((slideType) => Object.keys(MAX_ANSWER_LENGTH).includes(slideType)),
	"One ore more slide type/s missing from MAX_ANSWER_LENGTH array."
);

export const MAX_NUM_ANSWERS = {
	[SLIDE_TYPE_INFO_SLIDE]: 0,
	[SLIDE_TYPE_CLASSIC]: 4,
	[SLIDE_TYPE_CHECK_BOXES]: 4,
	[SLIDE_TYPE_TYPE_ANSWER]: 25,
	[SLIDE_TYPE_LOCATION]: 3,
	[SLIDE_TYPE_RANGE]: 3,
	[SLIDE_TYPE_REORDER]: 4,
	[SLIDE_TYPE_PINPOINT]: 10,
};
export const MAX_NUM_ANSWERS_ALL = Math.max(...Object.values(MAX_NUM_ANSWERS));
console.assert(
	SLIDE_TYPES.every((slideType) => Object.keys(MAX_NUM_ANSWERS).includes(slideType)),
	"One ore more slide type/s missing from MAX_NUM_ANSWERS array."
);

export const MIN_NUM_ANSWERS = {
	[SLIDE_TYPE_INFO_SLIDE]: 0,
	[SLIDE_TYPE_CLASSIC]: 2,
	[SLIDE_TYPE_CHECK_BOXES]: 1,
	[SLIDE_TYPE_TYPE_ANSWER]: 1,
	[SLIDE_TYPE_LOCATION]: 2,
	[SLIDE_TYPE_RANGE]: 2,
	[SLIDE_TYPE_REORDER]: 2,
	[SLIDE_TYPE_PINPOINT]: 0,
};
console.assert(
	SLIDE_TYPES.every((slideType) => Object.keys(MIN_NUM_ANSWERS).includes(slideType)),
	"One ore more slide type/s missing from MIN_NUM_ANSWERS array."
);

export const VALIDATE_ANSWER_IS_CORRECT = {
	[SLIDE_TYPE_INFO_SLIDE]: (isCorrect, pos) => isCorrect,
	[SLIDE_TYPE_CLASSIC]: (isCorrect, pos) => pos === 0, // this and only this answer is correct
	[SLIDE_TYPE_CHECK_BOXES]: (isCorrect, pos) => isCorrect,
	[SLIDE_TYPE_TYPE_ANSWER]: (isCorrect, pos) => true, // all answers are correct
	[SLIDE_TYPE_LOCATION]: (isCorrect, pos) => isCorrect,
	[SLIDE_TYPE_RANGE]: (isCorrect, pos) => pos === 0,
	[SLIDE_TYPE_REORDER]: (isCorrect, pos) => true, // TODO
	[SLIDE_TYPE_PINPOINT]: (isCorrect, pos) => true, // TODO
};
console.assert(
	SLIDE_TYPES.every((slideType) => Object.keys(VALIDATE_ANSWER_IS_CORRECT).includes(slideType)),
	"One ore more slide type/s missing from VALIDATE_ANSWER_IS_CORRECT array."
);

export const MAX_LENGTH_TTS = MAX_QUESTION_LENGTH_ALL;
export const MAX_UPLOAD_SIZE = 10 * 1024 * 1024;

export const MIN_PASSWORD_LENGTH = 8;
export const MIN_USER_NAME_LENGTH = 4;
export const MAX_USER_NAME_LENGTH = 25;
export const USER_NAME_REGEX = /^[a-zA-Z][a-zA-Z0-9_]+$/;
export const MAX_EMAIL_LENGTH = 320;

export const MAX_OPENAI_QUERY_LENGTH = 60;

// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
// "Common image file types"
export const MEDIA_UPLOAD_ACCEPT = {
	// mimetype: extension
	"image/apng": "apng",
	"image/avif": "avif",
	"image/gif": "gif",
	"image/jpeg": "jpg",
	"image/png": "png",
	"image/svg+xml": "svg",
	"image/webp": "webp",
};

export const MAX_NUM_TAGS = 30;
export const TAG_REGEX = /^[.\-_ a-z0-9]{2,40}$/;

export const VOICES = [
	{ name: "Zeina", lang: "ar", gender: "f" },
	{ name: "Sanne", lang: "da-DK", gender: "f" },
	{ name: "Lotte", lang: "nl-NL", gender: "f" },
	{ name: "Charles", lang: "en-GB", gender: "m" },
	{ name: "Elizabeth", lang: "en-GB", gender: "f" },
	{ name: "Raveena", lang: "en-IN", gender: "f" },
	{ name: "Jane", lang: "en-US", gender: "f" },
	{ name: "Joe", lang: "en-US", gender: "m" },
	{ name: "Corazon", lang: "tl-PH", gender: "f" }, // https://culturalatlas.sbs.com.au/filipino-culture/filipino-culture-naming
	{ name: "Yvette", lang: "fr-FR", gender: "f" },
	{ name: "Jacques", lang: "fr-FR", gender: "m" },
	{ name: "Heidi", lang: "de-DE", gender: "f" },
	{ name: "Lothar", lang: "de-DE", gender: "m" },
	{ name: "Kajal", lang: "hi", gender: "f" },
	{ name: "Eszter", lang: "hu-HU", gender: "f" },
	{ name: "Mawar", lang: "id-ID", gender: "f" },
	{ name: "Bianca", lang: "it-IT", gender: "f" },
	{ name: "Hildur", lang: "is-IS", gender: "f" },
	{ name: "Aishah", lang: "ms-MY", gender: "f" },
	{ name: "Marit", lang: "no-NO", gender: "f" },
	{ name: "Ewa", lang: "pl-PL", gender: "f" },
	{ name: "Ines", lang: "pt-PT", gender: "f" },
	{ name: "Carmen", lang: "ro-RO", gender: "f" },
	{ name: "Olga", lang: "ru-RU", gender: "f" },
	{ name: "Dolores", lang: "es-ES", gender: "f" },
	{ name: "Jorge", lang: "es-ES", gender: "m" },
	{ name: "Astrid", lang: "sv-SE", gender: "f" },
	{ name: "Pakpao", lang: "th-TH", gender: "f" }, // https://move2thailand.com/question/what-are-the-most-common-thai-names/
	{ name: "Filiz", lang: "tr", gender: "f" },
	{ name: "Linh", lang: "vi-VN", gender: "f" }, // https://culturalatlas.sbs.com.au/vietnamese-culture/vietnamese-culture-naming
	{ name: "Zhiyu", lang: "zh-CN", gender: "f" },
	{ name: "Mizuki", lang: "ja-JP", gender: "f" },
	{ name: "Seoyeon", lang: "ko-KR", gender: "f" },
	{ name: "Tarja", lang: "fi-FI", gender: "f" },
	{ name: "Elena", lang: "el-GR", gender: "f" },
	// Uzbek,
	// Kazakh
	// Mongolian
	// Cebuano
	// Azerbaijani
];

export const voiceNameToLanguageCode = (name) => VOICES.find((voice) => voice.name === name)?.lang.split("-")[0];
export const languageCodeToVoiceName = (lang) =>
	VOICES.find((voice) => voice.lang.split("-")[0] === lang?.split("-")[0])?.name;

export const MIN_NUM_DIFFICULTY = 5;
export const HARD_THRESHOLD = 0.65;
export const EASY_THRESHOLD = 0.2;

export const CATEGORIES = [
	{ name: "Art & Literature", slug: "art-and-literature", tag: "art & literature", color: "#ccb696" },
	{ name: "Entertainment", slug: "entertainment", tag: "entertainment", color: "#ffc679" },
	{ name: "Geography", slug: "geography", tag: "geography", color: "#abfdd7" },
	{ name: "History", slug: "history", tag: "history", color: "#e2c7a6" },
	{ name: "Languages", slug: "languages", tag: "languages", color: "#fff0cc" },
	{ name: "Science & Nature", slug: "science-and-nature", tag: "science & nature", color: "#c6ea84" },
	{ name: "Sports", slug: "sports", tag: "sports", color: "#ffa77b" },
	{ name: "Trivia", slug: "trivia", tag: "trivia", color: "#c9bddb" },
];

export const GENERATOR_REASON_UNKNOWN = 0;
export const GENERATOR_REASON_GENERATE_QUESTIONS = 1;
export const GENERATOR_REASON_GENERATE_MEDIA = 2;
export const GENERATOR_REASON_NO_SLIDES = 3;
export const GENERATOR_REASON_CREATE_QUIZ = 4;
export const GENERATOR_REASON_CREATE_SLIDES = 5;
export const GENERATOR_REASON_BAD_TOPIC = 6;

export const GENREATOR_SERVICE_DISABLED = 0;
export const GENREATOR_SERVICE_OPENAI_GPT3_5 = 3;
export const GENREATOR_SERVICE_OPENAI_GPT4 = 4;
export const GENREATOR_SERVICE_OPENAI_GPT4_TURBO = 5;
export const GENREATOR_SERVICE_ANTHROPICAI_CLAUDE_3_OPUS = 6;
export const GENREATOR_SERVICE_ANTHROPICAI_CLAUDE_3_SONNET = 7;
export const GENREATOR_SERVICE_ANTHROPICAI_CLAUDE_3_HAIKU = 8;
export const GENREATOR_SERVICE_OPENAI_GPT4_O = 9;

export const GENREATOR_IMAGE_SERVICE_DISABLED = 0;
export const GENREATOR_IMAGE_SERVICE_DEZGO = 1;
export const GENREATOR_IMAGE_SERVICE_OPENAI = 2;
export const GENREATOR_IMAGE_SERVICE_LEONARDOAI = 3;

export const PLACE_CODE_SEPARATOR = "-";

export const PADDLE_VENDOR_ID = 170717;
export const PADDLE_PRODUCT_ID = 829349;
export const PADDLE_SANDBOX_VENDOR_ID = 12204;
export const PADDLE_SANDBOX_PRODUCT_ID = 50849;

export const FEATURE_DISABLE_CHECKBOX_SLIDES = false;
export const FEATURE_DISABLE_REORDER_SLIDES = false;

export const OPENAI_GPT3_5 = "gpt-3.5-turbo-0301";
export const OPENAI_GPT3_5_MAX_TOKENS = 4096;

export const OPENAI_GPT4 = "gpt-4-0314";
export const OPENAI_GPT4_MAX_TOKENS = 4096;

export const OPENAI_GPT4_TURBO = "gpt-4-turbo";
export const OPENAI_GPT4_TURBO_MAX_TOKENS = 4096;

export const OPENAI_GPT4_O = "gpt-4o";
export const OPENAI_GPT4_O_MAX_TOKENS = 4096;

export const ANTHROPICAI_CLAUDE_3_OPUS = "claude-3-opus-20240229";
export const ANTHROPICAI_CLAUDE_3_OPUS_MAX_TOKENS = 4096;

export const ANTHROPICAI_CLAUDE_3_SONNET = "claude-3-sonnet-20240229";
export const ANTHROPICAI_CLAUDE_3_SONNET_MAX_TOKENS = 4096;

export const ANTHROPICAI_CLAUDE_3_HAIKU = "claude-3-haiku-20240307";
export const ANTHROPICAI_CLAUDE_3_HAIKU_MAX_TOKENS = 4096;

export const OPENAI_DALL_E_3 = "dall-e-3";
export const LEONARDOAI = "leonardoai";

export const DEFAULT_MODEL = OPENAI_GPT4;
export const DEFAULT_IMAGE_MODEL = "stablediffusion_2_1_512px";

export const DEFAULT_NUMBER_OF_GENERATE_QUESTIONS = 8;
export const VALID_NUMBER_OF_GENERATE_QUESTIONS = [4, DEFAULT_NUMBER_OF_GENERATE_QUESTIONS, 12];
