import map from "lodash/map";

import {
	SLIDE_TYPES,
	SLIDE_TYPE_CHECK_BOXES,
	SLIDE_TYPE_CLASSIC,
	SLIDE_TYPE_INFO_SLIDE,
	SLIDE_TYPE_LOCATION,
	SLIDE_TYPE_PINPOINT,
	SLIDE_TYPE_RANGE,
	SLIDE_TYPE_REORDER,
	SLIDE_TYPE_TYPE_ANSWER,
} from "./app-constants.mjs";
import trans from "./helpers/trans";

export const PUBLIC_URL = process.env.NEXT_PUBLIC_URL;
export const WEB_SOCKET_URL = process.env.NEXT_PUBLIC_WEB_SOCKET_URL;
export const WEB_SOCKET_API_URL = process.env.NEXT_PUBLIC_WEB_SOCKET_API_URL;
export const GOOGLE_AUTH_URL = process.env.NEXT_PUBLIC_GOOGLE_AUTH_URL;
export const GOOGLE_AUTH_TOKEN_URL = process.env.NEXT_PUBLIC_GOOGLE_AUTH_TOKEN_URL;
export const GOOGLE_ANALYTICS = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;
export const MICROSOFT_CLARITY = process.env.NEXT_PUBLIC_MICROSOFT_CLARITY;
export const CDN_BASE_URL = process.env.NEXT_PUBLIC_CDN_BASE_URL;
export const UNSPLASH_API_URL = process.env.NEXT_PUBLIC_UNSPLASH_API_URL;
export const GIPHY_API_KEY = process.env.NEXT_PUBLIC_GIPHY_API_KEY;
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const API_WEB_SOCKET_GATEWAY_URL = process.env.NEXT_PUBLIC_API_WEB_SOCKET_GATEWAY_URL;
export const JOIN_URL = `${process.env.NEXT_PUBLIC_URL}%s/`;
export const COMMIT_HASH = process.env.NEXT_PUBLIC_COMMIT_HASH || "";
export const SHOW_COMMIT_HASH = process.env.NEXT_PUBLIC_SHOW_COMMIT_HASH;
export const IMAGE_CACHE_URL = process.env.NEXT_PUBLIC_IMAGE_CACHE_URL || `${API_URL}/image`;
export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
export const GOOGLE_MAPS_API_VERSION = "3.55";
export const GOOGLE_MAPS_LIBRARIES = ["places"];
export const ENABLE_SOLO_MODE = process.env.NEXT_PUBLIC_ENABLE_SOLO_MODE || false;
export const IMGPROXY_BASE_URL = process.env.NEXT_PUBLIC_IMGPROXY_BASE_URL || null;
export const CACHE_HEADER_TTL = parseInt(process.env.NEXT_PUBLIC_CACHE_HEADER_TTL) || 300; // Default: 5 minutes
export const ERROR_REQUEST_ENDPOINT = process.env.NEXT_PUBLIC_ERROR_REQUEST_ENDPOINT || "/next-api/log/error/";

export const DISCORD_LINK = "https://discord.gg/E2ZADNg48K";

export const ROOM_CODE_LENGTH = 6;
export const ROOM_CODE_SEPARATOR = " ";
export const ROOM_CODE_INPUT_PATTERN = `[0-9]{${ROOM_CODE_LENGTH / 2}}${ROOM_CODE_SEPARATOR}[0-9]{${
	ROOM_CODE_LENGTH / 2
}}`;
export const ROOM_CODE_PATTERN = `[0-9]{${ROOM_CODE_LENGTH}}`;

export const QUIZ_DEAL_ID = "deal";
export const QUIZ_STOP_ID = "stop";
export const QUIZ_VOTEMODE_ID = "vote";
export const QUIZ_AI_ID = "ai";

export const PLAY_STATUS_LOBBY = "lobby";
export const PLAY_STATUS_LOAD_QUIZ = "load quiz";
export const PLAY_STATUS_GAME_START = "game start";
export const PLAY_STATUS_SHOW_GET_READY = "show get ready";
export const PLAY_STATUS_HIDE_GET_READY = "hide get ready";
export const PLAY_STATUS_LOAD_SLIDE = "load slide";
export const PLAY_STATUS_SHOW_QUESTION = "show question";
export const PLAY_STATUS_SHOW_QUIZNAME = "show quizname";
export const PLAY_STATUS_SHOW_MEDIA = "show media";
export const PLAY_STATUS_SHOW_ANSWERS = "show answers";
export const PLAY_STATUS_WAIT_FOR_MEDIA = "wait for media";
export const PLAY_STATUS_WAIT_FOR_ANSWER = "wait for answer";
export const PLAY_STATUS_ALL_ANSWERS_RECEIVED = "all answers received";
export const PLAY_STATUS_SHOW_AVATAR_COLORS = "show avatar colors";
export const PLAY_STATUS_SHOW_CORRECT_ANSWER = "show correct answer";
export const PLAY_STATUS_SHOW_AVATAR_CORRECTNESS = "show avatar correctness";
export const PLAY_STATUS_SHOW_INSTRUCTOR_PACED = "show instructor paced";
export const PLAY_STATUS_SHOW_FUN_FACT = "show fun fact";
export const PLAY_STATUS_HIDE_CORRECT_ANSWER = "hide correct answer";
export const PLAY_STATUS_YOUTUBE_END_1 = "youtube end 1";
export const PLAY_STATUS_YOUTUBE_END_2 = "youtube end 2";
export const PLAY_STATUS_HIDE_SLIDE = "hide slide";
export const PLAY_STATUS_PREP_LEADERBOARD = "prep leaderboard";
export const PLAY_STATUS_SHOW_LEADERBOARD = "show leaderboard";
export const PLAY_STATUS_SHOW_WINNER = "show winner";
export const PLAY_STATUS_SHOW_RATE = "show rate"; // Players rate the quiz with 1-5 stars
export const PLAY_STATUS_RATE_DONE = "rate done";
export const PLAY_STATUS_SHOW_MAP_PIN = "show map pin";
export const PLAY_STATUS_ZOOM_MAP_PIN = "zoom map pin";
export const PLAY_STATUS_BROWSE_MAP = "browse map";
export const PLAY_STATUS_HIDE_FUN_FACT = "hide fun fact";
export const PLAY_STATUS_FLUSH = "flush";
export const PLAY_STATUS_END_OF_SLIDE = "end of slide";
export const PLAY_STATUS_VOTE_PRE_COMING_UP = "pre vote coming up";
export const PLAY_STATUS_VOTE_COMING_UP = "vote coming up";
export const PLAY_STATUS_VOTE_PREP = "prep vote";
export const PLAY_STATUS_VOTE_SHOW = "show vote";
export const PLAY_STATUS_VOTE_SUSPENSE = "vote decision";
export const PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ = "show next quiz";
export const PLAY_STATUS_VOTE_END = "vote end";
export const PLAY_STATUS_BEFORE_LAST_SLIDE = "before last slide";
export const PLAY_STATUS_EXIT = "exit";
export const PLAY_STATUS_HIDE_INPUT = "hide input";
export const PLAY_STATUS_SHOW_RESULTS = "show results";
export const PLAY_STATUS_AI_DISCLAIMER = "ai disclaimer";

export const STREET_VIEW_PORTAL_ID = "slide_media_portal";

// https://console.cloud.google.com/google/maps-apis/studio/maps?project=quizcat-310507

export const MAP_TYPE_DETAILED = "full";
export const MAP_TYPE_REGULAR = "easy";
export const MAP_TYPE_UNLABELED = "medium";
export const MAP_TYPE_FEATURELESS = "hard";
export const MAP_TYPE_SATELLITE = "satellite";
export const MAP_TYPE_HYBRID = "hybrid";
export const MAP_TYPE_TERRAIN = "terrain";

export const GOOGLE_MAPS_MAP_IDS = {
	/*
	[MAP_TYPE_DETAILED]: { name: "Full", id: "7d1d6b9831fc2dfa", description: "Detailed" },
	[MAP_TYPE_REGULAR]: { name: "Easy", id: "672a95b868badba0", description: "Regular" },
	[MAP_TYPE_UNLABELED]: { name: "Medium", id: "8646004ade0b5d0d", description: "Unlabeled" },
	[MAP_TYPE_FEATURELESS]: { name: "Hard", id: "a6c09a444a8cc7cf", description: "Featureless" },
};

export const GOOGLE_MAPS_RASTER_MAP_IDS = {
*/
	[MAP_TYPE_REGULAR]: { id: "c41f60a761572343", description: "Regular Map", preview: "regular.png" },
	[MAP_TYPE_DETAILED]: { id: "85a0dce00fc6ba18", description: "Detailed", exclude: true },
	[MAP_TYPE_UNLABELED]: { id: "2aee375f327ec825", description: "Unlabeled", preview: "unlabeled.png" },
	[MAP_TYPE_FEATURELESS]: { id: "2273a26c0efceceb", description: "Featureless", preview: "featureless.png" },
	[MAP_TYPE_HYBRID]: { typeId: "hybrid", description: "Hybrid", preview: "hybrid.png" },
	[MAP_TYPE_SATELLITE]: { typeId: "satellite", description: "Satellite", preview: "satellite.png" },
	[MAP_TYPE_TERRAIN]: { typeId: "terrain", description: "Terrain", exclude: true },
};

export const PLAY_STATUES_SLIDE = [
	PLAY_STATUS_LOAD_SLIDE,
	PLAY_STATUS_SHOW_QUESTION,
	PLAY_STATUS_SHOW_QUIZNAME,
	PLAY_STATUS_SHOW_MEDIA,
	PLAY_STATUS_SHOW_ANSWERS,
	PLAY_STATUS_WAIT_FOR_MEDIA,
	PLAY_STATUS_WAIT_FOR_ANSWER,
	PLAY_STATUS_ALL_ANSWERS_RECEIVED,
	PLAY_STATUS_SHOW_CORRECT_ANSWER,
	PLAY_STATUS_SHOW_AVATAR_COLORS,
	PLAY_STATUS_SHOW_AVATAR_CORRECTNESS,
	PLAY_STATUS_SHOW_INSTRUCTOR_PACED,
	PLAY_STATUS_SHOW_FUN_FACT,
	PLAY_STATUS_HIDE_FUN_FACT,
	PLAY_STATUS_HIDE_CORRECT_ANSWER,
	PLAY_STATUS_HIDE_SLIDE,
	PLAY_STATUS_YOUTUBE_END_1,
	PLAY_STATUS_YOUTUBE_END_2,
	PLAY_STATUS_SHOW_MAP_PIN,
	PLAY_STATUS_ZOOM_MAP_PIN,
	PLAY_STATUS_BROWSE_MAP,
	PLAY_STATUS_HIDE_INPUT,
	PLAY_STATUS_SHOW_RESULTS,
];

export const SLIDE_SKIP_STATUSES = PLAY_STATUES_SLIDE.concat([
	PLAY_STATUS_PREP_LEADERBOARD,
	PLAY_STATUS_SHOW_LEADERBOARD,
]);

export const PLAYLIST_SKIP_STATUSES = [
	PLAY_STATUS_SHOW_GET_READY,
	PLAY_STATUS_VOTE_COMING_UP,
	PLAY_STATUS_BEFORE_LAST_SLIDE,
	PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ,
	PLAY_STATUS_AI_DISCLAIMER,
];

export const REVEAL_TYPE_GRID_3X4 = "grid3x4";
export const REVEAL_TYPE_GRID_6X8 = "grid6x8";
export const REVEAL_TYPE_TIMELAPSE = "timelapse";
export const REVEAL_TYPE_TIMELAPSE_REVERSE = "timelapseReverse";
export const REVEAL_TYPE_TIMELAPSE_RANDOM = "timelapseRandom";
export const REVEAL_TYPE_ZOOM_20X = "zoom20x";
export const REVEAL_TYPE_IRIS = "iris";
export const REVEAL_TYPE_BLUR = "blur";
export const REVEAL_TYPE_GRID_3X4_SEQUENTIAL = "seqGrid3x4";
export const REVEAL_TYPE_GRID_6X8_SEQUENTIAL = "seqGrid6x8";
export const IS_REVEAL_TYPE_TIMELAPSE = (t) =>
	[REVEAL_TYPE_TIMELAPSE, REVEAL_TYPE_TIMELAPSE_REVERSE, REVEAL_TYPE_TIMELAPSE_RANDOM].includes(t);

export const ANSWER_TIME_EXTRA_SHORT = "extraShort";
export const ANSWER_TIME_SHORT = "short";
export const ANSWER_TIME_NORMAL = "normal";
export const ANSWER_TIME_LONG = "long";
export const ANSWER_TIME_EXTRA_LONG = "extraLong";
export const ANSWER_TIMES = [
	ANSWER_TIME_EXTRA_SHORT,
	ANSWER_TIME_SHORT,
	ANSWER_TIME_NORMAL,
	ANSWER_TIME_LONG,
	ANSWER_TIME_EXTRA_LONG,
];

export const QUIZZES_PER_PAGE = 20;
export const MAX_NR_OF_PLAYERS = 300;
export const MAX_NR_OF_PLAYERS_ADMIN = MAX_NR_OF_PLAYERS;

export const IMAGE_SIZE_QUANTIZATION = 6;

export const VISIBILITY_PRIVATE = "private";
export const VISIBILITY_UNLISTED = "unlisted";
export const VISIBILITY_PUBLIC = "public";

export const REVIEW_NEUTRAL = "neutral";
export const REVIEW_APPROVE = "approve";
export const REVIEW_DENY = "deny";

export const VOTE_MODE_NORMAL = "normal";
export const VOTE_MODE_CRAZY_CLICK_MODE = "crazy";
export const VOTE_MODE_HOST_DECIDES = "host";

export const SLIDE_TYPE_NAMES = {
	[SLIDE_TYPE_CLASSIC]: trans("Buttons"),
	[SLIDE_TYPE_CHECK_BOXES]: trans("Checkboxes"),
	[SLIDE_TYPE_REORDER]: trans("Reorder"),
	[SLIDE_TYPE_RANGE]: trans("Range"),
	[SLIDE_TYPE_LOCATION]: trans("Location"),
	[SLIDE_TYPE_PINPOINT]: trans("Pinpoint"),
	[SLIDE_TYPE_TYPE_ANSWER]: trans("Type answer"),
	[SLIDE_TYPE_INFO_SLIDE]: trans("Info slide"),
};

console.assert(
	SLIDE_TYPES.every((slideType) => Object.keys(SLIDE_TYPE_NAMES).includes(slideType)),
	"One or more slide type/s missing from SLIDE_TYPE_NAMES array."
);

export const FONT_NUNITO = `Nunito, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, Twemoji, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`;
export const FONT_ROBOTO = `Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, Twemoji, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`;

export const PAINT_BRUSHES = [
	{ thickness: 3, name: trans("Thin"), iconSize: 16 },
	{ thickness: 7, name: trans("Medium"), iconSize: 25 },
	{ thickness: 17, name: trans("Thick"), iconSize: 36 },
];

// https://github.com/nesbox/TIC-80/wiki/palett
export const PAINT_PALETTE = [
	{ hex: "#1a1c2c", key: "black", name: trans("Black") },
	{ hex: "#5d275d", key: "purple", name: trans("Purple") },
	{ hex: "#b13e53", key: "red", name: trans("Red") },
	{ hex: "#ff7986", key: "pink", name: trans("Pink") },
	{ hex: "#ef7d57", key: "orange", name: trans("Orange") },
	{ hex: "#ffcd75", key: "yellow", name: trans("Yellow") },
	{ hex: "#f8bd8c", key: "light-brown", name: trans("Light brown") },
	{ hex: "#a37454", key: "brown", name: trans("Brown") },
	{ hex: "#594131", key: "dark-brown", name: trans("Dark brown") },
	{ hex: "#a7f070", key: "light-green", name: trans("Light green") },
	{ hex: "#38b764", key: "green", name: trans("Green") },
	{ hex: "#257179", key: "dark-green", name: trans("Dark green") },
	{ hex: "#29366f", key: "dark-blue", name: trans("Dark blue") },
	{ hex: "#3b5dc9", key: "blue", name: trans("Blue") },
	{ hex: "#41a6f6", key: "light-blue", name: trans("Light blue") },
	{ hex: "#73eff7", key: "cyan", name: trans("Cyan") },
	{ hex: "#f4f4f4", key: "white", name: trans("White") },
	{ hex: "#94b0c2", key: "light-gray", name: trans("Light gray") },
	{ hex: "#566c86", key: "gray", name: trans("Gray") },
	{ hex: "#333c57", key: "dark-gray", name: trans("Dark gray") },
];

export const DEFAULT_MUSIC_VOLUME = 0.37;
export const DEFAULT_SFX_VOLUME = 0.5;
export const DEFAULT_VOICE_VOLUME = 0.7;
export const DEFAULT_MEDIA_VOLUME = 0.5;

export const RANGE_SLIDE_ERROR_SCORE_BREAKPOINT = 20;

export const RANGE_SLIDE_ERROR_SCORE = [
	[0, 2, 5, 9],
	[0, 0, 1, 2],
]; // Thresholds for 1000, 800, 600, 400

// Transform groups of two ore more letters to lowercase
export const PLAYER_NAME_TTS_TRANSFORM = (name) => name.replace(/(\p{L}\p{L}+)/gu, (s) => s.toLowerCase());

export const PLAYER_AVATAR_BASE_PATH = "/images/avatar/";
export const PLAYER_AVATAR_OUTLINE_BASE_PATH = "/images/avatar/outline/";

export const PLAYER_AVATAR_CANVAS_SIZE = 1000;

export const PLAYER_AVATAR_BACKGROUND_COLOR = "#276B74";

export const PLAYER_AVATAR_HAT_DEFAULT_SCALE = 0.5;
export const PLAYER_AVATAR_HAT_DEFAULT_Y = -0.1;

// DON'T FORGET CHECK CHECK IF FILENAME MATCH REGEX IN server/file.service.ts, IF CHANGED!
export const PLAYER_AVATAR_HATS = [
	"hat-1-A.svg",
	"hat-2-A.svg",
	"hat-3-A.svg",
	"hat-4-A.svg",
	"hat-5-A.svg",
	"hat-6-A.svg",
	"hat-7-A.svg",
	"hat-8-A.svg",
	"hat-9-A.svg",
	"hat-10-A.svg",
	"hat-11-A.svg",
	"hat-12-A.svg",
	"hat-13-A.svg",
	"hat-14-A.svg",
	"hat-15-A.svg",
	"hat-16-A.svg",
	"hat-17-A.svg",
	"hat-18-A.svg",
	"hat-19-A.svg",
	"hat-20-A.svg",
	"hat-21-A.svg",
	"hat-22-A.svg",
];

export const PLAYER_AVATAR_HAIR_DEFAULT_SCALE = 0.9;
export const PLAYER_AVATAR_HAIR_DEFAULT_Y = -0.1;

// DON'T FORGET CHECK CHECK IF FILENAME MATCH REGEX IN server/file.service.ts, IF CHANGED!
export const PLAYER_AVATAR_HAIRS = [
	"hair-1-A.svg",
	"hair-2-A.svg",
	"hair-3-A.svg",
	"hair-4-A.svg",
	"hair-5-A.svg",
	"hair-6-A.svg",
	"hair-7-A.svg",
	"hair-8-A.svg",
	"hair-9-A.svg",
	"hair-10-A.svg",
	"hair-11-A.svg",
	"hair-12-A.svg",
	"hair-13-A.svg",
	"hair-14-A.svg",
	"hair-15-A.svg",
	"hair-16-A.svg",
];

export const PLAYER_AVATAR_EYE_DEFAULT_SCALE = 0.5;
export const PLAYER_AVATAR_EYE_DEFAULT_Y = 0.2;

// DON'T FORGET CHECK CHECK IF FILENAME MATCH REGEX IN server/file.service.ts, IF CHANGED!
export const PLAYER_AVATAR_EYES = [
	"eyes-1-A.svg",
	"eyes-2-A.svg",
	"eyes-3-A.svg",
	"eyes-4-A.svg",
	"eyes-5-A.svg",
	"eyes-6-A.svg",
	"eyes-7-A.svg",
	"eyes-8-A.svg",
	"eyes-9-A.svg",
	"eyes-10-A.svg",
	"eyes-11-A.svg",
	"eyes-12-A.svg",
	"eyes-13-A.svg",
	"eyes-14-A.svg",
	"eyes-15-A.svg",
	"eyes-16-A.svg",
	"eyes-17-A.svg",
	"eyes-18-A.svg",
	"eyes-19-A.svg",
	"eyes-20-A.svg",
	"eyes-21-A.svg",
	"eyes-22-A.svg",
	"eyes-23-A.svg",
	"eyes-24-A.svg",
	"eyes-25-A.svg",
	"eyes-26-A.svg",
	"eyes-27-A.svg",
	"eyes-28-A.svg",
	"eyes-29-A.svg",
	"eyes-30-A.svg",
	"eyes-31-A.svg",
	"eyes-32-A.svg",
	"eyes-33-A.svg",
	"eyes-34-A.svg",
];

export const PLAYER_AVATAR_MOUTH_DEFAULT_SCALE = 0.5;
export const PLAYER_AVATAR_MOUTH_DEFAULT_Y = 0.5;

// DON'T FORGET CHECK CHECK IF FILENAME MATCH REGEX IN server/file.service.ts, IF CHANGED!
export const PLAYER_AVATAR_MOUTHS = [
	"mouth-1-A.svg",
	"mouth-2-A.svg",
	"mouth-3-A.svg",
	"mouth-4-A.svg",
	"mouth-5-A.svg",
	"mouth-6-A.svg",
	"mouth-7-A.svg",
	"mouth-8-A.svg",
	"mouth-9-A.svg",
	"mouth-10-A.svg",
	"mouth-11-A.svg",
	"mouth-12-A.svg",
	"mouth-13-A.svg",
	"mouth-14-A.svg",
	"mouth-15-A.svg",
	"mouth-16-A.svg",
	"mouth-17-A.svg",
	"mouth-18-A.svg",
	"mouth-19-A.svg",
	"mouth-20-A.svg",
	"mouth-21-A.svg",
	"mouth-22-A.svg",
	"mouth-23-A.svg",
	"mouth-24-A.svg",
	"mouth-25-A.svg",
	"mouth-26-A.svg",
	"mouth-27-A.svg",
	"mouth-28-A.svg",
	"mouth-29-A.svg",
];

export const PLAYER_AVATAR_BODY_DEFAULT_SCALE = 0.9;
export const PLAYER_AVATAR_BODY_DEFAULT_Y = 0.1;

// DON'T FORGET CHECK CHECK IF FILENAME MATCH REGEX IN server/file.service.ts, IF CHANGED!
export const PLAYER_AVATAR_BODYS = [
	"body-1-A.svg",
	"body-2-A.svg",
	"body-3-A.svg",
	"body-4-A.svg",
	"body-5-A.svg",
	"body-6-A.svg",
	"body-7-A.svg",
	"body-8-A.svg",
	"body-9-A.svg",
	"body-10-A.svg",
	"body-11-A.svg",
	"body-12-A.svg",
	"body-13-A.svg",
	"body-14-A.svg",
	"body-15-A.svg",
	"body-16-A.svg",
	"body-17-A.svg",
	"body-18-A.svg",
	"body-19-A.svg",
	"body-20-A.svg",
	"body-21-A.svg",
	"body-22-A.svg",
	"body-23-A.svg",
	"body-24-A.svg",
];

export const TAG_PAGE_DEFAULT_SORT_KEY = "ratingAvg";
export const TAG_PAGE_DEFAULT_SORT_ORDER = "desc";

export const GENERATOR_EXAMPLE_QUERYS = [
	[
		// Places
		["The Colosseum", "en"],
		["The Eiffel Tower", "en"],
		["Big Ben", "en"],
		["Statue of Liberty", "en"],
		["Pyramids of Giza", "en"],

		// Animals
		["Snakes", "en"],
		["Humpback whale", "en"],
		["Green sea turtle", "en"],
		["Sharks", "en"],
		["Crocodiles", "en"],

		// History
		["Black Death Plague", "en"],
		["World War II", "en"],
		["The Hundred years War", "en"],
		["French Revolution", "en"],
		["American Civil War", "en"],

		// People
		["Mahatma Gandhi", "en"],
		["Albert Einstein", "en"],
		["Nelson Mandela", "en"],
		["Queen Elizabeth II", "en"],
		["Elvis Presley", "en"],

		// Education
		["Kindergarten Math", "en"],
		["Kindergarten Phonics", "en"],
		["First Grade Reading Comprehension", "en"],
		["First Grade Basic Addition", "en"],
		["First Grade Basic Subtraction", "en"],
		["Second Grade Science", "en"],
		["Second Grade US States", "en"],
		["Third Grade Social Studies", "en"],
		["Third Grade Multiplication", "en"],
		["Fourth Grade Writing", "en"],
		["Fourth Grade Fractions and Decimals", "en"],
		["Fifth Grade Grammar", "en"],
		["Fifth Grade US History", "en"],
		["Sixth Grade World History", "en"],
		["Sixth Grade Earth Science", "en"],
		["Seventh Grade Life Science", "en"],
		["Seventh Grade Algebra", "en"],
		["Eighth Grade Algebra", "en"],
		["Eighth Grade US Government and Civics", "en"],
		["Ninth Grade Literature", "en"],
		["10th Grade World Literature", "en"],
		["11th Grade Geometry", "en"],
		["12th Grade US History", "en"],
		["High School Chemistry", "en"],
		["High School Creative Writing", "en"],
		["High School Human Anatomy and Physiology", "en"],
		["High School Environmental Science", "en"],
		["College Grade Calculus", "en"],
		["College Grade Biology", "en"],
		["College Grade Psychology", "en"],
		["College Preparatory English", "en"],
	],

	[
		// Arabic
		["الحضارات القديمة", "ar"], // Ancient civilizations
		["المشاهير العالميون", "ar"], // World celebrities
		["الأساطير اليونانية", "ar"], // Greek mythology
		["المعالم السياحية العالمية", "ar"], // World landmarks
		["الرياضيين الشهيرة", "ar"], // Famous athletes

		// Danish
		["Europæiske lande og hovedstæder", "da"], // European countries and capitals
		["Berømte opdagelsesrejsende", "da"], // Famous explorers
		["Verdens højeste bjerge", "da"], // World's tallest mountains
		["Store videnskabelige opdagelser", "da"], // Great scientific discoveries
		["Kendte kunstnere og deres mesterværker", "da"], // Famous artists and their masterpieces

		// Dutch
		["Landen van Afrika", "nl"], // Countries of Africa
		["Beroemde schilders", "nl"], // Famous painters
		["Historische gebeurtenissen", "nl"], // Historical events
		["Het menselijk lichaam", "nl"], // The human body
		["Sportieve prestaties", "nl"], // Athletic achievements

		// Filipino
		["Kabataang mga Gantimpalang Nobel", "tl"], // Young Nobel Prize Winners
		["Pinakamalalaking Bansa sa Mundo", "tl"], // Largest countries in the world
		["Mga Kilalang Scientist sa Kasaysayan", "tl"], // Famous Scientists in History
		["Mga Kilalang Empires sa Daigdig", "tl"], // Famous Empires in the World
		["Mga Kilalang Imbensyon sa Kasaysayan", "tl"], // Famous Inventions in History

		// French
		["Villes du monde", "fr"], // Cities of the world
		["Châteaux de France", "fr"], // Castles of France
		["Personnages historiques célèbres", "fr"], // Famous historical figures
		["Les Oscars", "fr"], // The Oscars
		["Capitales européennes", "fr"], // European capitals

		// German
		["Geografie der Weltmeere", "de"], // Geography of the world's oceans
		["Faszinierende Tierwelt Afrikas", "de"], // Fascinating wildlife of Africa
		["Berühmte Persönlichkeiten der Geschichte", "de"], // Famous personalities of history
		["Moderne Technologie und Erfindungen", "de"], // Modern technology and inventions
		["Bekannte Filme und Filmemacher", "de"], // Famous movies and filmmakers

		// Hindi
		["विश्व के उच्चतम पर्वत शिखर", "hi"], // World's highest mountain peaks
		["विश्व के महाद्वीप", "hi"], // Continents of the world
		["विश्व अर्थव्यवस्था", "hi"], // World economy
		["भारत के राज्य और राजधानी", "hi"], // States and capitals of India
		["विश्व के शासक और उनके साम्राज्य", "hi"], // Rulers and empires of the world

		// Hungarian
		["Világ országai fővárosaival", "hu"], // Countries and their capitals
		["Az emberi test részei", "hu"], // Parts of the human body
		["Az állatvilág fajai", "hu"], // Animal species
		["A világ tengeri állatai", "hu"], // Marine animals of the world
		["A tudományok területei", "hu"], // Fields of science

		// Indonesian
		["Bencana Alam Dunia", "id"], // World Natural Disasters
		["Pahlawan Dunia Terkenal", "id"], // Famous World Heroes
		["Tokoh-Tokoh Sejarah Dunia", "id"], // World Historical Figures
		["Negara dan Ibu Kota", "id"], // Countries and Capitals
		["Makanan Khas Dunia", "id"], // World Famous Foods

		// Italian
		["Paesi del Mondo", "it"], // Countries of the World
		["Capitale del Mondo", "it"], // World Capitals
		["Animali Selvaggi", "it"], // Wild Animals
		["Città Italiane", "it"], // Italian Cities
		["Storia Mondiale", "it"], // World History

		// Icelandic
		["Fólksfjöldi í löndum", "is"], // Population in countries
		["Heimskautsins", "is"], // The Arctic Circle
		["Náttúrufræði", "is"], // Natural science
		["Náttúruundur", "is"], // Natural Wonders
		["Bæjarfuglar", "is"], // City Birds

		// Malay
		["Sejarah Dunia", "ms"], // World history
		["Sains dan Teknologi", "ms"], // Science and technology
		["Kesenian dan Budaya", "ms"], // Arts and culture
		["Geografi dan Alam Semesta", "ms"], // Geography and the universe
		["Politik dan Ekonomi", "ms"], // Politics and economics

		// Norwegian
		["Verdens hovedsteder", "no"], // World capitals
		["Naturfenomener", "no"], // Natural phenomena
		["Mat og drikke", "no"], // Food and drink
		["Historiske hendelser", "no"], // Historical events
		["Kunst og kultur", "no"], // Art and culture

		// Polish
		["Państwa świata", "pl"], // Countries of the world
		["Wielkie odkrycia geograficzne", "pl"], // Great geographical discoveries
		["Słynne osoby historyczne", "pl"], // Famous historical figures
		["Nauka i technika", "pl"], // Science and technology
		["Kultura i sztuka", "pl"], // Culture and art

		// Portuguese
		["Países e Capitais", "pt"], // Countries and Capitals
		["Biologia", "pt"], // Biology
		["Filmes Clássicos", "pt"], // Classic Movies
		["Grandes Nomes da História", "pt"], // Great Names in History
		["Ciência e Tecnologia", "pt"], // Science and Technology

		// Romanian
		["Geografia lumii", "ro"], // World geography
		["Istorie universală", "ro"], // World history
		["Științe și tehnologie", "ro"], // Science and technology
		["Cultură generală", "ro"], // General culture
		["Sport și divertisment", "ro"], // Sports and entertainment

		// Russian
		["Великие открытия и изобретения", "ru"], // Great discoveries and inventions
		["Исторические даты и события", "ru"], // Historical dates and events
		["Знаменитости и кинематограф", "ru"], // Celebrities and cinema
		["Наука и технологии", "ru"], // Science and technology
		["Кулинария и мировая кухня", "ru"], // Culinary and world cuisine

		// Spanish
		["Grandes inventos", "es"], // Great inventions
		["Personajes históricos", "es"], // Historical figures
		["Culturas prehispánicas", "es"], // Prehispanic cultures
		["Geografía mundial", "es"], // World geography
		["Ciencia y tecnología actual", "es"], // Current science and technology

		// Swedish
		["Kända filosofiska verk", "sv"], // Famous philosophical works
		["Världens högsta berg", "sv"], // World's tallest mountains
		["Sveriges kungligheter", "sv"], // Swedish royalty
		["Kända konstnärer och deras verk", "sv"], // Famous artists and their works
		["Populära filmserier", "sv"], // Popular movie series

		// Thai
		["ประวัติศาสตร์โลก", "th"], // World History
		["วรรณกรรมโลก", "th"], // World Literature
		["สิ่งที่น่ารู้เกี่ยวกับธรรมชาติ", "th"], // Natural Wonders
		["อาหารทั่วโลก", "th"], // Global Cuisine
		["เทคโนโลยีขั้นสูง", "th"], // Advanced Technology

		// Turkish
		["Yeryüzündeki Ülkeler", "tr"], // Countries in the World
		["Ünlü Sanatçılar", "tr"], // Famous Artists
		["Sinema Tarihi", "tr"], // History of Cinema
		["Bilim Adamları ve Buluşları", "tr"], // Scientists and Their Inventions
		["Tarihteki Önemli Olaylar", "tr"], // Important Events in History

		// Vietnamese
		["Địa lý thế giới", "vi"], // World Geography
		["Lịch sử Việt Nam", "vi"], // Vietnamese History
		["Văn hóa đại chúng", "vi"], // Popular Culture
		["Khoa học và công nghệ", "vi"], // Science and Technology
		["Thể thao quốc tế", "vi"], // International Sports
	],
];

export const PLACE_TYPE_COUNTRY = "country";
export const PLACE_TYPE_STATE = "administrative_area_level_1";
export const PLACE_TYPE_PINPOINT = "";

export const AVATAR_LAYER_NONE = null;
export const AVATAR_LAYER_FEATURE_EYE = "eye";
export const AVATAR_LAYER_FEATURE_MOUTH = "mouth";
export const AVATAR_LAYER_FEATURE_HAIR = "hair";
export const AVATAR_LAYER_FEATURE_HAT = "hat";
export const AVATAR_LAYER_FEATURE_BODY = "body";
export const AVATAR_LAYER_LINE = "line";

export const AVATAR_FEATURE_PROPS = new Map([
	[
		AVATAR_LAYER_FEATURE_EYE,
		{
			defaultScale: PLAYER_AVATAR_EYE_DEFAULT_SCALE,
			defaultY: PLAYER_AVATAR_EYE_DEFAULT_Y,
			urls: PLAYER_AVATAR_EYES,
		},
	],
	[
		AVATAR_LAYER_FEATURE_MOUTH,
		{
			defaultScale: PLAYER_AVATAR_MOUTH_DEFAULT_SCALE,
			defaultY: PLAYER_AVATAR_MOUTH_DEFAULT_Y,
			urls: PLAYER_AVATAR_MOUTHS,
		},
	],
	[
		AVATAR_LAYER_FEATURE_HAIR,
		{
			defaultScale: PLAYER_AVATAR_HAIR_DEFAULT_SCALE,
			defaultY: PLAYER_AVATAR_HAIR_DEFAULT_Y,
			urls: PLAYER_AVATAR_HAIRS,
		},
	],
	[
		AVATAR_LAYER_FEATURE_HAT,
		{
			defaultScale: PLAYER_AVATAR_HAT_DEFAULT_SCALE,
			defaultY: PLAYER_AVATAR_HAT_DEFAULT_Y,
			urls: PLAYER_AVATAR_HATS,
		},
	],
	[
		AVATAR_LAYER_FEATURE_BODY,
		{
			defaultScale: PLAYER_AVATAR_BODY_DEFAULT_SCALE,
			defaultY: PLAYER_AVATAR_BODY_DEFAULT_Y,
			urls: PLAYER_AVATAR_BODYS,
		},
	],
]);

export const BOOST_TYPE_GENERATE_QUIZ = "generate quiz";
export const BOOST_TYPE_AUTO_COMPLETE = "auto complete";
export const BOOST_TYPE_TRANSLATE_QUIZ = BOOST_TYPE_GENERATE_QUIZ;

export const BOOST_STATUS_CANCEL = "cancel";
export const BOOST_STATUS_FREE = "free";
export const BOOST_STATUS_SUBSCRIBED = "subscribed";

export const PLAYING_HOST_CONNECTION_ID = "local";
