import React, { useEffect, useState, useCallback, useRef } from "react";
import useRefMounted from "@/hooks/useRefMounted";
import trans from "@/helpers/trans";
import { formatRoomCode, roomExists, roomCodeValid } from "@/helpers/roomCode";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import { ROOM_CODE_LENGTH, ROOM_CODE_INPUT_PATTERN, ROOM_CODE_SEPARATOR } from "@/constants";
import useLayoutStore from "@/stores/layout";

export default function JoinRoom({ onChange, placeholder, titleClassName, inputClassName }) {
	return (
		<div className="md:w-auto md:px-0 relative flex flex-row items-center w-full">
			<div className="lg:flex flex-col items-center hidden px-2">
				<div
					className={tailwindCascade(
						"whitespace-nowrap",
						"text-sm",
						"font-bold",
						"leading-tight",
						"text-white",
						titleClassName
					)}
				>
					{trans("Join game?")}
				</div>
			</div>
			<RoomCodeInput
				className="md:px-0 md:py-0 flex flex-row w-full px-4 py-4"
				inputClassName={tailwindCascade("md:w-24", "md:text-sm", inputClassName)}
				onChange={onChange}
				placeholder={placeholder}
			/>
		</div>
	);
}

export function RoomCodeInput({ className, inputClassName, onChange, placeholder }) {
	const [, mountedRef] = useRefMounted();

	const ref = useRef(null);
	const [roomCode, setRoomCode] = useState("");
	const [focus, setFocus] = useState(false);

	const setLayoutStore = useLayoutStore((state) => state.set);

	useEffect(
		() => void setLayoutStore((draft) => void (draft.roomCodeInputActive = !!focus)),
		[focus, setLayoutStore]
	);

	useEffect(() => {
		let dispose = null;
		if (roomCodeValid(roomCode)) {
			dispose = roomExists(roomCode, (roomExists) => {
				if (mountedRef.current && onChange) {
					onChange(roomCode, !roomExists);
				}
			});
		} else {
			if (onChange) {
				onChange(null, false);
			}
		}

		return () => {
			if (dispose) {
				dispose();
			}
		};
	}, [roomCode, onChange, mountedRef]);

	const onSubmit = useCallback((event) => {
		event.preventDefault();
		if (ref.current) {
			setRoomCode((ref.current.value || "").replace(/[^0-9]/g, ""));
		}
		return false;
	}, []);

	useEffect(() => {
		setRoomCode("");
		return () => void setRoomCode("");
	}, []);

	return (
		<form className={className} action="#" onSubmit={onSubmit}>
			<input
				ref={ref}
				className={tailwindCascade(
					"h-7",
					"focus:placeholder:text-transparent",
					"w-full",
					"my-auto",
					"text-lg",
					"font-bold",
					"text-center",
					"bg-white",
					"rounded-full",
					inputClassName
				)}
				type="text"
				inputMode="numeric"
				autoComplete="off"
				autoCorrect="off"
				autoCapitalize="none"
				spellCheck="false"
				placeholder={placeholder}
				maxLength={ROOM_CODE_LENGTH + 1}
				pattern={ROOM_CODE_INPUT_PATTERN}
				onChange={(event) => void setRoomCode((event?.target?.value || "").replace(/[^0-9]/g, ""))}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				value={formatRoomCode(roomCode)}
			/>
		</form>
	);
}
