import React, { forwardRef, useRef, useEffect } from "react";
import useForwardRef from "@/hooks/useForwardRef";
import IconDropdown from "@/images/icons/icon-dropdown.svg";
import { tailwindCascade } from "@/helpers/tailwindCascade";

export default forwardRef(function Selector({ children, className, innerClassName, value, ...props }, forwardedRef) {
	const ref = useForwardRef(forwardedRef);

	return (
		<div className={tailwindCascade("text-white relative inline-block w-full group", className)}>
			<div className=" group-hover:bg-opacity-20 bg-fff group-disabled:bg-opacity-0 absolute inset-0 bg-opacity-0 rounded-lg pointer-events-none" />
			<select
				ref={ref}
				className={tailwindCascade(
					"bg-opacity-30",
					"flex-grow",
					"w-full",
					"pl-4",
					"pr-8",
					"py-3",
					"font-bold",
					"bg-black",
					"rounded-lg",
					innerClassName
				)}
				value={value || ""}
				{...props}
			>
				{children}
			</select>
			<div className="right-4 top-1/2 absolute w-6 h-6 transform -translate-y-1/2 pointer-events-none">
				<IconDropdown className="w-full h-full" />
			</div>
		</div>
	);
});
