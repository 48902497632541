const isApp = (function isApp() {
	if (typeof window !== "undefined") {
		const matches = window.navigator.userAgent.match(/(\s|^)Quiz.com\/(\d).(\d).(\d)(\s|$)/);
		if (matches && matches.length === 6) {
			const major = parseInt(matches[2]) || 0;
			const minor = parseInt(matches[3]) || 0;
			const patch = parseInt(matches[4]) || 0;
			return { major, minor, patch };
		}
	}
	return false;
})();
export default isApp;
