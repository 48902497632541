import lerp from "lerp";
import clamp from "lodash/clamp";

function pad(value) {
	const stringValue = `${value.toString(16).toLowerCase()}`;
	return stringValue.length === 1 ? `0${stringValue}` : stringValue;
}

export default function shade(color, amount) {
	let prefix = "";

	if (color.indexOf("#") === 0) {
		color = color.slice(1);
		prefix = "#";
	}

	const c = parseInt(color, 16);
	let r = (c >> 16) & 0xff;
	let g = (c >> 8) & 0xff;
	let b = c & 0xff;

	if (amount < 0) {
		r = clamp(Math.round(lerp(r, 0x00, -amount)), 0, 0xff);
		g = clamp(Math.round(lerp(g, 0x00, -amount)), 0, 0xff);
		b = clamp(Math.round(lerp(b, 0x00, -amount)), 0, 0xff);
	} else if (amount > 0) {
		r = clamp(Math.round(lerp(r, 0x00, amount)), 0, 0xff);
		g = clamp(Math.round(lerp(g, 0x00, amount)), 0, 0xff);
		b = clamp(Math.round(lerp(b, 0x00, amount)), 0, 0xff);
	}

	return `${prefix}${pad(r)}${pad(g)}${pad(b)}`;
}
