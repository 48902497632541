import React, { forwardRef } from "react";
import isFinite from "lodash/isFinite";
import useRefMounted from "@/hooks/useRefMounted";

import { tailwindCascade } from "@/helpers/tailwindCascade";

function OutlineText({
	clip = false,
	textClassName,
	textStrokeColor = null,
	textStrokeWidth = null,
	textShadow = false,
	children,
}) {
	const [mounted] = useRefMounted();

	const style = {};
	style.WebkitTextStrokeColor = textStrokeColor || "#000000";
	style.WebkitTextStrokeWidth = isFinite(textStrokeWidth) ? `${textStrokeWidth / 4}em` : `${1 / 6}em`;

	const styleWithTextShadow = { textShadow: "0 0 1em #000" };
	style.WebkitTextStrokeColor = textStrokeColor || "#000000";
	style.WebkitTextStrokeWidth = isFinite(textStrokeWidth) ? `${textStrokeWidth / 4}em` : `${1 / 6}em`;

	return (
		<div className="relative inline-block">
			<div
				className={tailwindCascade(
					`${clip ? "px-0.5" : "px-0"}`,
					"z-3",
					"relative",
					"top-0",
					"left-0",
					{
						"w-full": clip,
						"overflow-hidden": clip,
					},
					textClassName
				)}
			>
				{children || ""}
			</div>
			{mounted && (
				<div
					className={tailwindCascade(
						`${clip ? "px-0.5" : "px-0"}`,
						"z-2",
						"absolute",
						"top-0",
						"left-0",
						"text-transparent",
						"pointer-events-none",
						"select-none",
						{
							"whitespace-nowrap": clip,
							"w-full": clip,
							"overflow-hidden": clip,
						},
						textClassName
					)}
					data-nosnippet
					style={textShadow ? styleWithTextShadow : style}
				>
					{children || ""}
				</div>
			)}
			{mounted && (
				<div
					className={tailwindCascade(
						`${clip ? "px-0.5" : "px-0"}`,
						"top-px",
						"z-1",
						"absolute",
						"left-0",
						"text-transparent",
						"pointer-events-none",
						"select-none",
						{
							"whitespace-nowrap": clip,
							"w-full": clip,
							"overflow-hidden": clip,
						},
						textClassName
					)}
					data-nosnippet
					style={style}
				>
					{children || ""}
				</div>
			)}
		</div>
	);
}
export default forwardRef(function Header(
	{
		textStrokeColor = null,
		textStrokeWidth = null,
		clip = false,
		wrapper = null,
		textClassName,
		textShadow = false,
		visible = true,
		children,
		className,
		...props
	},
	ref
) {
	const Wrapper = wrapper || "h1";

	return (
		<Wrapper
			ref={ref}
			className={tailwindCascade(
				"font-bold",
				"text-2xl",
				"text-white",
				{
					"whitespace-nowrap": clip,
					"overflow-hidden": clip,
					"opacity-0": !visible,
				},
				className
			)}
			{...props}
		>
			<OutlineText
				clip={clip}
				textStrokeWidth={textStrokeWidth}
				textShadow={textShadow}
				textClassName={textClassName}
			>
				{children}
			</OutlineText>
		</Wrapper>
	);
});
