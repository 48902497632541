import React, { useState, useEffect, useRef, useCallback, forwardRef } from "react";
import useForwardRef from "@/hooks/useForwardRef";

import CorrectIcon from "@/images/icons/icon-correct-checkbox-multicolor.svg";
import WrongIcon from "@/images/icons/icon-wrong-checkbox-multicolor.svg";

import { tailwindCascade } from "@/helpers/tailwindCascade";

export default forwardRef(function CheckboxSettings(
	{ children, className, checked, alignText = "right", onChange, type = "checkbox", ...props },
	forwardedRef
) {
	const ref = useForwardRef(forwardedRef);

	const [isChecked, setIsChecked] = useState(checked);
	useEffect(() => void (onChange && onChange(isChecked)), [isChecked]);

	useEffect(() => {
		if (ref.current) {
			ref.current.checked = checked;
		}
		setIsChecked(checked);
	}, [checked]);

	useEffect(() => {
		const onChange = () => {
			if (ref.current) {
				setIsChecked(ref.current ? !!ref.current.checked : false);
			}
		};

		if (ref.current) {
			ref.current.addEventListener("change", onChange);
			onChange(); // Initial
		}

		return () => {
			if (ref.current) {
				ref.current.removeEventListener("change", onChange);
			}
		};
	}, []);

	return (
		<div
			className={tailwindCascade(
				"flex",
				"justify-center",
				"items-center",
				"w-6",
				"h-6",
				{ "opacity-30": props.disabled },
				className
			)}
		>
			{isChecked ? (
				<CorrectIcon className="fill-white text-petrol-darker absolute w-6 h-6" />
			) : (
				<WrongIcon className="fill-white absolute w-6 h-6 text-white" />
			)}
			<input
				ref={ref}
				type={type}
				className="focus-visible:outline-white absolute w-6 h-6 rounded-md cursor-pointer"
				defaultChecked={isChecked}
				{...props}
			/>
		</div>
	);
});
