import React, { useEffect, useRef } from "react";

import useWebSocketStore from "@/stores/webSocket";

import { ROOM_JOIN } from "@/webSocket/webSocket";
import usePlayStore from "@/stores/play";
import useStopHosting from "@/hooks/useStopHosting";
import useStatusWithProgressStore from "@/stores/statusWithProgress";

const CONNECTION_TYPE_MASTER = "master";

export default function HostBrowsinQuizzesSocket({ isHostPage = false, isGeneratePage = false }) {
	const connected = useWebSocketStore((state) => state.connected);
	const reconnecting = useWebSocketStore((state) => state.reconnecting);
	const connectionType = useWebSocketStore((state) => state.connectionType);

	const stopHosting = useStopHosting();
	const stopHostingRef = useRef(stopHosting);
	useEffect(() => void (stopHostingRef.current = stopHosting), [stopHosting]);

	useEffect(() => {
		if (connected && connectionType === CONNECTION_TYPE_MASTER) {
			if (!isHostPage || isGeneratePage) {
				usePlayStore.getState().reset(null, false, true, false);
				useStatusWithProgressStore.getState().reset();

				const { roomId, addEventListener, removeEventListener } = useWebSocketStore.getState();
				if (roomId) {
					useWebSocketStore.getState().sendRoomMessage({ browsingQuizzes: true });
				}

				const onRoomJoin = (message) => {
					useWebSocketStore.getState().sendRoomMessage({ browsingQuizzes: true }, message.connectionId); // Send room message to joining user!
				};

				addEventListener(ROOM_JOIN, onRoomJoin);

				return () => {
					removeEventListener(ROOM_JOIN, onRoomJoin);
				};
			} else {
				const { roomId } = useWebSocketStore.getState();
				if (roomId) {
					useWebSocketStore.getState().sendRoomMessage({ browsingQuizzes: false });
				}
			}
		}
	}, [connected, connectionType, isHostPage, isGeneratePage]);

	useEffect(() => {
		if (
			!connected &&
			!reconnecting &&
			connectionType === CONNECTION_TYPE_MASTER &&
			!isHostPage &&
			!isGeneratePage
		) {
			if (stopHostingRef.current) {
				stopHostingRef.current();
			}
		}
	}, [connected, reconnecting, connectionType, isHostPage, isGeneratePage]);

	return null;
}
