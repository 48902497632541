import React, { PureComponent } from "react";

const canUseDOM = !!(typeof window !== "undefined" && window.document && window.document.createElement);

export default function withSideEffect(reducePropsToState, handleStateChangeOnClient, mapStateOnServer) {
	if (typeof reducePropsToState !== "function") {
		throw new Error("Expected reducePropsToState to be a function.");
	}
	if (typeof handleStateChangeOnClient !== "function") {
		throw new Error("Expected handleStateChangeOnClient to be a function.");
	}
	if (typeof mapStateOnServer !== "undefined" && typeof mapStateOnServer !== "function") {
		throw new Error("Expected mapStateOnServer to either be undefined or a function.");
	}

	function getDisplayName(WrappedComponent) {
		return WrappedComponent.displayName || WrappedComponent.name || "Component";
	}

	return function wrap(WrappedComponent) {
		if (typeof WrappedComponent !== "function") {
			throw new Error("Expected WrappedComponent to be a React component.");
		}

		let mountedInstances = [];
		let state;

		function emitChange() {
			state = reducePropsToState(
				mountedInstances.map(function (instance) {
					return instance.props;
				})
			);

			if (SideEffect.canUseDOM) {
				handleStateChangeOnClient(state);
			} else if (mapStateOnServer) {
				state = mapStateOnServer(state);
			}
		}

		class SideEffect extends PureComponent {
			static peek() {
				return state;
			}

			static rewind() {
				if (SideEffect.canUseDOM) {
					throw new Error("You may only call rewind() on the server. Call peek() to read the current state.");
				}

				let recordedState = state;
				state = undefined;
				mountedInstances = [];
				return recordedState;
			}

			componentDidMount() {
				// Although this shouldn"t be called on the server,
				// Enzyme *does* call it, so we have to make sure
				// that it only happens once using this flag check.
				if (SideEffect.canUseDOM) {
					mountedInstances.unshift(this);
					emitChange();
				}
			}

			componentDidUpdate() {
				emitChange();
			}

			componentWillUnmount() {
				const index = mountedInstances.indexOf(this);
				mountedInstances.splice(index, 1);
				emitChange();
			}

			render() {
				if (!SideEffect.canUseDOM) {
					// This only works for non-streaming SSR.
					// However, that"s the issue with the whole approach in general.
					mountedInstances.push(this);
					emitChange();
				}
				return <WrappedComponent {...this.props} />;
			}
		}

		// Try to use displayName of wrapped component
		SideEffect.displayName = `SideEffect(${getDisplayName(WrappedComponent)})`;

		// Expose canUseDOM so tests can monkeypatch it
		SideEffect.canUseDOM = canUseDOM;

		return SideEffect;
	};
}
