import create from "zustand";

const useLayoutStore = create((set) => ({
	mobileNavigationCategoriesVisible: false,
	containerScale: 1,
	roomCodeInputActive: false,
	aiQueryInputActive: false,

	reset: (callback) => {
		const draft = callback ? callback() : null;
		return set({
			mobileNavigationCategoriesVisible: (draft && draft.mobileNavigationCategoriesVisible) || false,
			containerScale: 1,
			roomCodeInputActive: (draft && draft.roomCodeInputActive) || false,
			aiQueryInputActive: (draft && draft.aiQueryInputActive) || false,
		});
	},

	set: (callback) => set((state) => callback(state)),
}));

export default useLayoutStore;
