import React, { useRef, useEffect } from "react";

export default function SyncedAnimatedDots({ duration = 200 }) {
	const ref = useRef(null);

	useEffect(() => {
		let mounted = true;

		let state = null;
		let request = requestAnimationFrame(function loop() {
			request = null;
			const newState = Math.round(Date.now() / duration) % 5;
			if (newState !== state) {
				state = newState;
				if (ref.current) {
					const dots = [...ref.current.querySelectorAll("span")];
					dots[0].style.opacity = state > 0 && state !== 4 ? 1 : 0;
					dots[1].style.opacity = state > 1 && state !== 4 ? 1 : 0;
					dots[2].style.opacity = state > 2 && state !== 4 ? 1 : 0;
				}
			}
			if (mounted) {
				request = requestAnimationFrame(loop);
			}
		});

		return () => {
			mounted = false;

			if (request) {
				cancelAnimationFrame(request);
			}
		};
	}, [duration]);

	return (
		<span ref={ref}>
			<span>.</span>
			<span>.</span>
			<span>.</span>
		</span>
	);
}
