import trans from "@/helpers/trans";

import { MAX_UPLOAD_SIZE } from "@/app-constants.mjs";

import { getData, patchData, postData, postDataForm } from "./helpers";

export async function apiGenerateQuiz(data) {
	try {
		const quiz = await postData("/generator/", data);
		return quiz;
	} catch (error) {
		return null;
	}
}

export async function apiTranslateQuiz(data) {
	try {
		const quiz = await postData("/generator/translate/", data);
		return quiz;
	} catch (error) {
		return null;
	}
}

export async function apiGeneratePDFQuiz(file) {
	if (file.size > MAX_UPLOAD_SIZE) {
		throw trans("Max %.1f MB allowed", MAX_UPLOAD_SIZE / 1024 / 1024);
	}

	try {
		const result = await postDataForm("/generator/pdf/", { file });
		return result;
	} catch (error) {
		return null;
	}
}

export async function apiPopulateAiQuizCache(data) {
	try {
		const quiz = await patchData("/generator/", data);
		return quiz;
	} catch (error) {
		return null;
	}
}

export async function apiAdminOpenAI(data) {
	try {
		const quiz = await postData("/generator/admin/openai", data);
		return quiz;
	} catch (error) {
		return null;
	}
}

export async function apiGetQueries() {
	const result = await getData(`/generator/queries`, false, false);
	return result;
}

export async function apiGetStatus(jobId) {
	const result = await getData(`/generator/status/${jobId}`);
	return result;
}

export async function apiPostModerationFlag(data) {
	const result = await postData("/generator/moderation/", data);
	return !!result?.flagged;
}

export async function apiCompleteSlide(slide, field, data) {
	return await postData("/generator/autocomplete", { slide, field, data });
}

export async function apiGenerateCoverImage(quiz) {
	return await postData("/generator/coverimage", quiz);
}

export async function apiAdminSetService(service) {
	try {
		return await postData("/settings/admin", {
			key: "generator_service",
			value: service,
		});
	} catch (error) {
		return null;
	}
}

export async function apiAdminSetImageService(imageService) {
	try {
		return await postData("/settings/admin", {
			key: "generator_image_service",
			value: imageService,
		});
	} catch (error) {
		return null;
	}
}

export async function apiAdminSetBannerStyle(bannerStyle) {
	try {
		return await postData("/settings/admin", {
			key: "banner_style",
			value: bannerStyle,
		});
	} catch (error) {
		return null;
	}
}

export async function apiGetService() {
	const result = await getData("/settings/generator_service");
	if (result?.key !== "generator_service") {
		return 0;
	}
	return parseInt(result?.value) || 0;
}

export async function apiGetImageService() {
	const result = await getData("/settings/generator_image_service");
	if (result?.key !== "generator_image_service") {
		return 0;
	}
	return parseInt(result?.value) || 0;
}

export async function apiAdminSetCache(cache) {
	try {
		return await postData("/settings/admin", {
			key: "generator_cache",
			value: cache,
		});
	} catch (error) {
		return null;
	}
}

export async function apiGetCache() {
	const result = await getData("/settings/generator_cache");
	if (result?.key !== "generator_cache") {
		return 0;
	}
	return parseInt(result?.value) || 0;
}

export async function apiGetDailyUsage() {
	return await getData("/generator/poll");
}
