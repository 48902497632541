import { PLAYING_HOST_CONNECTION_ID } from "@/constants";

const DISCONNECT_TIMEOUT = 5000; // 5 seconds until player is not considered disconnected

export function isPlayerConnected(player, excludeLocalPlayers = false) {
	if (!player.connectionId) {
		return false;
	}

	if (player.connectionId === PLAYING_HOST_CONNECTION_ID && excludeLocalPlayers) {
		return false;
	}

	if (player.disconnected) {
		return false;
	}

	if (player.spectating) {
		return false;
	}

	return true;
}

export function getConnectedPlayers(players, excludeLocalPlayers = false) {
	if (!players) {
		return [];
	}
	return players.filter((player) => isPlayerConnected(player, excludeLocalPlayers));
}
