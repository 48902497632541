import React, { useState, useCallback } from "react";
import Link from "next/link";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

const BOTTOM_STYLE = { height: "1px" };

export default function VerifyEmail() {
	return (
		<div
			className={tailwindCascade(
				"print:hidden",
				"bg-pink-dark",
				"shadow-inner-soft-1",
				"flex",
				"w-full",
				"py-4",
				"relative"
			)}
		>
			<div className="w-full">
				<div className="container mx-auto">
					<div className="flex flex-row items-center justify-center px-4 space-x-2 text-white">
						<p>
							{trans("Verify your email to create a quiz.")} {trans("Didn't receive a email?")}{" "}
							<Link legacyBehavior href="/user/email/verify/" prefetch={false}>
								<a className="underline">{trans("Resend confirmation here!")}</a>
							</Link>
						</p>
					</div>
				</div>
			</div>
			<div className="bg-pink absolute bottom-0 w-full" style={BOTTOM_STYLE} />
		</div>
	);
}
