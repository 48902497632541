import React, { useCallback, useRef, useEffect } from "react";
import { useRouter } from "next/router";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import { useImmer } from "use-immer";
import trans from "@/helpers/trans";
import SearchIcon from "@/images/icons/icon-search.svg";
import ClearIcon from "@/images/icons/icon-crosscircle.svg";
import useRefMounted from "@/hooks/useRefMounted";
import onWindowResize from "@/helpers/onWindowResize";
import { JOIN_URL } from "@/constants";
import { sprintf } from "sprintf-js";

export default function TopMenuSearchInput({ value, onUpdate = null, searchOpen }) {
	const TYPE_BUTTON = "button";
	const TYPE_SEARCH = "search";

	const [mounted, mountedRef] = useRefMounted();

	const router = useRouter();
	const routerRef = useRef(router);
	useEffect(() => void (routerRef.current = router), [router]);

	const inputRef = useRef(null);
	const onUpdateRef = useRef(onUpdate);

	const [state, updateState] = useImmer({
		type: TYPE_BUTTON,
		value: value || "",
		isBlur: true,
	});

	const delayedBlurTimeoutRef = useRef(null);
	const delayedBlur = useCallback(() => {
		updateState((draft) => void (draft.isBlur = true));
		if (delayedBlurTimeoutRef.current) {
			clearTimeout(delayedBlurTimeoutRef.current);
		}
		delayedBlurTimeoutRef.current = setTimeout(() => {
			delayedBlurTimeoutRef.current = null;
			if (mountedRef.current) {
				updateState((draft) => {
					if (draft.isBlur) {
						draft.type = TYPE_BUTTON;
					}
				});
			}
		}, 300); // Delay blur 300ms
	}, [mountedRef, updateState]);
	const delayedBlurRef = useRef(delayedBlur);

	useEffect(() => {
		if (delayedBlurTimeoutRef.current) {
			clearTimeout(delayedBlurTimeoutRef.current);
		}
		delayedBlurRef.current = delayedBlur;
		return () => {
			if (delayedBlurTimeoutRef.current) {
				clearTimeout(delayedBlurTimeoutRef.current);
			}
		};
	}, [delayedBlur]);

	useEffect(() => {
		if (onUpdateRef.current) {
			onUpdateRef.current(state.type === TYPE_SEARCH);
		}
		if (state.type === TYPE_SEARCH) {
			const options = { passive: true };
			const onTouchStart = () => {
				if (delayedBlurRef.current) {
					delayedBlurRef.current();
				}
			};
			window.addEventListener("touchstart", onTouchStart, options);
			return () => void window.addEventListener("touchstart", onTouchStart, options);
		}
	}, [state.type]);

	const onClick = useCallback(() => {
		updateState((draft) => {
			draft.type = TYPE_SEARCH;
			draft.isBlur = false;
		});
	}, [updateState]);

	const onFocus = useCallback(() => {
		updateState((draft) => {
			draft.type = TYPE_SEARCH;
			draft.isBlur = false;
		});
	}, [updateState]);

	const onBlur = useCallback(() => {
		if (delayedBlurRef.current) {
			delayedBlurRef.current();
		}
	}, []);

	const onChange = useCallback(
		(event) => {
			updateState((draft) => {
				draft.value = event.target.value;
				draft.isBlur = false;
			});
		},
		[updateState]
	);

	const onSubmit = useCallback(
		(event) => {
			event.preventDefault();
			if (/\d{3}\s*\d{3}/.test(state.value)) {
				const roomCode = state.value.replace(/[^\d]/g, "");
				if (routerRef.current) {
					routerRef.current.push(sprintf(JOIN_URL, roomCode));
				}
			} else {
				if (routerRef.current) {
					routerRef.current.push({
						pathname: "/results/",
						query: { q: inputRef.current.value || "" },
					});
				}
			}
		},
		[state.value]
	);

	const onReset = useCallback(() => {
		updateState((draft) => void (draft.value = ""));
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [updateState]);

	return (
		<div
			className={`max-w-none md:max-w-xs relative flex flex-col items-end justify-center ${
				searchOpen ? "w-full" : "w-10"
			} h-10`}
		>
			<form
				className={tailwindCascade(
					"absolute w-10 h-10 transition-[width,background-color,border] duration-300 md:transition-none overflow-hidden rounded-full border-3 border-black bg-[#e5e3dc] border-opacity-20",
					{
						"w-full bg-fff": state.type === TYPE_SEARCH,
						"border-[#e5e3dc]": state.type === TYPE_BUTTON,
					}
				)}
				onSubmit={onSubmit}
				onReset={onReset}
			>
				<input
					ref={inputRef}
					type={state.type}
					value={state.value}
					placeholder={trans("Search")}
					onClick={state.type === TYPE_BUTTON ? onClick : null}
					onFocus={state.type === TYPE_SEARCH ? onFocus : null}
					onBlur={state.type === TYPE_SEARCH ? onBlur : null}
					onChange={state.type === TYPE_SEARCH ? onChange : null}
					className={tailwindCascade(
						"absolute",
						"left-0",
						"top-0",
						"w-full",
						"border-0",
						"transition-[color,padding]",
						"duration-300",
						"md:transition-none",
						"text-left",
						"pl-4",
						"pr-4",
						"h-[34px]",
						"placeholder:text-black",
						"placeholder:opacity-70",
						"focus:placeholder:text-transparent",
						"text-black",
						"overflow-hidden",
						"text-base font-bold opacity-70",
						{
							"cursor-pointer text-transparent placeholder:text-transparent": state.type === TYPE_BUTTON,
							"cursor-text pr-20": state.type === TYPE_SEARCH,
						}
					)}
				/>
				<button
					type="submit"
					aria-label={trans("Search")}
					className={tailwindCascade(
						"top-[1px] right-2 absolute w-8 h-8 bg-[#e5e3dc] rounded-full transition-[right,background-color] duration-300 md:transition-none",
						{
							"pointer-events-none right-[1px]": state.type === TYPE_BUTTON,
							"bg-fff": state.type === TYPE_SEARCH,
						}
					)}
				>
					<SearchIcon className="relative w-8 h-8" />
				</button>
				<button
					type="reset"
					aria-label={trans("Reset")}
					className={tailwindCascade(
						"top-[1px] right-10 absolute w-8 h-8 bg-[#e5e3dc] rounded-full hidden transition-[background-color] duration-300 md:transition-none",
						{
							"pointer-events-none": state.type === TYPE_BUTTON,
							"bg-fff bg-opacity-100": state.type === TYPE_SEARCH,
							block: state.value,
						}
					)}
				>
					<ClearIcon className="relative w-8 h-8" />
				</button>
			</form>
		</div>
	);
}
