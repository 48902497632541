import create from "zustand";
import { persist } from "zustand/middleware";
import produce from "immer";

import localStorageGuard from "@/helpers/localStorageGuard";
import { GENERATOR_REASON_UNKNOWN } from "@/app-constants.mjs";

const useAIStore = create(
	persist(
		(set) => {
			return {
				error: false,
				reason: GENERATOR_REASON_UNKNOWN,
				query: null,
				language: null,
				questions: 8,
				images: true,
				progress: null,
				jobId: null,
				finished: false,
				processTime: {
					// TODO: Populate with avarage, not used right now (Overwritten in HostPage.jsx)
					questions: 120000,
					media: 7000,
				},

				set: (recipe) => set(produce(recipe)),

				reset: () =>
					set(
						produce((state) => {
							state.query = null;
							state.language = null;
							state.questions = 8;
							state.images = true;
							state.error = false;
							state.jobId = null;
							state.progress = 0;
							state.finished = false;
						})
					),
			};
		},
		{
			name: "ai",
			getStorage: () => localStorageGuard,
		}
	)
);

export default useAIStore;
