import { useCallback, useMemo } from "react";

import {
	SLIDE_TYPE_CHECK_BOXES,
	SLIDE_TYPE_CLASSIC,
	SLIDE_TYPE_INFO_SLIDE,
	SLIDE_TYPE_LOCATION,
	SLIDE_TYPE_PINPOINT,
	SLIDE_TYPE_RANGE,
	SLIDE_TYPE_REORDER,
	SLIDE_TYPE_TYPE_ANSWER,
} from "@/app-constants.mjs";
import {
	PLAY_STATUS_ALL_ANSWERS_RECEIVED,
	PLAY_STATUS_BROWSE_MAP,
	PLAY_STATUS_HIDE_FUN_FACT,
	PLAY_STATUS_HIDE_INPUT,
	PLAY_STATUS_HIDE_SLIDE,
	PLAY_STATUS_LOAD_SLIDE,
	PLAY_STATUS_SHOW_ANSWERS,
	PLAY_STATUS_SHOW_AVATAR_COLORS,
	PLAY_STATUS_SHOW_AVATAR_CORRECTNESS,
	PLAY_STATUS_SHOW_CORRECT_ANSWER,
	PLAY_STATUS_SHOW_FUN_FACT,
	PLAY_STATUS_SHOW_INSTRUCTOR_PACED,
	PLAY_STATUS_SHOW_MAP_PIN,
	PLAY_STATUS_SHOW_MEDIA,
	PLAY_STATUS_SHOW_QUESTION,
	PLAY_STATUS_SHOW_QUIZNAME,
	PLAY_STATUS_SHOW_RESULTS,
	PLAY_STATUS_WAIT_FOR_ANSWER,
	PLAY_STATUS_WAIT_FOR_MEDIA,
	PLAY_STATUS_YOUTUBE_END_1,
	PLAY_STATUS_YOUTUBE_END_2,
	PLAY_STATUS_ZOOM_MAP_PIN,
} from "@/constants";

export default function useStatusSequence(slideType, statusWithProgress) {
	const sequence = useMemo(() => {
		switch (slideType) {
			case SLIDE_TYPE_TYPE_ANSWER:
			case SLIDE_TYPE_CLASSIC:
			case SLIDE_TYPE_CHECK_BOXES:
			case SLIDE_TYPE_RANGE:
			case SLIDE_TYPE_REORDER:
			case SLIDE_TYPE_PINPOINT:
				return [
					PLAY_STATUS_LOAD_SLIDE,
					PLAY_STATUS_SHOW_QUIZNAME,
					PLAY_STATUS_SHOW_QUESTION,
					PLAY_STATUS_SHOW_MEDIA,
					PLAY_STATUS_SHOW_ANSWERS,
					PLAY_STATUS_WAIT_FOR_MEDIA,
					PLAY_STATUS_WAIT_FOR_ANSWER,
					PLAY_STATUS_ALL_ANSWERS_RECEIVED, // FADE OUT ANSWER RULER, WAIT .5s
					PLAY_STATUS_HIDE_INPUT,
					PLAY_STATUS_SHOW_RESULTS, // FADE IN RESULTS RULER
					PLAY_STATUS_SHOW_AVATAR_COLORS, // SHOW AVATARS ON RESULTS RULER, AFTER MINOR DELAY CHANGE RULER COLOR TO GRAY
					PLAY_STATUS_SHOW_CORRECT_ANSWER, // ANIMATE CORRECT ANSWER RISING
					PLAY_STATUS_SHOW_AVATAR_CORRECTNESS, // FADE IN FIELD COLORS, THEN FIELD SCORES
					PLAY_STATUS_YOUTUBE_END_1, // FADE OUT RESULTS RULER
					PLAY_STATUS_SHOW_FUN_FACT,
					PLAY_STATUS_YOUTUBE_END_2,
					PLAY_STATUS_HIDE_FUN_FACT,
					PLAY_STATUS_SHOW_INSTRUCTOR_PACED,
					PLAY_STATUS_HIDE_SLIDE,
				];
			case SLIDE_TYPE_INFO_SLIDE:
				return [
					PLAY_STATUS_LOAD_SLIDE,
					PLAY_STATUS_SHOW_MEDIA,
					PLAY_STATUS_SHOW_QUIZNAME,
					PLAY_STATUS_SHOW_QUESTION,
					PLAY_STATUS_SHOW_ANSWERS,
					PLAY_STATUS_WAIT_FOR_MEDIA,
					PLAY_STATUS_WAIT_FOR_ANSWER,
					PLAY_STATUS_YOUTUBE_END_1,
					PLAY_STATUS_SHOW_CORRECT_ANSWER,
					PLAY_STATUS_SHOW_FUN_FACT, // reference for hiding question and media
					PLAY_STATUS_HIDE_FUN_FACT,
					PLAY_STATUS_SHOW_INSTRUCTOR_PACED,
					PLAY_STATUS_YOUTUBE_END_2, // reference for hiding question and media
					PLAY_STATUS_HIDE_SLIDE,
				];
			case SLIDE_TYPE_LOCATION:
				return [
					PLAY_STATUS_LOAD_SLIDE,
					PLAY_STATUS_SHOW_QUIZNAME,
					PLAY_STATUS_SHOW_QUESTION,
					PLAY_STATUS_SHOW_MEDIA,
					PLAY_STATUS_SHOW_ANSWERS,
					PLAY_STATUS_WAIT_FOR_MEDIA,
					PLAY_STATUS_WAIT_FOR_ANSWER,
					PLAY_STATUS_ALL_ANSWERS_RECEIVED,
					PLAY_STATUS_SHOW_AVATAR_COLORS,
					PLAY_STATUS_SHOW_CORRECT_ANSWER,
					PLAY_STATUS_SHOW_AVATAR_CORRECTNESS,
					PLAY_STATUS_YOUTUBE_END_1,
					PLAY_STATUS_HIDE_SLIDE,
					PLAY_STATUS_SHOW_MAP_PIN,
					PLAY_STATUS_SHOW_FUN_FACT,
					PLAY_STATUS_HIDE_FUN_FACT,
					PLAY_STATUS_ZOOM_MAP_PIN,
					PLAY_STATUS_BROWSE_MAP,
					PLAY_STATUS_SHOW_INSTRUCTOR_PACED,
				];
			default:
				return [];
		}
	}, [slideType]);

	const getVisibility = useCallback(
		(firstVisibleStatus, firstInvisibleStatus = null) => {
			const i = sequence.indexOf(statusWithProgress.name);
			const i0 = sequence.indexOf(firstVisibleStatus);

			if (firstInvisibleStatus !== null) {
				const i1 = sequence.indexOf(firstInvisibleStatus);
				return i >= i0 && i < i1;
			} else {
				return i >= i0;
			}
		},
		[statusWithProgress.name, sequence]
	);

	const getProgress = useCallback(
		(activeStatus) => {
			const i = sequence.indexOf(statusWithProgress.name);
			const j = sequence.indexOf(activeStatus);
			return i < j ? 0 : i === j ? statusWithProgress.progress : 1;
		},
		[statusWithProgress.name, statusWithProgress.progress, sequence]
	);

	return { sequence, getVisibility, getProgress };
}
