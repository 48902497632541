// Must be module.exports, postcss/tailwind doesn't work with export const

module.exports = {
	TRANSPARENT: "transparent",
	CURRENT: "currentcolor",
	ERROR: "#ff6790",
	ERROR_30: "#ff67904d",

	THEME: "#FFFDF4",

	FFF: "#fff",

	BLUE: "#00A2CA",

	WHITE: "#fffae6",
	WHITE_20: "#fffae633",
	WHITE_30: "#fffae64d",
	WHITE_50: "#fffae67f",
	BLACK: "#000000",
	BLACK_70: "#000000b3",
	BLACK_50: "#0000007f",
	BLACK_30: "#0000004d",
	BLACK_10: "#E5E3DB",
	BLACK_15: "#ceccc5",

	WHITE_BLACK_10: "#e5e1cf",

	WHITE_HOVER: "#E3E0D5",

	GRAY: "#eae8e1",

	BEIGE: "#e5dcba",

	COAL: "#454545",
	COAL_DARK: "#303030",

	PETROL_LIGHTER: "#86CDE2",
	PETROL_LIGHT: "#308490",
	PETROL: "#23616a",
	PETROL_DARK: "#19444a",
	PETROL_DARKER: "#133338",
	PETROL_DARKEST: "#031D21",
	PETROL_DARKEST_WRONG_ANSWER: "#210703",

	BARBERPOLE1: "#254243",
	BARBERPOLE2: "#355d5f",
	BARBERPOLE3: "#487f81",
	BARBERPOLE4: "#96c7cb",

	PURPLE_LIGHT: "#906791",
	PURPLE: "#715f81",
	PURPLE_DARK: "#574a62",

	PURPLE_TWITCH: "#F1E7F3",
	RED_TWITCH: "#DE4949",

	PINK_LIGHT: "#ff6790",
	PINK: "#d5456c",
	PINK_DARK: "#9f314f",

	PINK2: "#EA7D75",

	GREEN_LIGHTER: "#c6ea84",
	GREEN_LIGHTER_30: "#c6ea844d",
	GREEN_LIGHT: "#00a76d",
	GREEN: "#216452",
	GREEN_DARK: "#194e40",
	GREEN_DARK_90: "#194e40e6",

	CYAN: "#00afc6",
	CYAN_DARK: "#004b55",

	YELLOW: "#FFC679",
	YELLOW_DARK: "#DE9D63",
	GOLD: "#DF7921",
	BROWN: "#6A5A48",

	YELLOW_BROWN: "#ffcd75",

	CORRECT_ANSWER: "#3d8f00",
	CORRECT_ANSWER_THEME_DARK: "#80B560",
	WRONG_ANSWER: "#a10e06",
	WRONG_ANSWER_THEME_DARK: "#D54636",
	WRONG_ANSWER_10: "#f2e6dd",

	ANSWER1: "#ABFDD7",
	ANSWER1_DARK: "#69AC8D",
	ANSWER1_AVATAR: "#54C6B9",
	ANSWER2: "#C6EA84",
	ANSWER2_DARK: "#96A852",
	ANSWER2_AVATAR: "#A9D162",
	ANSWER3: "#FFC679",
	ANSWER3_DARK: "#C48948",
	ANSWER3_AVATAR: "#EAAF60",
	ANSWER4: "#FFA7A0",
	ANSWER4_DARK: "#A56560",
	ANSWER4_AVATAR: "#F08F88",
	ANSWER5: "#C298EB",
	ANSWER6: "#96A8F2",

	BACKGROUND1: "#19444A",
	BACKGROUND2: "#47425D",
	BACKGROUND3: "#655648",
	BACKGROUND4: "#344B5F",
	BACKGROUND5: "#72454E",
	BACKGROUND6: "#21554D",
	BACKGROUND7: "#5D4259",
	BACKGROUND8: "#475341",

	LEADERBOARD_IDLE: "#85ACB9",
	LEADERBOARD_RIGHT: "#B1D470",
	LEADERBOARD_WRONG: "#FF8080",

	GENERATOR_BLUE: "#6FEEFF",

	PLACEHOLDER: "#819b98",

	HEX_TO_NORMALIZED_RGB_STRING: (hex) =>
		[0, 1, 2]
			.map((i) =>
				((hex && hex.substring ? parseInt(hex.substring(1 + i * 2, 3 + i * 2), 16) : 0) / 0xff).toFixed(3)
			)
			.join(","),
};
