import Link from "next/link";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import React, { forwardRef, useState } from "react";

export default forwardRef(function LinkButton({ className, href, children, isActive = false, ...props }, ref) {
	const [selected, setSelected] = useState();

	return (
		<div
			ref={ref}
			className={tailwindCascade(
				"text-lg font-bold text-black",
				"select-none",
				"lg:text-lg",
				"whitespace-nowrap",
				"text-base",
				"hover:text-opacity-100",
				"text-opacity-50",
				{ "text-opacity-100": isActive },
				className
			)}
			{...props}
		>
			<Link legacyBehavior href={href} prefetch={false}>
				<a>{children || ""}</a>
			</Link>
		</div>
	);
});
