import {
	PADDLE_PRODUCT_ID,
	PADDLE_SANDBOX_PRODUCT_ID,
	PADDLE_SANDBOX_VENDOR_ID,
	PADDLE_VENDOR_ID,
} from "@/app-constants.mjs";
import useAuthStore from "@/stores/auth";

const PADDLE_API_SRC = "https://cdn.paddle.com/paddle/paddle.js";

const loadPaddleCallbacks = [];

async function getPaddle() {
	return new Promise((resolve, reject) => {
		// If API is loaded, there is nothing else to do
		if (typeof window !== "undefined" && window.Paddle) {
			return resolve(window.Paddle);
		}

		// Otherwise, queue callback until API is loaded
		loadPaddleCallbacks.push({ resolve, reject });

		const scripts = [...document.getElementsByTagName("script")];
		const isLoading = scripts.some((script) => script.src === PADDLE_API_SRC);

		if (!isLoading) {
			const script = document.createElement("script");
			script.async = true;
			script.src = PADDLE_API_SRC;

			script.onload = () => {
				script.onerror = script.onload = null;

				const credentials = getCredentials();

				const Paddle = window.Paddle;
				if (credentials.environment) {
					Paddle.Environment.set(credentials.environment);
				}
				Paddle.Setup({ vendor: credentials.vendorId });

				while (loadPaddleCallbacks.length) {
					const { resolve, _reject } = loadPaddleCallbacks.shift();
					resolve(Paddle);
				}
			};

			script.onerror = () => {
				script.onerror = script.onload = null;
				while (loadPaddleCallbacks.length) {
					const { _resolve, reject } = loadPaddleCallbacks.shift();
					reject(new Error(`Failed to load ${PADDLE_API_SRC}`));
				}
			};

			const node = document.head || document.getElementsByTagName("head")[0];
			node.appendChild(script);
		}
	});
}

export async function checkout(options) {
	return new Promise((resolve, reject) => {
		getPaddle()
			.then((Paddle) => {
				const user = useAuthStore.getState().user;
				if (!user) {
					reject();
				}

				const credentials = getCredentials();

				const defaultOptions = {
					product: credentials.productId,
					email: user?.email,
					successCallback: (details) => {
						// console.log("Checkout complete:", { details });

						const nextBillDate = new Date();
						nextBillDate.setMonth(nextBillDate.getMonth() + 1);
						const nextBillDateISOString = nextBillDate.toISOString().substr(0, 10);

						useAuthStore.getState().set((state) => {
							state.user.endDate = nextBillDateISOString;
							state.user.isActive = true;
						});

						resolve();
					},
					closeCallback: () => void reject(),
					customData: { user: user?.id },
				};

				if (!options) {
					options = {};
				}

				Paddle.Checkout.open({
					...defaultOptions,
					...options,
				});
			})
			.catch((error) => void reject(error));
	});
}

function getCredentials() {
	return process.env.NEXT_PUBLIC_INSTANCE_NAME === "live"
		? {
				environment: null,
				vendorId: PADDLE_VENDOR_ID,
				productId: PADDLE_PRODUCT_ID,
		  }
		: {
				environment: "sandbox",
				vendorId: PADDLE_SANDBOX_VENDOR_ID,
				productId: PADDLE_SANDBOX_PRODUCT_ID,
		  };
}
