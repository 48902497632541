const ADD_EVENT_LISTENER_OPTIONS_PASSIVE = { passive: true };

const observers = new Set();

if (typeof window !== "undefined") {
	let width = window.visualViewport.width;
	let height = window.visualViewport.height;
	window.visualViewport.addEventListener(
		"resize",
		() => {
			if (window.visualViewport.width !== width || window.visualViewport.height !== height) {
				width = window.visualViewport.width;
				height = window.visualViewport.height;
				const values = [...observers.values()];
				for (let i = 0; i < values.length; i++) {
					values[i]();
				}
			}
		},
		ADD_EVENT_LISTENER_OPTIONS_PASSIVE
	);
}

export default function onWindowResize(callback) {
	observers.add(callback);
	return () => observers.delete(callback);
}
