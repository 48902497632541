import React, { useCallback, useEffect, useState } from "react";

import { apiGetPlayedQuizzes } from "@/api/quiz";

import Boost from "@/components/Boost";
import CookieConsent from "@/components/CookieConsent";
import TopMenu from "@/components/TopMenu";
import VerifyEmail from "@/components/VerifyEmail";
import InfoBarHost from "@/components/pages/play/InfoBarHost";

import isApp from "@/helpers/isApp";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import useAuthStore from "@/stores/auth";
import useLayoutStore from "@/stores/layout";
import useUserCacheStore from "@/stores/userCache";

export default function Layout({
	hideTopMenu = false,
	hideInfoBar = false,
	showInforBarIfDisconnected = false,
	isHostPage = false,
	isAdminPage = false,
	hideConfirmationBar = false,
	hideCookieConsent = false,
	children,
}) {
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const user = useAuthStore((state) => state.user);
	const [showVerifyEmail, setShowVerifyEmail] = useState(false);

	const updateUserCache = useUserCacheStore((state) => state.update);

	const [cookieConsentVisible, setCookieConsentVisible] = useState(false);
	const onUpdateookieConsent = useCallback((visible) => void setCookieConsentVisible(visible), []);

	useEffect(
		() => void setShowVerifyEmail(isAuthenticated && (!user || !user.isVerified) && !hideConfirmationBar),
		[isAuthenticated, user, hideConfirmationBar]
	);

	useEffect(() => {
		if (user?.id) {
			// on login populate list of played quizzes
			apiGetPlayedQuizzes()
				.then(
					(response) =>
						void updateUserCache((draft) => {
							draft.quizzesPlayed = new Map(response || []);
						})
				)
				.catch((err) => void console.error(err));
		} else {
			// on logout empty list of played quizzes
			updateUserCache((draft) => {
				draft.quizzesPlayed.clear();
			});
		}
	}, [updateUserCache, user?.id]);

	return (
		<div className="relative z-0 flex flex-col flex-auto">
			{!isApp && !hideCookieConsent && <CookieConsent onUpdate={onUpdateookieConsent} />}
			{showVerifyEmail && <VerifyEmail />}
			{!hideTopMenu && (
				<>
					<TopMenu isAdminPage={isAdminPage} cookieConsentVisible={cookieConsentVisible} />
				</>
			)}
			<>{children}</>
			{(!hideInfoBar || showInforBarIfDisconnected) && (
				<InfoBarHost
					isHost={isHostPage}
					playMode={isHostPage}
					showInforBarIfDisconnected={showInforBarIfDisconnected}
				/>
			)}
			<Boost />
		</div>
	);
}
