import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useImmer } from "use-immer";

import Modal from "@/components/Modal";
import SyncedAnimatedDots from "@/components/SyncedAnimatedDots";
import Button from "@/components/interactives/Button";

import { googleAnalyticsBoostBeginCheckout, googleAnalyticsBoostViewItem } from "@/helpers/gtag";
import { checkout } from "@/helpers/paddle";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import CheckIcon from "@/images/icons/check.svg";

import useAuthStore from "@/stores/auth";
import useBoostStore, {
	BOOST_DIALOG_STATE_CHECKOUT,
	BOOST_DIALOG_STATE_ERROR,
	BOOST_DIALOG_STATE_HIDDEN,
	BOOST_DIALOG_STATE_PENDING,
	BOOST_DIALOG_STATE_PROMPT,
	BOOST_DIALOG_STATE_VERIFY,
} from "@/stores/boost";

import {
	BOOST_STATUS_CANCEL,
	BOOST_STATUS_FREE,
	BOOST_STATUS_SUBSCRIBED,
	BOOST_TYPE_AUTO_COMPLETE,
	BOOST_TYPE_GENERATE_QUIZ,
} from "@/constants";

/*
const INTERACTIVE_MEDIA_ADS_SDK_SRC = "https://imasdk.googleapis.com/js/sdkloader/ima3.js";

const getInteractiveMediaAdsCallbacks = [];

async function getInteractiveMediaAds() {
	return new Promise((resolve, reject) => {
		// If API is loaded, there is nothing else to do
		if (typeof window !== "undefined" && window?.google?.ima) {
			return resolve(window.google.ima);
		}

		// Otherwise, queue callback until API is loaded
		getInteractiveMediaAdsCallbacks.push({ resolve, reject });

		const scripts = [...document.getElementsByTagName("script")];
		const isLoading = scripts.some((script) => script.src === INTERACTIVE_MEDIA_ADS_SDK_SRC);

		if (!isLoading) {
			const script = document.createElement("script");
			script.async = true;
			script.src = INTERACTIVE_MEDIA_ADS_SDK_SRC;

			script.onload = () => {
				script.onerror = script.onload = null;

				while (getInteractiveMediaAdsCallbacks.length) {
					const { resolve, _reject } = getInteractiveMediaAdsCallbacks.shift();
					resolve(window.google.ima);
				}
			};

			script.onerror = () => {
				script.onerror = script.onload = null;
				while (getInteractiveMediaAdsCallbacks.length) {
					const { _resolve, reject } = getInteractiveMediaAdsCallbacks.shift();
					reject(new Error(`Failed to load ${INTERACTIVE_MEDIA_ADS_SDK_SRC}`));
				}
			};

			const node = document.head || document.getElementsByTagName("head")[0];
			node.appendChild(script);
		}
	});
}
*/

function BulletPoint({ className, children, ...props }) {
	return (
		<div
			className={tailwindCascade(
				"flex flex-row text-white font-bold text-base leading-none space-x-2 pb-2",
				className
			)}
			{...props}
		>
			<CheckIcon className="w-4 h-4" />
			<div>{children}</div>
		</div>
	);
}

export default function Boost({ className, ...props }) {
	const user = useAuthStore((state) => state.user);
	const isSubscribed = useMemo(() => user?.endDate && new Date(user.endDate) >= new Date(), [user]);
	const isSubscribedRef = useRef(isSubscribed);
	useEffect(() => void (isSubscribedRef.current = isSubscribed), [isSubscribed]);

	const type = useBoostStore((state) => state.type);
	const hide = useBoostStore((state) => state.hide);
	const checkout = useBoostStore((state) => state.checkout);
	const dialogState = useBoostStore((state) => state.dialogState);
	const dailyUsage = useBoostStore((state) => state.dailyUsage);

	const triesLeft = useMemo(() => {
		if (type === BOOST_TYPE_GENERATE_QUIZ) {
			return dailyUsage.generateQuizLeft;
		} else if (type === BOOST_TYPE_AUTO_COMPLETE) {
			return dailyUsage.autoCompleteLeft;
		}
		return 0;
	}, [dailyUsage, type]);

	const onCancel = useCallback(() => hide(BOOST_STATUS_CANCEL), [hide]);
	const onClickTryForFree = useCallback(() => void hide(BOOST_STATUS_FREE), [hide]);
	const onClickGetBoost = useCallback(() => {
		googleAnalyticsBoostBeginCheckout();
		checkout();
	}, [checkout]);

	const [viewedItem, setViewedItem] = useState(false);
	useEffect(() => {
		if (dialogState !== BOOST_DIALOG_STATE_HIDDEN) {
			setViewedItem(true);
		}
	}, [dialogState]);
	useEffect(() => {
		if (viewedItem) {
			googleAnalyticsBoostViewItem();
		}
	}, [viewedItem]);

	/*
	const [adState, updateAdState] = useImmer({
		loaded: false,
		playing: false,
		error: false,
		adLoaded: false,
		adPlayed: false,
		remainingTime: 0,
	});

	const [interactiveMediaAds, setInteractiveMediaAds] = useState(null);
	const interactiveMediaAdsRef = useRef(null);
	useEffect(() => void (interactiveMediaAdsRef.current = interactiveMediaAds), [interactiveMediaAds]);

	const adWrapperRef = useRef(null);
	const adContainerRef = useRef(null);
	const adsManagerRef = useRef(null);
	const adDisplayContainerRef = useRef(null);

	const onResumeAd = useCallback(() => {
		hide(BOOST_STATUS_FREE);
	}, [hide]);
	const onResumeAdRef = useRef(onResumeAd);
	useEffect(() => void (onResumeAdRef.current = onResumeAd), [onResumeAd]);

	useEffect(() => {
		if (visible || locked) {
			let mounted = true;

			setInteractiveMediaAds(null);
			getInteractiveMediaAds()
				.then((ima) => {
					if (mounted) {
						setInteractiveMediaAds(ima);
					}
				})
				.catch((error) => {
					setInteractiveMediaAds(null);
				});

			return () => void (mounted = false);
		}
	}, [locked, visible]);

	useEffect(() => {
		if (interactiveMediaAds) {
			let mounted = true;

			updateAdState((draft) => {
				draft.loaded = false;
				draft.playing = false;
				draft.error = false;
				draft.adLoaded = false;
				draft.adPlayed = false;
				draft.remainingTime = 0;
			});

			if (adContainerRef.current && adWrapperRef.current) {
				const adDisplayContainer = (adDisplayContainerRef.current = new interactiveMediaAds.AdDisplayContainer(
					adContainerRef.current
				));
				const adsLoader = new interactiveMediaAds.AdsLoader(adDisplayContainer);

				const adsRequest = new interactiveMediaAds.AdsRequest();
				adsRequest.adTagUrl =
					"https://pubads.g.doubleclick.net/gampad/ads?" +
					"iu=/21775744923/external/single_ad_samples&sz=640x480&" +
					"cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&" +
					"gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=";

				const wrapper = adWrapperRef.current;
				const width = wrapper.clientWidth;
				const height = wrapper.clientHeight;
				adsRequest.linearAdSlotWidth = width;
				adsRequest.linearAdSlotHeight = height;
				adsRequest.nonLinearAdSlotWidth = width;
				adsRequest.nonLinearAdSlotHeight = height / 3;

				let adsManager = null;

				const onAdLoaded = () => {
					if (mounted) {
						updateAdState((draft) => {
							draft.adLoaded = true;
							draft.remainingTime = adsManager ? adsManager.getRemainingTime() : 0;
						});
					}
				};

				let countdownTimer = null;
				const onAdStarted = () => {
					if (mounted) {
						updateAdState((draft) => {
							draft.remainingTime = adsManager ? adsManager.getRemainingTime() : 0;
						});
						countdownTimer = setInterval(() => {
							if (adsManager && mounted) {
								updateAdState((draft) => {
									draft.remainingTime = adsManager ? adsManager.getRemainingTime() : 0;
								});
							}
						}, 1000);
					}
				};

				const onContentPauseRequested = () => {
					if (mounted) {
						updateAdState((draft) => {
							draft.adPlayed = false;
							draft.remainingTime = adsManager ? adsManager.getRemainingTime() : 0;
						});
					}
				};

				const onContentResumeRequested = () => {
					if (mounted) {
						updateAdState((draft) => {
							draft.loaded = false;
							draft.playing = false;
							draft.error = false;
							draft.adPlayed = true;
							draft.remainingTime = 0;
						});

						setInteractiveMediaAds(null);

						if (onResumeAdRef.current) {
							onResumeAdRef.current();
						}
					}
				};

				const onAdsManagerLoaded = (adsManagerLoadedEvent) => {
					if (mounted) {
						adsManager = adsManagerRef.current = adsManagerLoadedEvent.getAdsManager({ currentTime: 0 });

						adsManager.addEventListener(interactiveMediaAds.AdEvent.Type.LOADED, onAdLoaded);
						adsManager.addEventListener(interactiveMediaAds.AdEvent.Type.STARTED, onAdStarted);
						adsManager.addEventListener(
							interactiveMediaAds.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
							onContentPauseRequested
						);
						adsManager.addEventListener(
							interactiveMediaAds.AdEvent.Type.CONTENT_RESUME_REQUESTED,
							onContentResumeRequested
						);

						updateAdState((draft) => {
							draft.loaded = true;
							draft.remainingTime = adsManager ? adsManager.getRemainingTime() : 0;
						});
					}
				};
				adsLoader.addEventListener(
					interactiveMediaAds.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
					onAdsManagerLoaded,
					false
				);

				const onAdError = (adErrorEvent) => {
					console.error(adErrorEvent.getError());
					if (mounted) {
						updateAdState((draft) => {
							draft.loaded = false;
							draft.playing = false;
							draft.error = true;
							draft.remainingTime = 0;
						});

						setInteractiveMediaAds(null);
					}
				};
				adsLoader.addEventListener(interactiveMediaAds.AdErrorEvent.Type.AD_ERROR, onAdError, false);

				adsLoader.requestAds(adsRequest);

				return () => {
					mounted = false;

					if (countdownTimer) {
						clearInterval(countdownTimer);
					}

					if (adsManager) {
						adsManager.removeEventListener(interactiveMediaAds.AdEvent.Type.STARTED, onAdStarted);
						adsManager.removeEventListener(interactiveMediaAds.AdEvent.Type.LOADED, onAdLoaded);
						adsManager.removeEventListener(
							interactiveMediaAds.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
							onContentPauseRequested
						);
						adsManager.removeEventListener(
							interactiveMediaAds.AdEvent.Type.CONTENT_RESUME_REQUESTED,
							onContentResumeRequested
						);

						adsManager.destroy();
					}
					adsLoader.removeEventListener(interactiveMediaAds.AdErrorEvent.Type.AD_ERROR, onAdError, false);
					adsLoader.removeEventListener(
						interactiveMediaAds.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
						onAdsManagerLoaded,
						false
					);
				};
			} else {
				updateAdState((draft) => {
					draft.loaded = false;
					draft.playing = false;
					draft.error = true;
					draft.remainingTime = 0;
				});

				setInteractiveMediaAds(null);
			}
		}
	}, [interactiveMediaAds, locked, updateAdState, visible]);

	useEffect(() => {
		const wrapper = adWrapperRef.current;
		if (wrapper) {
			const resizeObserver = new ResizeObserver((entries) => {
				if (wrapper) {
					const width = wrapper.clientWidth;
					const height = wrapper.clientHeight;

					if (adContainerRef.current) {
						const container = adContainerRef.current;
						container.style.width = `${width}px`;
						container.style.height = `${height}px`;
					}

					if (adsManagerRef.current) {
						adsManagerRef.current.resize(width, height);
					}
				}
			});

			resizeObserver.observe(wrapper);

			return () => void resizeObserver.unobserve(wrapper);
		}
	}, []);

	const onClick = useCallback(() => {
		if (
			interactiveMediaAdsRef.current &&
			adsManagerRef.current &&
			adDisplayContainerRef.current &&
			adWrapperRef.current
		) {
			const interactiveMediaAds = interactiveMediaAdsRef.current;
			const adsManager = adsManagerRef.current;
			const adDisplayContainer = adDisplayContainerRef.current;

			adDisplayContainer.initialize();

			const wrapper = adWrapperRef.current;
			const width = wrapper.clientWidth;
			const height = wrapper.clientHeight;

			if (adContainerRef.current) {
				const container = adContainerRef.current;
				container.style.width = `${width}px`;
				container.style.height = `${height}px`;
			}

			try {
				adsManager.init(width, height, interactiveMediaAds.ViewMode.NORMAL);
				adsManager.start();

				updateAdState((draft) => {
					draft.playing = true;
				});
			} catch (adError) {
				console.error(adError);

				updateAdState((draft) => {
					draft.loaded = false;
					draft.playing = false;
					draft.error = true;
				});

				setInteractiveMediaAds(null);
			}
		}
	}, [updateAdState]);
	*/

	return dialogState === BOOST_DIALOG_STATE_PENDING ? (
		<Modal
			onCancel={onCancel}
			className="animate-fadein"
			style={{
				animationDelay: "1s",
			}}
		>
			<div className="font-bold text-white">{trans("Please wait...")}</div>
		</Modal>
	) : (
		dialogState !== BOOST_DIALOG_STATE_HIDDEN && (
			<Modal onCancel={onCancel}>
				{dialogState === BOOST_DIALOG_STATE_ERROR && (
					<div className="bg-error max-w-md p-8 font-bold text-white rounded-lg">
						{trans(
							"Unable to verify response from subscription service provider. Please come back later to see if your subscription has been activated, or contact us at info@quiz.com for assistance."
						)}
					</div>
				)}
				{dialogState === BOOST_DIALOG_STATE_VERIFY && (
					<div
						className="animate-fadein flex flex-row items-center justify-center w-full h-full font-bold text-white pointer-events-auto"
						style={{
							animationDelay: "3s",
						}}
					>
						<span>{trans("Please wait")}</span>
						<SyncedAnimatedDots />
					</div>
				)}
				{dialogState === BOOST_DIALOG_STATE_PROMPT && (
					<>
						{/*
						<button
							className={tailwindCascade(
								"absolute cursor-default block top-0 left-0 w-full h-full overflow-hidden",
								{
									"z-0 pointer-events-none invisible": !adState.playing,
									"z-40 pointer-events-auto visible": adState.playing,
								}
							)}
							onClick={onCancel}
						>
							<div className="md:py-4 md:px-4 container relative flex flex-col items-center justify-center w-full h-full m-auto pointer-events-auto">
								<div className="pb-16/9 relative w-full bg-black">
									<div className="absolute w-full h-full">
										<div className="relative w-full h-full">
											<div ref={adWrapperRef} className="w-full h-full">
												<div ref={adContainerRef} className="relative"></div>
											</div>
										</div>
									</div>
									<div className="-bottom-5 opacity-80 absolute right-0 text-xs font-bold leading-none text-white">
										{trans("Ad will end in %d", Math.round(adState.remainingTime))}
									</div>
								</div>
							</div>
						</button>
							*/}
						{true /* !adState.playing */ && (
							<div className="bg-purple-light rounded-2xl flex flex-col items-center w-full max-w-[24rem] gap-8 md:px-12 sm:px-8 px-4 py-8 sm:py-12 border-4 border-black border-solid">
								<img
									src="/images/logo/boost-multicolor.svg"
									className="w-76 block h-auto"
									alt={trans("Boost")}
								/>

								<div className="flex flex-col w-full gap-4">
									{triesLeft === 0 ? (
										<>
											<div className="bg-opacity-20 whitespace-nowrap flex flex-col items-center justify-center w-full h-12 overflow-hidden text-base font-bold leading-none text-center text-black bg-black rounded-full select-none">
												{trans("No more tries")}
											</div>
											{/*
											<div className="bg-purple-light flex flex-col items-center w-full">
												<Button
													elementType="a"
													border={4}
													color="answer2"
													className="whitespace-nowrap z-1 w-full overflow-hidden text-base font-bold text-center"
													onClick={onClick}
													disabled={!adState.loaded || adState.playing || adState.error}
												>
													{trans("Watch Ad – Get 1 more")}
												</Button>
												{adState.error && (
													<div className="opacity-80 py-2 text-xs font-bold leading-none text-center text-white">
														{trans(
															"Unable to show ad, please disable Ad Blocker and try again!"
														)}
													</div>
												)}
											</div>
											*/}
										</>
									) : (
										<>
											{type === BOOST_TYPE_GENERATE_QUIZ && (
												<Button
													elementType="a"
													border={4}
													color="answer2"
													className="whitespace-nowrap w-full overflow-hidden text-base font-bold text-center"
													onClick={onClickTryForFree}
												>
													{trans("Try for free – %d time(s) left", triesLeft)}
												</Button>
											)}
											{type === BOOST_TYPE_AUTO_COMPLETE && (
												<Button
													elementType="a"
													border={4}
													color="answer2"
													className="whitespace-nowrap w-full overflow-hidden text-base font-bold text-center"
													onClick={onClickTryForFree}
												>
													{trans("%d more free tries in Editor", triesLeft)}
												</Button>
											)}
										</>
									)}

									<Button
										elementType="a"
										border={4}
										color="answer3"
										className="whitespace-nowrap w-full overflow-hidden text-base font-bold text-center"
										onClick={onClickGetBoost}
									>
										{trans("Get Boost – $1.99/month")}
									</Button>
								</div>

								<div className="flex flex-col">
									<BulletPoint>{trans("Generate unlimited AI content")}</BulletPoint>
									<BulletPoint>{trans("Support our development")}</BulletPoint>
								</div>
							</div>
						)}
					</>
				)}
			</Modal>
		)
	);
}
