import create from "zustand";
import { persist } from "zustand/middleware";
import produce, { enableMapSet } from "immer";
import { PlayState, Report } from "@/types/play";

import {
	PLAY_STATUS_SHOW_GET_READY,
	PLAY_STATUS_HIDE_GET_READY,
	PLAY_STATUS_SHOW_QUESTION,
	PLAY_STATUS_SHOW_MEDIA,
	PLAY_STATUS_SHOW_ANSWERS,
	PLAY_STATUS_WAIT_FOR_ANSWER,
	PLAY_STATUS_SHOW_CORRECT_ANSWER,
	PLAY_STATUS_SHOW_FUN_FACT,
	PLAY_STATUS_HIDE_CORRECT_ANSWER,
	PLAY_STATUS_HIDE_SLIDE,
	PLAY_STATUS_YOUTUBE_END_1,
	PLAY_STATUS_YOUTUBE_END_2,
	PLAY_STATUS_SHOW_MAP_PIN,
	PLAY_STATUS_ZOOM_MAP_PIN,
	PLAY_STATUS_GAME_START,
	PLAY_STATUS_LOBBY,
	PLAY_STATUS_BROWSE_MAP,
	PLAY_STATUS_LOAD_SLIDE,
	VOTE_MODE_NORMAL,
	PLAYING_HOST_CONNECTION_ID,
} from "@/constants";
import { SLIDE_TYPE_INFO_SLIDE } from "@/app-constants.mjs";
import { Player } from "@/types/player";
import { Team } from "@/types/team";

enableMapSet();

const usePlayStore = create<PlayState>(
	persist(
		(set, get) => ({
			quiz: null,
			setQuiz: (quiz) => set({ quiz }),

			owner: null,

			code: null,
			setCode: (code) => set({ code }),

			paused: false,
			setPaused: (paused) => set({ paused }),
			pauseDuration: 0,
			pauseTimestamp: 0,
			slideTimestamp: 0,

			playlistSkip: false,
			setPlaylistSkip: (playlistSkip) => set({ playlistSkip }),

			haveLocalPlayer: false,

			slideIndex: -1,
			numPlayedQuestions: 0,

			slideLoaded: false,
			slideComplete: false,
			setSlideLoaded: (slideLoaded) => set({ slideLoaded }),
			setSlideComplete: (slideComplete) => set({ slideComplete }),
			slideSkipToNext: false,
			setSlideSkipToNext: (slideSkipToNext) => set({ slideSkipToNext }),
			slideWinnerRevealed: false,
			setSlideWinnerRevealed: (slideWinnerRevealed) => set({ slideWinnerRevealed }),

			skippedSlides: [],
			addSkippedSlide: () =>
				set(
					produce((state) => {
						state.skippedSlides.push(state.slideIndex);
					})
				),

			leaderboard: [],
			setLeaderboard: (leaderboard) => set({ leaderboard }),

			report: [],

			recommendedQuizzes: [],
			setRecommendedQuizzes: (recommendedQuizzes) => set({ recommendedQuizzes }),

			nextQuiz: null,
			setNextQuiz: (nextQuiz) => set({ nextQuiz }),

			gameStartedFlag: false,
			gameEndedFlag: false,

			updateStatus: (name, duration = 0, callback = null) =>
				set(
					produce((state) => {
						switch (name) {
							case PLAY_STATUS_GAME_START:
								state.slideLoaded = false;
								state.slideComplete = false;
								state.slideWinnerRevealed = false;
								state.slideIndex = 0;
								state.numPlayedQuestions = 0;
								state.owner = state.quiz?.slides[0]?.quiz?.owner || null;
								state.nextQuiz = null;
								break;
							case PLAY_STATUS_SHOW_GET_READY:
								state.slideLoaded = false;
								state.slideComplete = false;
								state.slideWinnerRevealed = false;
								state.nextQuiz = null;
								break;
							case PLAY_STATUS_HIDE_GET_READY:
								state.slideLoaded = false;
								state.slideComplete = false;
								state.slideWinnerRevealed = false;
								state.nextQuiz = null;
								break;
							case PLAY_STATUS_SHOW_QUESTION:
							case PLAY_STATUS_SHOW_MEDIA:
							case PLAY_STATUS_SHOW_ANSWERS:
								state.slideLoaded = false;
								state.slideComplete = false;
								state.slideWinnerRevealed = false;
								state.nextQuiz = null;
								break;
							case PLAY_STATUS_WAIT_FOR_ANSWER:
								state.slideLoaded = false;
								state.slideComplete = false;
								state.slideWinnerRevealed = false;
								state.nextQuiz = null;
								break;
							case PLAY_STATUS_SHOW_CORRECT_ANSWER:
							case PLAY_STATUS_HIDE_CORRECT_ANSWER:
							case PLAY_STATUS_SHOW_FUN_FACT:
							case PLAY_STATUS_YOUTUBE_END_1:
							case PLAY_STATUS_YOUTUBE_END_2:
							case PLAY_STATUS_SHOW_MAP_PIN:
								state.slideLoaded = false;
								state.slideComplete = false;
								state.slideWinnerRevealed = false;
								state.nextQuiz = null;
								break;
							case PLAY_STATUS_ZOOM_MAP_PIN:
							case PLAY_STATUS_BROWSE_MAP:
								state.slideLoaded = false;
								state.slideComplete = false;
								state.slideWinnerRevealed = false;
								state.nextQuiz = null;
								break;
							case PLAY_STATUS_HIDE_SLIDE:
							default:
								state.slideLoaded = false;
								state.slideComplete = false;
								state.slideWinnerRevealed = false;
								break;
						}

						if (callback) {
							callback(state);
						}
					})
				),

			preview: (slideIndex) =>
				set(
					produce((state: PlayState) => {
						state.paused = false;
						state.previewMode = true;

						state.playlistSkip = false;

						state.slideIndex = slideIndex;
						state.numPlayedQuestions = 0;
						state.slideLoaded = false;
						state.slideComplete = false;
						state.slideWinnerRevealed = false;
						state.slideSkipToNext = false;

						state.players.clear();
						const player: Player = {
							connectionId: PLAYING_HOST_CONNECTION_ID,
							disconnected: false,
							spectating: false,
							name: "",
							avatar: null,
							points: 0,
							previousPoints: 0,
							history: {},
							typeAnswerIsCorrect: null,
							typeAnswerValue: "",
							rank: undefined,
							wrongTypeAnswerSubmitted: null,
							inactive: false,
						};
						state.players.set(player.connectionId, player);

						state.haveLocalPlayer = state.players.has(PLAYING_HOST_CONNECTION_ID);

						state.sentGuestState = {};

						state.gameStartedFlag = false;
						state.gameEndedFlag = false;

						state.voteMode = VOTE_MODE_NORMAL;
					})
				),

			reset: (callback = null, resetPlayers = false, resetQuiz = false) =>
				set(
					produce((state: PlayState) => {
						if (resetQuiz) {
							state.quiz = null;
							state.aiMode = false;
							state.aiGenerating = false;
						}

						state.paused = false;
						state.previewMode = false;

						state.playlistSkip = false;

						state.slideIndex = -1;
						state.numPlayedQuestions = 0;
						state.slideLoaded = false;
						state.slideComplete = false;
						state.slideWinnerRevealed = false;
						state.slideSkipToNext = false;

						state.skippedSlides = [];

						state.nextQuiz = null;

						state.owner = null;

						state.leaderboard = [];

						if (resetPlayers) {
							state.players.clear();
						} else {
							state.players.forEach((player) => {
								player.points = 0;
								player.previousPoints = 0;
								player.history = {};
								player.typeAnswerIsCorrect = null;
								player.typeAnswerValue = "";
								player.ratings = {};
								player.rank = undefined;
								player.wrongTypeAnswerSubmitted = null;
								player.inactive = false;
								delete player.answerProgress;
								delete player.selectedAnswer;
							});
						}

						state.haveLocalPlayer = state.players.has(PLAYING_HOST_CONNECTION_ID);

						state.sentGuestState = {};

						state.gameStartedFlag = false;
						state.gameEndedFlag = false;

						state.voteMode = VOTE_MODE_NORMAL;

						if (callback) {
							callback(state);
						}
					})
				),

			nextSlide: () =>
				set(
					produce((state: PlayState) => {
						state.paused = false;

						state.playlistSkip = false;

						if (state.quiz.slides[state.slideIndex].type !== SLIDE_TYPE_INFO_SLIDE) {
							state.numPlayedQuestions++;
						}

						state.slideIndex++;
						state.slideLoaded = false;
						state.slideComplete = false;
						state.slideWinnerRevealed = false;
						state.slideSkipToNext = false;

						if (state.quiz?.slides && state.slideIndex < state.quiz.slides.length) {
							state.owner = state.quiz?.slides[state.slideIndex]?.quiz?.owner;
						}

						const playersArray = Array.from(state.players.values());

						if (
							state.slideIndex >= 1 &&
							!usePlayStore.getState().skippedSlides.includes(state.slideIndex - 1)
						) {
							// Mark player as inactive if last question was not answered
							for (let i = 0; i < playersArray.length; i++) {
								const player = playersArray[i];
								player.inactive =
									!(state.slideIndex - 1 in player.history) &&
									player.wrongTypeAnswerSubmitted !== state.slideIndex - 1;
							}
						}

						// Reset previousPoints and rank on next slide
						playersArray.sort((a, b) => b.points - a.points);
						for (let i = 0; i < playersArray.length; i++) {
							playersArray[i].previousPoints = playersArray[i].points;
							playersArray[i].rank = i + 1;
							delete playersArray[i].selectedAnswer;
							delete playersArray[i].answerProgress;
						}
					})
				),

			skipToNextSlide: () =>
				set(
					produce((state: PlayState) => {
						state.paused = false;

						state.playlistSkip = false;

						state.slideSkipToNext = true;
						state.slideLoaded = false;
						state.slideComplete = false;
						state.slideWinnerRevealed = false;
					})
				),

			players: new Map(),
			teams: new Map(),

			updateTeams: (callback = null) =>
				set(
					produce((state) => {
						if (callback) {
							callback(state.teams);
						}
					})
				),

			updatePlayer: (connectionId, callback = null, create = true) =>
				set(
					produce((state) => {
						if (connectionId) {
							let player = null;
							if (state.players.has(connectionId)) {
								player = state.players.get(connectionId);
							} else if (create) {
								player = {
									connectionId,
									disconnected: true,
									spectating: false,
									name: null,
									avatar: null,
									points: 0,
									previousPoints: 0,
									history: {},
									typeAnswerIsCorrect: null,
									typeAnswerValue: "",
									rank: undefined,
									wrongTypeAnswerSubmitted: null,
								};
								state.players.set(connectionId, player);
							}

							if (player && callback) {
								callback(player);
							}
						}

						state.haveLocalPlayer = state.players.has(PLAYING_HOST_CONNECTION_ID);
					})
				),

			updatePlayers: (callback = null) =>
				set(
					produce((state) => {
						if (callback) {
							callback(state.players);
						}

						state.haveLocalPlayer = state.players.has(PLAYING_HOST_CONNECTION_ID);
					})
				),

			removePlayer: (connectionId) =>
				set(
					produce((state) => {
						state.players.delete(connectionId);

						state.haveLocalPlayer = state.players.has(PLAYING_HOST_CONNECTION_ID);
					})
				),

			quickMode: false,
			setQuickMode: (quickMode) => set({ quickMode }),

			aiMode: false,
			setAiMode: (aiMode) => set({ aiMode }),

			aiGenerating: false,
			setAiGenerating: (aiGenerating) => set({ aiGenerating }),

			previewMode: false,

			quizId: null,
			setQuizId: (quizId: string) => set({ quizId }),

			sentGuestState: {},
			setSentGuestState: (sentGuestState) => set({ sentGuestState }),

			voteMode: null,
			setVoteMode: (voteMode) => set({ voteMode }),

			set: (recipe) => set(produce(recipe)),
		}),
		{
			name: "play-storage",
			getStorage: () => sessionStorage,

			serialize: (data) => {
				return JSON.stringify({
					...data,
					state: {
						...data.state,
						players: Array.from(data.state.players as Map<string, Player>),
						teams: Array.from(data.state.teams as Map<string, Team>),
					},
				});
			},
			deserialize: (value) => {
				const data = JSON.parse(value);
				data.state.players = new Map(data.state.players);
				data.state.teams = new Map(data.state.teams);
				return data;
			},
		}
	)
);

export default usePlayStore;
