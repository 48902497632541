import create from "zustand";

import {
	connect,
	disconnect,
	resetSessionStorage,
	getConnectionTimestamp,
	sendRoomMessage,
	sendRoomSettings,
	createRoom,
	joinRoom,
	closeCurrentRoom,
	on,
	off,
	addEventListener,
	removeEventListener,
	STATE_UPDATE,
	ROOM_JOIN_SLAVE,
	ROOM_LEAVE_SLAVE,
} from "@/webSocket/webSocket";

import usePlayStore from "@/stores/play";
import { PLAY_STATUS_LOBBY } from "@/constants";
import useStatusWithProgressStore from "./statusWithProgress";

const CONNECTION_TYPE_NONE = "none";

const useWebSocketStore = create((set) => {
	const onStateUpdate = (message) => {
		if (message) {
			set({
				connected: message.connected,
				reconnecting: message.reconnecting,
				connectionId: message.connectionId,
				connectionType: message.connectionType,
				roomId: message.roomId,
				roomJoined: message.roomJoined,
				country: message.country,
			});
		}
	};

	const onRoomJoinSlave = (message) => {};

	const onRoomLeaveSlave = (message) => {
		if (message && message.connectionId) {
			if (useStatusWithProgressStore.getState().name === PLAY_STATUS_LOBBY) {
				usePlayStore.getState().removePlayer(message.connectionId);
				/*
				// Disconnected is set in HostPage.jsx from ROOM_CLIENTS
			} else {
				usePlayStore.getState().updatePlayer(
					message.connectionId,
					(player) => {
						player.disconnected = true;
					},
					false
				);
				*/
			}
		}
	};

	return {
		connected: false,
		reconnecting: false,
		connectionId: null,
		connectionType: CONNECTION_TYPE_NONE,
		roomId: null,
		roomJoined: false,
		country: null,

		connect: (url) => {
			connect(url);

			on(STATE_UPDATE, onStateUpdate);
			on(ROOM_JOIN_SLAVE, onRoomJoinSlave);
			on(ROOM_LEAVE_SLAVE, onRoomLeaveSlave);
		},

		disconnect: (resetPlayers = true) => {
			disconnect(resetPlayers);

			usePlayStore.getState().reset(null, resetPlayers);
			useStatusWithProgressStore.getState().reset();

			off(STATE_UPDATE, onStateUpdate);
			off(ROOM_JOIN_SLAVE, onRoomJoinSlave);
			off(ROOM_LEAVE_SLAVE, onRoomLeaveSlave);
		},

		resetSessionStorage: () => void resetSessionStorage(),

		getConnectionTimestamp: () => getConnectionTimestamp(),
		sendRoomMessage: (message, connectionId = null) => sendRoomMessage(message, connectionId),
		sendRoomSettings: (settings) => sendRoomSettings(settings),
		createRoom: () => createRoom(),
		joinRoom: (roomId, spectating = false) => joinRoom(roomId, spectating),
		closeCurrentRoom: (roomId) => closeCurrentRoom(roomId),

		on: (type, listener) => on(type, listener),
		off: (type, listener) => off(type, listener),

		addEventListener: (type, listener) => addEventListener(type, listener),
		removeEventListener: (type, listener) => removeEventListener(type, listener),
	};
});

export default useWebSocketStore;
