import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import trans from "@/helpers/trans";
import SearchIcon from "@/images/icons/icon-search.svg";
import ClearIcon from "@/images/icons/icon-crosscircle.svg";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import { useEffect } from "react";

export default forwardRef(function SearchInput(
	{
		className,
		placeholder,
		value,
		onChange,
		onSearch,
		autoFocus = false,
		onReset,
		inputClassName,
		iconOpacity = 0.7,
		searchButtonClassName = "",
		searchIconClassName = "",
		type = "search",
		...props
	},
	ref
) {
	const [hasSearchText, setHasSearchText] = useState(false);
	const inputRef = useRef(null);
	const formRef = useRef(null);

	useImperativeHandle(ref, () => ({
		get form() {
			return formRef.current;
		},
		get input() {
			return inputRef.current;
		},
	}));

	useEffect(() => {
		if (value !== null && value !== undefined && value.length === 0) {
			setHasSearchText(false);
		}
	}, [value]);

	return (
		<form
			ref={formRef}
			action="."
			onSubmit={(ev) => {
				ev.preventDefault();
				if (onSearch) {
					onSearch(inputRef.current.value);
				}
			}}
			onReset={(event) => {
				setHasSearchText(false);
				if (inputRef.current.value) {
					inputRef.current.value = "";
				}
				if (onChange) {
					onChange(event);
				}
				if (onReset) {
					onReset();
				}
			}}
			className={tailwindCascade("flex flex-row w-full py-4 space-x-4 text-base text-white", className)}
			{...props}
		>
			<div className={tailwindCascade("relative flex flex-row w-full")}>
				<input
					ref={inputRef}
					name="searchTerms"
					className={tailwindCascade(
						"placeholder-white",
						"focus:placeholder-opacity-0",
						"flex-grow",
						"h-12",
						"px-4",
						"py-2",
						"text-left",
						"placeholder-opacity-50",
						"rounded-md",
						inputClassName
					)}
					type={type}
					inputMode="search"
					placeholder={placeholder || trans("Search")}
					value={value}
					onChange={(event) => {
						if (event.target.value.length > 0) {
							setHasSearchText(true);
						} else {
							setHasSearchText(false);
						}
						if (onChange) {
							onChange(event);
						}
					}}
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus={autoFocus}
				/>
				{hasSearchText && (
					<button type="reset">
						<ClearIcon
							className="top-1/2 right-10 absolute h-8 transform -translate-y-1/2"
							style={{ opacity: iconOpacity }}
						/>
					</button>
				)}
				<button
					className={searchButtonClassName}
					type="button"
					onClick={() => {
						if (onSearch) {
							onSearch(inputRef.current.value);
						}
					}}
				>
					<SearchIcon
						className={tailwindCascade(
							"top-1/2",
							"right-2",
							"absolute",
							"h-8",
							"transform",
							"-translate-y-1/2",
							searchIconClassName
						)}
						style={{ opacity: iconOpacity }}
					/>
				</button>
			</div>
		</form>
	);
});
