import isNumber from "lodash/isNumber";

import { deleteData, getData, getData2, patchData, postData, postDataForm } from "@/api/helpers";

import isUUID from "@/helpers/isUUID";
import trans from "@/helpers/trans";

import { MAX_UPLOAD_SIZE, VOICES } from "@/app-constants.mjs";

export async function apiGetQuiz(id) {
	if (!isUUID(id)) {
		throw `Invalid Quiz ID: ${id}`;
	}
	return await getData(`/quiz/${id}/`);
}

export function apiGetQuiz2(id, query = {}) {
	if (!isUUID(id)) {
		throw `Invalid Quiz ID: ${id}`;
	}
	return getData2(`/quiz/${id}/`, query);
}

export async function apiGetPublicQuiz(id) {
	if (!isUUID(id)) {
		throw `Invalid Quiz ID: ${id}`;
	}
	return await getData(`/quiz/public/${id}/`);
}

export function apiGetPublicQuiz2(id, query = {}) {
	if (!isUUID(id)) {
		throw `Invalid Quiz ID: ${id}`;
	}
	return getData2(`/quiz/public/${id}/`, query);
}

export async function apiGetGeneratorQuiz(params) {
	return await getData("/generator/", params);
}

export async function apiCreateQuiz(data) {
	try {
		const quiz = await postData("/quiz/", data);
		return quiz;
	} catch (error) {
		return null;
	}
}

export async function apiDeleteReportedQuiz(id) {
	try {
		const result = await deleteData(`/quiz/report/${id}`, {});
		return result;
	} catch (error) {
		alert(error);
	}
}

export async function apiGetReportedQuizzes() {
	try {
		const result = await getData("/quiz/report/");
		return result;
	} catch (error) {
		alert(error);
	}
}

export async function apiReportQuiz(id, data) {
	try {
		const result = await postData("/quiz/report/", data, false);
		return result;
	} catch (error) {
		alert(error);
	}
}

export async function apiDeleteQuiz(id) {
	try {
		const result = await deleteData(`/quiz/${id}/`, {});
		return result;
	} catch (error) {
		alert(error);
	}
}

export async function apiGetQuizzes(params, authTokenRequired = true, redirect = true) {
	const quizzes = await getData("/quiz/", params, authTokenRequired, redirect);
	return quizzes;
}

export async function apiGetPublicQuizzes(params) {
	const quizzes = await getData("/quiz/public/", params);
	return quizzes;
}

export async function apiGetReviewQuizzes(params) {
	const quizzes = await getData("/quiz/review/", params);
	return quizzes;
}

export function apiGetPublicQuizzes2(params) {
	return getData2("/quiz/public/", params);
}

export async function apiGetRandomQuizzes(params) {
	const quizzes = await getData("/quiz/random/", params);
	return quizzes;
}

export async function apiGetPrivateQuizzes(params) {
	const quizzes = await getData("/quiz/private/", params);
	return quizzes;
}

export function apiGetPrivateQuizzes2(params) {
	return getData2("/quiz/private/", params);
}

export async function apiGetAIQuizzes(params) {
	const quizzes = await getData("/quiz/ai/", params);
	return quizzes;
}

export async function apiCreateSession(data) {
	return await postData(`/quiz/startGame/`, data);
}

export async function apiUpdateSession(data) {
	return await postData(`/quiz/endGame/`, data);
}

export async function apiPostQuizRating(data) {
	if (isNumber(data.rating) && data.rating > 0) {
		await postData(`/quiz/rate/`, data);
	}
}

export async function apiGetSlide(slideId) {
	if (!isUUID(slideId)) {
		throw `Invalid Slide ID: ${slideId}`;
	}

	const slide = await getData(`/slide/${slideId}/`);
	return slide;
}

export async function apiGetSlides(params) {
	const result = await getData("/slide/", params);
	return result;
}

export async function apiGetRandomSlides(data) {
	const result = await postData("/slide/random/", data);
	return result;
}

export async function apiPostSavePlayedSlide(data) {
	const result = await postData("/slide/save/", data);
	return result;
}

export async function apiPostSlideResults(data) {
	return await postData("/slide/results/", data);
}

export async function apiCreateSlide(quizId, props) {
	try {
		const slide = await postData("/slide/", {
			quizId,
			...props,
		});
		return slide;
	} catch (error) {
		alert(error);
	}
}

export async function apiUpdateSlide(slideId, quizId, props) {
	const slide = await patchData(`/slide/${slideId}/`, {
		quizId,
		...props,
	});
	return slide;
}

export async function apiUpdateSlides(propsArray) {
	return await patchData(`/slide/many`, propsArray);
}

export async function apiDeleteSlide(slideId) {
	try {
		const result = await deleteData(`/slide/${slideId}/`);
		return result;
	} catch (error) {
		alert(error);
	}
}

export async function apiUpdateQuiz(quizId, properties) {
	try {
		const result = await patchData(`/quiz/${quizId}/`, properties);
		return result;
	} catch (error) {
		alert(error);
	}
}

export async function apiApproveQuiz(quizId, properties) {
	try {
		const result = await postData(`/quiz/approve/${quizId}/`, properties);
		return result;
	} catch (error) {
		alert(error);
	}
}

export async function apiUploadFile(file) {
	if (file.size > MAX_UPLOAD_SIZE) {
		throw trans("Max %.1f MB allowed", MAX_UPLOAD_SIZE / 1024 / 1024);
	}
	try {
		const result = await postDataForm("/file/", { file });
		return result;
	} catch (error) {
		alert(error);
	}
}

export async function apiUploadPaint(file) {
	if (file.size > MAX_UPLOAD_SIZE) {
		throw trans("Max %.1f MB allowed", MAX_UPLOAD_SIZE / 1024 / 1024);
	}
	try {
		const result = await postDataForm("/file/paint/", { file });
		return result;
	} catch (error) {
		alert(error);
	}
}

export async function apiGetAudioClip(voice, text) {
	return await getData(`/tts/`, { voice, text });
}

export async function apiGetCachedAudioClip(voice, text) {
	return await getData(`/tts/cached/`, { voice, text });
}

export async function apiPatchAudioClip(voice, text) {
	return await patchData(`/tts/`, { voice, text }, true, false);
}

export async function apiCloneQuiz(id) {
	const quizzes = await getData(`/quiz/clone/${id}/`);
	return quizzes;
}

export async function apiExportQuiz(id) {
	const quizzes = await getData(`/quiz/export/${id}/`);
	return quizzes;
}

export async function apiGetPlayedQuizzes() {
	return await getData("/quiz/played");
}

export function apiGetPlayCategory(slug, omitQuizIds = []) {
	return getData2(
		`/quiz/play/${slug}`,
		omitQuizIds && omitQuizIds.length > 0
			? {
					omitQuizIds,
			  }
			: undefined
	);
}
