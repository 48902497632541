import React, { useRef, useEffect, useCallback } from "react";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import clamp from "lodash/clamp";
import isFinite from "lodash/isFinite";

export default function Slider({ value, setValue, min, max, step, className, vertical, thumbWidth = 15, ...props }) {
	const ref = useRef(null);

	const setValueRef = useRef(setValue);
	useEffect(() => void (setValueRef.current = setValue), [setValue]);

	const onTouch = useCallback(
		(event) => {
			event.preventDefault();

			const input = event.target;
			const touch = event.changedTouches[0];
			const delta = max - min;

			const clientRect = input.getBoundingClientRect();
			const pixelLength = vertical ? clientRect.height : clientRect.width;
			const pixelPos = vertical ? clientRect.bottom - touch.clientY : touch.clientX - clientRect.left;

			// Determine left percentage
			let normalizedPos = pixelPos / pixelLength;
			const thumbSize = thumbWidth / 2 / pixelLength;

			// Factor in the thumb offset
			if (normalizedPos < 0.5) {
				normalizedPos -= (1 - normalizedPos * 2) * thumbSize;
			} else if (normalizedPos > 0.5) {
				normalizedPos += (normalizedPos - 0.5) * 2 * thumbSize;
			}

			normalizedPos = clamp(normalizedPos, 0, 1);
			let pos = delta * normalizedPos;

			// Quantize to step
			if (isFinite(step)) {
				pos = Math.round(pos / step) * step;
			}

			// eslint-disable-next-line no-param-reassign
			event.target.value = min + pos;

			event.target.dispatchEvent(new Event(event.type === "touchend" ? "change" : "input", { bubbles: true }));
		},
		[max, min, step, thumbWidth, vertical]
	);

	useEffect(() => {
		const el = ref.current;
		if (el) {
			el.addEventListener("touchstart", onTouch, false);
			el.addEventListener("touchmove", onTouch, false);
			el.addEventListener("touchend", onTouch, false);

			return () => {
				el.removeEventListener("touchstart", onTouch, false);
				el.removeEventListener("touchmove", onTouch, false);
				el.removeEventListener("touchend", onTouch, false);
			};
		}
	}, [onTouch]);

	const onChange = useCallback((event) => {
		if (setValueRef.current) {
			setValueRef.current(parseFloat(event.target.value));
		}
	}, []);

	return (
		<input
			ref={ref}
			className={tailwindCascade(
				"input-range my-[10px] select-none touch-manipulation",
				"disabled:opacity-50",
				className
			)}
			type="range"
			min={min}
			max={max}
			step={step || "any"}
			value={value}
			onInput={onChange}
			onChange={onChange}
			{...props}
		/>
	);
}
