import { PADDLE_PRODUCT_ID } from "@/app-constants.mjs";

export const GOOGLE_ANALYTICS = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages

export const googleAnalyticsPageView = (url) => {
	if (typeof window !== "undefined" && window.gtag && GOOGLE_ANALYTICS) {
		window.gtag("config", GOOGLE_ANALYTICS, {
			page_path: url,
		});
	}
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
// googleAnalyticsEvent("login", "engagement", "method")
// googleAnalyticsEvent("sign_up", "engagement", "method")
// googleAnalyticsEvent("search", "engagement", "search_term")
// googleAnalyticsEvent("quiz_start", "host", "69629286-a5d8-4510-92a7-0ceb1b985208")

function event(name, parameters) {
	if (typeof window !== "undefined" && name && parameters) {
		if (window.gtag && process.env.NODE_ENV === "production") {
			window.gtag("event", name, parameters);
		} else {
			console.log(`gtag, event:`, name, parameters);
		}
	}
}

export function googleAnalyticsEvent(name, category = "general", label = null, value = null, nonInteraction = false) {
	if (typeof window !== "undefined" && window.gtag && name) {
		const parameters = {};
		if (category) {
			parameters.event_category = category;
		}
		if (label !== null) {
			parameters.event_label = label;
		}
		if (value !== null) {
			parameters.value = value;
		}
		if (nonInteraction) {
			parameters.non_interaction = true;
		}

		event(name, parameters);
	}
}

function boostEvent(name, subscriptionId = null) {
	const parameters = {
		currency: "USD",
		value: 1.99,
		items: [
			{
				item_id: `${PADDLE_PRODUCT_ID}`,
				item_name: "Boost",
				price: 1.99,
				quantity: 1,
			},
		],
	};
	if (subscriptionId) {
		parameters.transaction_id = `${subscriptionId}`;
	}
	event(name, parameters);
}

export const googleAnalyticsBoostViewItem = () => boostEvent("view_item");
export const googleAnalyticsBoostBeginCheckout = () => boostEvent("begin_checkout");
export const googleAnalyticsBoostPurchase = (subscriptionId) => boostEvent("purchase", subscriptionId);
