import create from "zustand";
import produce from "immer";
import { persist } from "zustand/middleware";
import { DEFAULT_MUSIC_VOLUME, DEFAULT_SFX_VOLUME, DEFAULT_VOICE_VOLUME, DEFAULT_MEDIA_VOLUME } from "@/constants";

const useSettingsStore = create(
	persist(
		(set) => {
			return {
				safeNames: false,
				showCode: true,
				joinOpen: true,
				instructorPaced: false,
				musicVolume: DEFAULT_MUSIC_VOLUME,
				soundVolume: DEFAULT_SFX_VOLUME,
				voiceVolume: DEFAULT_VOICE_VOLUME,
				mediaVolume: DEFAULT_MEDIA_VOLUME,
				backgroundAnimationEnabled: true,
				voiceOverride: "Default",
				leaderboardSetting: true,
				hideIncorrectTypeAnswers: false,
				muteGuest: false,
				mutePlayerNames: false,
				reducedMotion: false,
				hidePlayerCountry: false,
				banPlayerOnKick: false,
				quizSelectMode: "quiz/vote",
				numRoundsPerGame: 3,
				numQuestionsPerRound: 4,
				excludeYoutube: false,
				teamMode: false,
				set: (recipe) => set(produce(recipe)),
			};
		},
		{
			name: "settings",
		}
	)
);

export default useSettingsStore;
