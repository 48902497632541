import { isBoolean } from "lodash";
import isNumber from "lodash/isNumber";

import { getNewAccessToken } from "@/api/auth";
import { checkName } from "@/api/user";

import useAuthStore from "@/stores/auth";

import { API_URL, IMAGE_CACHE_URL } from "@/constants";
import ParseError from "@/errors/ParseError";
import ResponseError from "@/errors/ResponseError";
import UnauthorizedError from "@/errors/UnauthorizedError";

const addTrailingSlash = (url) => `${url}${url.charAt(url.length - 1) === "/" ? "" : "/"}`;

async function client(
	endpoint,
	{ body, query, ...customConfig } = {},
	authTokenRequired = true,
	redirect = true,
	signal
) {
	const headers = {};

	if (authTokenRequired) {
		const accessToken = useAuthStore.getState().accessToken;
		if (accessToken) {
			headers.Authorization = `Bearer ${accessToken}`;
		}
	}

	const config = {
		method: body ? "POST" : "GET",
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers,
		},
	};

	if (signal !== undefined) {
		config.signal = signal;
	}

	if (body) {
		if (config.headers["Content-Type"] === "application/json" && typeof body === "object") {
			config.body = JSON.stringify(body);
		} else {
			config.body = body;
		}
	}

	let encodedQueryParameters = null;
	if (config.method === "GET" && query) {
		encodedQueryParameters = Object.entries(query)
			.map((keyValue) => keyValue.map(encodeURIComponent).join("="))
			.join("&");
	}

	let url = addTrailingSlash(`${API_URL}${endpoint}`);
	if (encodedQueryParameters) {
		url = `${url}?${encodedQueryParameters}`;
	}

	let response = await fetch(url, config);

	let data = null;
	let dataIsJSON = false;
	if (response) {
		try {
			if (response.headers.get("Content-Type").startsWith("application/json")) {
				data = await response.json();
				dataIsJSON = true;
			} else {
				data = await response.blob();
			}
		} catch (error) {
			throw new ParseError("Parse error, invalid response");
		}
	}

	if (response.ok) {
		return data;
	}

	let isPrivate = false;
	if (dataIsJSON && isBoolean(data.private)) {
		isPrivate = data.private;
	}

	if (typeof window !== "undefined" && authTokenRequired && response.status === 401) {
		if (
			useAuthStore.getState().isAuthenticated &&
			(!useAuthStore.getState().user || !useAuthStore.getState().user.isVerified)
		) {
			if (redirect) {
				window.location.replace("/user/email/verify/");
			}
		} else {
			useAuthStore.getState().signOut();
			if (redirect) {
				window.location.replace("/");
			}
		}
	}
	if (isPrivate) {
		throw new ResponseError(response.status, true);
	}
	throw new ResponseError(response.status);
}

async function clientWithRefreshTokenCheck(
	endpoint,
	{ body, query, ...customConfig } = {},
	authTokenRequired = true,
	redirect = true,
	signal
) {
	let makeCall = true;
	if (authTokenRequired) {
		const expiresIn = useAuthStore.getState().expiresIn;
		if (isNumber(expiresIn)) {
			const now = Math.floor(Date.now() / 1000);
			if (expiresIn <= now) {
				const newExpiresIn = await getNewAccessToken();
				if (!newExpiresIn) {
					const signOut = useAuthStore.getState().signOut;
					signOut();
					if (redirect) {
						window.location.replace("/user/login/");
					}
					makeCall = false;
				}
			}
		}
	}
	if (makeCall) {
		return await client(endpoint, { body, query, ...customConfig }, authTokenRequired, redirect, signal);
	} else {
		throw new UnauthorizedError();
	}
}

async function getData(url, query = {}, authTokenRequired = true, redirect = true) {
	return await clientWithRefreshTokenCheck(
		url,
		{
			method: "GET",
			headers: { "Content-Type": "application/json" },
			query,
		},
		authTokenRequired,
		redirect
	);
}

function getData2(url, query = {}, authTokenRequired = true, redirect = true) {
	const controller = new AbortController();

	const promise = clientWithRefreshTokenCheck(
		url,
		{
			method: "GET",
			headers: { "Content-Type": "application/json" },
			query,
		},
		authTokenRequired,
		redirect,
		controller.signal
	);

	return { promise, abort: () => void controller.abort() };
}

async function postData(url, data, authTokenRequired = true) {
	return await clientWithRefreshTokenCheck(
		url,
		{ method: "POST", headers: { "Content-Type": "application/json" }, body: data },
		authTokenRequired
	);
}

function postData2(url, data, authTokenRequired = true, redirect = true) {
	const controller = new AbortController();

	const promise = clientWithRefreshTokenCheck(
		url,
		{ method: "POST", headers: { "Content-Type": "application/json" }, body: data },
		authTokenRequired,
		redirect,
		controller.signal
	);

	return { promise, abort: () => void controller.abort() };
}

async function postDataForm(url, data) {
	const formData = new FormData();
	for (const property in data) {
		formData.append(property, data[property]);
	}

	return await clientWithRefreshTokenCheck(url, {
		method: "POST",
		body: formData,
	});
}

async function patchData(url, data, authTokenRequired = true, redirect = true) {
	return await clientWithRefreshTokenCheck(
		url,
		{
			method: "PATCH",
			headers: { "Content-Type": "application/json" },
			body: data,
		},
		authTokenRequired,
		redirect
	);
}

async function patchDataForm(url, data) {
	const formData = new FormData();
	for (const property in data) {
		formData.append(property, data[property]);
	}

	return await clientWithRefreshTokenCheck(url, {
		method: "PATCH",
		body: formData,
	});
}

async function deleteData(url, data) {
	return await clientWithRefreshTokenCheck(url, {
		method: "DELETE",
		headers: { "Content-Type": "application/json" },
		body: data,
	});
}

function imageCacheSource(source, { width, height, transform, format }) {
	if (!source) {
		return null;
	}

	if (source.endsWith(".mp4")) {
		// videos don't take resize parameters
		return `${IMAGE_CACHE_URL}/${source}.mp4`; // yes .mp4.mp4
	}

	const queryParams = [];
	if (width) {
		queryParams.push(`width=${width}`);
	}
	if (height) {
		queryParams.push(`height=${height}`);
	}

	if (transform) {
		queryParams.push(`x=${(transform.x || 0).toFixed(3)}`);
		queryParams.push(`y=${(transform.y || 0).toFixed(3)}`);
		queryParams.push(`z=${(transform.z || 1).toFixed(3)}`);
	}

	const queryString = queryParams ? "?" + queryParams.join("&") : "";

	return `${IMAGE_CACHE_URL}/${source}.${format || "jpg"}${queryString}`;
}

export async function isUsernameAvailable(username) {
	try {
		const { isAvailable } = await checkName(username);
		return isAvailable;
	} catch (err) {
		return undefined;
	}
}

export { getData, getData2, postData, postData2, patchData, deleteData, patchDataForm, postDataForm, imageCacheSource };
