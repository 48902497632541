import React, { useRef, useState, useCallback, useEffect, useMemo, memo } from "react";
import isEqual from "lodash/isEqual";
import isFinite from "lodash/isFinite";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import { imageCacheSource } from "@/api/helpers";
import onWindowResize from "@/helpers/onWindowResize";
import { GOOGLE_MAPS_API_KEY } from "@/constants";
import cloneDeep from "lodash/cloneDeep";

function getElementSize(element) {
	if (element && isFinite(element.clientWidth) && isFinite(element.clientHeight)) {
		return { width: element.clientWidth, height: element.clientHeight };
	} else {
		return null;
	}
}

function CoverImage({
	media,
	className,
	width = null,
	height = null,
	sizes = [],
	alt = null,
	title = null,
	infoText = null,
	onLoad = null,
	onError = null,
	...props
}) {
	const imageRef = useRef(null);
	const containerRef = useRef(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isError, setIsError] = useState(false);

	if (!media) {
		media = { source: null, transform: undefined };
	}

	let source = media.source || null;
	const transform = media.transform || undefined;

	const [containerSize, setContainerSize] = useState(null);
	const [imageNaturalSize, setImageNaturalSize] = useState(null);

	const imageSize = useMemo(() => {
		if (containerSize !== null && imageNaturalSize !== null) {
			const containerAspectRatio = containerSize.width / containerSize.height;
			const imageAspectRatio = imageNaturalSize.width / imageNaturalSize.height;

			if (imageAspectRatio > containerAspectRatio) {
				return {
					height: containerSize.height,
					width: Math.floor((imageNaturalSize.width * containerSize.height) / imageNaturalSize.height),
				};
			} else {
				return {
					width: containerSize.width,
					height: Math.floor((imageNaturalSize.height * containerSize.width) / imageNaturalSize.width),
				};
			}
		} else {
			return { width: 0, height: 0 };
		}
	}, [containerSize, imageNaturalSize]);

	useEffect(() => {
		const container = containerRef.current;
		setContainerSize(getElementSize(container));

		if (container) {
			const resizeObserver = new ResizeObserver((entries) => {
				setContainerSize(getElementSize(entries[0].target));
			});

			resizeObserver.observe(container);

			return () => void resizeObserver.unobserve(container);
		}
	}, []);

	const onLoadImage = useCallback(() => {
		const image = imageRef.current;
		setImageNaturalSize(image ? { width: image.naturalWidth, height: image.naturalHeight } : null);

		setIsLoaded(true);
		if (onLoad) {
			onLoad();
		}
	}, [onLoad]);

	const setImageRef = useCallback(
		(element) => {
			imageRef.current = element;
			if (imageRef.current && imageRef.current.complete) {
				onLoadImage();
			}
		},
		[onLoadImage]
	);

	const onErrorImage = useCallback(() => {
		setIsError(true);
		if (onError) {
			onError();
		}
	}, [onError]);

	const [delayedSource, setDelayedSource] = useState(cloneDeep(source));

	useEffect(() => {
		let timeout = setTimeout(() => {
			setDelayedSource(cloneDeep(source));
			timeout = null;
		}, 1000);

		return () => {
			if (timeout) {
				clearTimeout(timeout);
				timeout = null;
			}
		};
	}, [source]);

	const picture = useMemo(() => {
		const picture = {
			src: null,
			alt: alt || media.alt || "",
			srcSet: null,
			sizes: null,
		};

		const srcSet = [];
		const srcSetSizes = [];

		let pictureSource = source;
		if (pictureSource && pictureSource.startsWith("giphy/")) {
			const i = pictureSource.lastIndexOf(".");
			pictureSource = (i >= 0 ? pictureSource.substring(0, i) : pictureSource) + ".gif";
		}

		if (pictureSource && pictureSource.startsWith("youtube/")) {
			picture.src = `https://i.ytimg.com/vi/${pictureSource.substring(8)}/hqdefault.jpg`;
		} else if (pictureSource?.startsWith("street/")) {
			// Use delayed source to reduce API calls in slide editor
			const [, lat, lng, heading, pitch, zoom] = delayedSource.split("/");
			picture.src =
				"https://maps.googleapis.com/maps/api/streetview?" +
				[
					`size=${2 * width}x${2 * height}`,
					`location=${lat},${lng}`,
					`fov=${(Math.atan(Math.pow(2, 1 - zoom)) * 360) / Math.PI}`,
					`heading=${heading}`,
					`pitch=${pitch}`,
					`key=${GOOGLE_MAPS_API_KEY}`,
				].join("&");
		} else if (pictureSource) {
			let maxWidth = width;
			let maxHeight = height;
			if (!width || !height) {
				if (sizes.length > 0) {
					for (let i = 0; i < sizes.length; i++) {
						const sizeWidth = sizes[i].width;
						const sizeHeight = sizes[i].height;
						const minWidth = sizes[i].minWidth;

						srcSet.push(
							`${imageCacheSource(pictureSource, {
								width: sizeWidth,
								height: sizeHeight,
								transform,
								format: "webp",
							})} ${sizeWidth}w`
						);

						srcSetSizes.push(`${minWidth ? `(min-width: ${minWidth}px) ` : ""}${sizeWidth}px`);

						if (sizeWidth > width) {
							maxWidth = sizeWidth;
							maxHeight = sizeHeight;
						}
					}
				} else if (sizes.length === 1) {
					maxWidth = sizes[0].width;
					maxHeight = sizes[0].height;
				}
			} else {
				srcSet.push(
					imageCacheSource(pictureSource, {
						width: maxWidth,
						height: maxHeight,
						transform,
						format: "webp",
					})
				);
			}

			picture.src = imageCacheSource(pictureSource, {
				width: maxWidth,
				height: maxHeight,
				transform,
				format: "jpg",
			});
		} else if (media && media.imgSrc) {
			picture.src = media.imgSrc;
		} else if (media && media.tempImgSrc) {
			picture.src = media.tempImgSrc;
		}

		picture.srcSet = srcSet.length > 0 ? srcSet.join(", ") : null;
		picture.sizes = srcSetSizes.length > 0 ? srcSetSizes.join(", ") : null;

		return picture;
	}, [alt, media, source, delayedSource, width, height, transform, sizes]);

	return (
		<div
			ref={containerRef}
			className={tailwindCascade(
				"relative inset-0 flex items-center justify-center w-full h-full overflow-hidden bg-black bg-opacity-0 z-1",
				{ "bg-opacity-10": !isLoaded },
				className
			)}
		>
			<picture className="absolute w-full h-full">
				{picture.srcSet && <source srcSet={picture.srcSet} sizes={picture.sizes} type="image/webp" />}
				<img
					ref={setImageRef}
					src={picture.src}
					alt={picture.alt}
					title={title}
					className={`absolute max-w-none ${isError ? "invisible" : isLoaded ? "visible" : "invisible"}`}
					onLoad={onLoadImage}
					onError={onErrorImage}
					style={{ width: `${imageSize.width}px`, height: `${imageSize.height}px` }}
				/>
			</picture>
			{infoText && (
				<div className="rounded-tr-md absolute bottom-0 left-0 px-3 p-1.5 text-sm font-black text-white bg-black bg-opacity-60">
					{infoText}
				</div>
			)}
		</div>
	);
}

const MemorizedCoverImage = memo(CoverImage, isEqual);
export default MemorizedCoverImage;
