import React, { useCallback, useEffect, useState, useRef } from "react";
import useRefMounted from "@/hooks/useRefMounted";
import trans from "@/helpers/trans";
import { useRouter } from "next/router";
import crawlers from "crawler-user-agents";

const naivePattern = /bot|spider|crawl|http|lighthouse/i;
function getInitialCookieConsentVisible() {
	if (typeof window === "undefined") {
		return false;
	}

	if (window.localStorage.getItem("cookieConsent")) {
		return false;
	}

	const userAgent = window.navigator.userAgent;
	if (userAgent && naivePattern.test(userAgent)) {
		for (const entry of crawlers) {
			if (RegExp(entry.pattern).test(userAgent)) {
				return false;
			}
		}
	}

	return true;
}

export default function CookieConsent({ onUpdate }) {
	const [mounted] = useRefMounted();
	const router = useRouter();

	const [cookieConsentVisible, setCookieConsentVisible] = useState(getInitialCookieConsentVisible());

	useEffect(() => {
		if (typeof window !== "undefined") {
			if (cookieConsentVisible) {
				window.localStorage.removeItem("cookieConsent");
			} else {
				window.localStorage.setItem("cookieConsent", true);
			}
		}
	}, [cookieConsentVisible]);

	const onUpdateRef = useRef(onUpdate);
	useEffect(() => void (onUpdateRef.current = onUpdate), [onUpdate]);

	useEffect(() => {
		if (onUpdateRef.current) {
			onUpdateRef.current(cookieConsentVisible);
		}
	}, [mounted, cookieConsentVisible]);

	const hide = useCallback(() => void setCookieConsentVisible(false), []);

	useEffect(() => {
		// hide when you navigate to different page
		const handleRouteChange = (url, { shallow }) => void hide();
		router.events.on("routeChangeComplete", handleRouteChange);
		return () => void router.events.off("routeChangeComplete", handleRouteChange);
	}, [hide, router]);

	return (
		mounted &&
		cookieConsentVisible && (
			<button className="print:hidden bg-[#EBDAC3] flex w-full md:py-4 py-2 relative" onClick={hide}>
				<div className="z-1 container relative mx-auto">
					<div className="md:flex-row md:gap-6 md:px-4 md:text-base flex flex-col items-center justify-center gap-4 px-2 text-sm font-bold text-black">
						<span
							dangerouslySetInnerHTML={{
								__html: trans(
									"To make Quiz.com work, we log user data. By using Quiz.com you agree to our %sPrivacy Policy%s, including cookie policy.",
									`<a class="underline" href="/privacy/">`,
									"</a>"
								),
							}}
						/>
						<div className="flex flex-row items-center gap-2">
							<img
								src="/images/icons/cookie-multicolor.svg"
								width="32"
								height="32"
								alt=""
								draggable={false}
								className="block w-8 h-8"
							/>
							<div className="whitespace-nowrap font-black underline">{trans("Hide this")}</div>
						</div>
					</div>
				</div>
				<div className="-shadow-inner-soft-2 opacity-10 absolute top-0 left-0 z-0 w-full h-full" />
			</button>
		)
	);
}
