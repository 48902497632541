import has from "lodash/has";
import isObject from "lodash/isObject";
import { getWebSocketWorker } from "@/webSocket/webSocketWorker";
import { setTimeout, clearTimeout, setInterval, clearInterval } from "@/helpers/workerTimers";

const CONNECTION_OPEN = "connection/open";
const CONNECTION_CREATE = "connection/create";
const CONNECTION_PING = "connection/ping";
const CONNECTION_PONG = "connection/pong";

const CONNECTION_TYPE_NONE = "none";
const CONNECTION_TYPE_MASTER = "master";
const CONNECTION_TYPE_SLAVE = "slave";

export const ROOM_CREATE = "room/create";
export const ROOM_JOIN = "room/join";
export const ROOM_LEAVE = "room/leave";
export const ROOM_MESSAGE = "room/message";
export const ROOM_CLOSE = "room/close";
export const ROOM_CLIENTS = "room/clients";
export const ROOM_FULL = "room/full";
export const ROOM_CLOSED = "room/closed";
export const ROOM_SETTINGS = "room/settings";

export const ROOM_SETTINGS_OPEN = "open";

export const ROOM_JOIN_SLAVE = `${ROOM_JOIN}/slave`;
export const ROOM_LEAVE_SLAVE = `${ROOM_LEAVE}/slave`;

export const STATE_UPDATE = "state/update";

const RECONNECT_TIMEOUT = 5000;
const PING_INTERVAL = 5000;
const ROOM_CLIENTS_INTERVAL = 10000;

const WEBSOCKET_CONNECTING = 0; // WebSocket.CONNECTING
const WEBSOCKET_OPEN = 1; // WebSocket.OPEN
const WEBSOCKET_CLOSING = 2; // WebSocket.CLOSING
const WEBSOCKET_CLOSED = 3; // WebSocket.CLOSED

const SESSION_STORAGE_NAME = "websocket";

const sessionStorage = typeof window !== "undefined" && window.sessionStorage ? window.sessionStorage : null;

function setSessionStorageItem(key, value) {
	if (sessionStorage) {
		let storage = null;
		try {
			storage = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME));
		} catch (error) {
			console.error(error);
		}
		storage = isObject(storage) ? storage : {};
		storage[key] = value;
		sessionStorage.setItem(SESSION_STORAGE_NAME, JSON.stringify(storage));
	}
}

function getSessionStorageItem(key) {
	if (sessionStorage) {
		let storage = null;
		try {
			storage = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME));
		} catch (error) {
			console.error(error);
		}
		if (isObject(storage) && has(storage, key)) {
			return storage[key];
		}
	}
	return null;
}

function generateUUID() {
	var d = Date.now();
	var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = Math.random() * 16; // Random number between 0 and 16
		if (d > 0) {
			// Use timestamp until depleted
			r = (d + r) % 16 | 0;
			d = Math.floor(d / 16);
		} else {
			// Use microseconds since page-load if supported
			r = (d2 + r) % 16 | 0;
			d2 = Math.floor(d2 / 16);
		}
		return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
}

let socket = null;
let previousUrl = null;
const timestampTokens = new Map();
const listeners = {};
const messageQueue = [];

let connectionTimestampDiff = 0;
let connectionLag = 0;
let connectionType = CONNECTION_TYPE_NONE;
let connectionId = getSessionStorageItem("connectionId");
let connectionRefreshToken = getSessionStorageItem("connectionRefreshToken");

let country = getSessionStorageItem("country");

let connected = false;
let reconnecting = false;

let currentRoomId = getSessionStorageItem("roomId");
let currentRoomRefreshToken = getSessionStorageItem("roomRefreshToken");
let currentRoomJoined = getSessionStorageItem("roomJoined");

let pingInterval = null;
let roomClientsInterval = null;
let reconnectTimeout = null;

function createTimestampToken() {
	const timestampToken = generateUUID().replace(/-/g, "");
	timestampTokens.set(timestampToken, Date.now());
	return timestampToken;
}

export function getConnectionTimestamp() {
	return Date.now() + connectionTimestampDiff;
}

function clearPingInterval() {
	if (pingInterval) {
		clearInterval(pingInterval);
		pingInterval = null;
	}
}

function restartPingInterval() {
	clearPingInterval();

	if (socket && socket.readyState === WEBSOCKET_OPEN) {
		pingInterval = setInterval(() => {
			if (socket && socket.readyState === WEBSOCKET_OPEN) {
				try {
					socket.send(JSON.stringify([CONNECTION_PING, { timestampToken: createTimestampToken() }]));
				} catch (error) {
					console.error(error);
				}
			} else {
				clearPingInterval();
			}
		}, PING_INTERVAL);
	}
}

function clearRoomClientsInterval() {
	if (roomClientsInterval) {
		clearInterval(roomClientsInterval);
		roomClientsInterval = null;
	}
}

function restartRoomClientsInterval() {
	clearRoomClientsInterval();

	if (currentRoomId && currentRoomRefreshToken && currentRoomJoined) {
		roomClientsInterval = setInterval(() => {
			if (currentRoomId && currentRoomRefreshToken && currentRoomJoined) {
				send(ROOM_CLIENTS, {
					roomId: currentRoomId,
					refreshToken: currentRoomRefreshToken,
					clientTimestamp: Date.now(),
					timestampToken: createTimestampToken(),
				}); // Get connected clients
			} else {
				clearRoomClientsInterval();
			}
		}, ROOM_CLIENTS_INTERVAL);
	}
}

function clearReconnectTimeout() {
	if (reconnectTimeout) {
		clearTimeout(reconnectTimeout);
		reconnectTimeout = null;
	}
}

export function resetSessionStorage() {
	setSessionStorageItem("connectionId", (connectionId = null));
	setSessionStorageItem("connectionRefreshToken", (connectionRefreshToken = null));
	setSessionStorageItem("roomId", (currentRoomId = null));
	setSessionStorageItem("roomRefreshToken", (currentRoomRefreshToken = null));
	setSessionStorageItem("roomJoined", (currentRoomJoined = null));
	setSessionStorageItem("country", (country = null));
}

export function on(type, listener) {
	if (listeners[type] === undefined) {
		listeners[type] = [];
	}

	if (listeners[type].indexOf(listener) === -1) {
		listeners[type].push(listener);
	}
}

export const addEventListener = (type, listener) => on(type, listener);

export function off(type, listener) {
	const t = listeners[type];
	if (t !== undefined) {
		const index = t.indexOf(listener);
		if (index !== -1) {
			t.splice(index, 1);
		}
	}
}

export const removeEventListener = (type, listener) => off(type, listener);

function dispatch(type, payload) {
	const t = listeners[type];

	if (t !== undefined) {
		// Make a copy, in case listeners are removed while iterating.
		const a = t.slice(0);
		for (let i = 0, l = a.length; i < l; i++) {
			a[i](payload);
		}
	}
}

function send(type, data = null) {
	restartPingInterval();

	const message = [type];
	if (data) {
		message.push(data);
	}

	if (socket && socket.readyState === WEBSOCKET_OPEN) {
		try {
			socket.send(JSON.stringify(message));
		} catch (error) {
			console.error(error);
		}
	} else {
		messageQueue.push(message);
	}
}

function sendMessageQueue() {
	if (socket && socket.readyState === WEBSOCKET_OPEN && messageQueue) {
		messageQueue.splice(0).forEach((message) => {
			try {
				socket.send(JSON.stringify(message));
			} catch (error) {
				console.error(error);
			}
		});
	}
}

export function sendRoomMessage(message, connectionId = null) {
	if (currentRoomId) {
		const roomMessage = { roomId: currentRoomId, message };
		if (connectionId) {
			roomMessage.connectionId = connectionId;
		}
		send(ROOM_MESSAGE, roomMessage);
	} else {
		console.error("⚠️ The following room message was not sent:", message);
	}
}

export function sendRoomSettings(settings) {
	if (currentRoomId && currentRoomRefreshToken && currentRoomJoined) {
		const roomSettings = {
			roomId: currentRoomId,
			refreshToken: currentRoomRefreshToken,
			settings,
		};
		send(ROOM_SETTINGS, roomSettings);
	}
}

// Master function
export function createRoom() {
	connectionType = CONNECTION_TYPE_MASTER;

	setSessionStorageItem("roomJoined", (currentRoomJoined = false));
	clearRoomClientsInterval();

	dispatch(STATE_UPDATE, {
		connected,
		reconnecting,
		connectionId,
		connectionType,
		roomId: currentRoomId,
		roomJoined: currentRoomJoined,
		country,
	});

	if (currentRoomId && currentRoomRefreshToken) {
		send(ROOM_CREATE, {
			roomId: currentRoomId,
			refreshToken: currentRoomRefreshToken,
			clientTimestamp: Date.now(),
			timestampToken: createTimestampToken(),
		}); // Rejoin
	} else {
		send(ROOM_CREATE, { timestampToken: createTimestampToken() }); // New
	}
}

// Slave function
export function joinRoom(roomId, spectating = false) {
	connectionType = CONNECTION_TYPE_SLAVE;

	setSessionStorageItem("roomJoined", (currentRoomJoined = false));

	if (roomId && roomId === currentRoomId && currentRoomRefreshToken) {
		const data = {
			roomId: currentRoomId,
			refreshToken: currentRoomRefreshToken,
			clientTimestamp: Date.now(),
			timestampToken: createTimestampToken(),
		};
		if (spectating) {
			data.spectating = true;
		}
		send(ROOM_JOIN, data); // Rejoin
	} else if (roomId) {
		const data = { roomId, timestampToken: createTimestampToken() };
		if (spectating) {
			data.spectating = true;
		}
		send(ROOM_JOIN, data);
	}
}

// Master function
export function closeCurrentRoom() {
	connectionType = CONNECTION_TYPE_MASTER;

	// TODO: Make this with HTTP request, if disconnected?
	if (currentRoomId && currentRoomRefreshToken) {
		send(ROOM_CLOSE, {
			roomId: currentRoomId,
			refreshToken: currentRoomRefreshToken,
			clientTimestamp: Date.now(),
			timestampToken: createTimestampToken(),
		});
	}
}

function onMessage(type, payload) {
	if (type === CONNECTION_PONG) {
		// Ignore
	} else if (type === CONNECTION_OPEN) {
		if (socket && socket.readyState === WEBSOCKET_OPEN) {
			if (connectionId && connectionRefreshToken) {
				socket.send(
					JSON.stringify([
						CONNECTION_CREATE,
						{
							connectionId: connectionId,
							refreshToken: connectionRefreshToken,
							clientTimestamp: Date.now(),
							timestampToken: createTimestampToken(),
						},
					])
				);
			} else {
				socket.send(JSON.stringify([CONNECTION_CREATE, { timestampToken: createTimestampToken() }]));
			}
		}
	} else if (type === CONNECTION_CREATE) {
		setSessionStorageItem(
			"connectionId",
			(connectionId = payload && payload.connectionId ? `${payload.connectionId}` : null)
		);
		setSessionStorageItem(
			"connectionRefreshToken",
			(connectionRefreshToken = payload && payload.refreshToken ? `${payload.refreshToken}` : null)
		);
		setSessionStorageItem("country", (country = payload && payload.country ? `${payload.country}` : null));

		connected = true;
		reconnecting = false;

		dispatch(STATE_UPDATE, {
			connected,
			reconnecting,
			connectionId,
			connectionType,
			roomId: currentRoomId,
			roomJoined: currentRoomJoined,
			country,
		});
	} else if (type === ROOM_CREATE) {
		// Master
		setSessionStorageItem(
			"roomId",
			(currentRoomId = payload && payload.roomId && payload.refreshToken ? `${payload.roomId}` : null)
		);
		setSessionStorageItem(
			"roomRefreshToken",
			(currentRoomRefreshToken =
				payload && payload.roomId && payload.refreshToken ? `${payload.refreshToken}` : null)
		);

		setSessionStorageItem("roomJoined", (currentRoomJoined = !!currentRoomId));
		restartRoomClientsInterval();

		dispatch(STATE_UPDATE, {
			connected,
			reconnecting,
			connectionId,
			connectionType,
			roomId: currentRoomId,
			roomJoined: currentRoomJoined,
			country,
		});

		let roomCreateMessage = null;

		if (payload.connectionId) {
			if (!roomCreateMessage) {
				roomCreateMessage = {};
			}
			roomCreateMessage.connectionId = payload.connectionId;
		}

		if (payload.roomId) {
			if (!roomCreateMessage) {
				roomCreateMessage = {};
			}
			roomCreateMessage.roomId = payload.roomId;
		}

		if (roomCreateMessage) {
			dispatch(ROOM_CREATE, roomCreateMessage);
		}
	} else if (type === ROOM_JOIN) {
		// Slave
		if (payload.refreshToken) {
			setSessionStorageItem(
				"roomId",
				(currentRoomId = payload && payload.roomId && payload.refreshToken ? `${payload.roomId}` : null)
			);
			setSessionStorageItem(
				"roomRefreshToken",
				(currentRoomRefreshToken =
					payload && payload.roomId && payload.refreshToken ? `${payload.refreshToken}` : null)
			);

			setSessionStorageItem("roomJoined", (currentRoomJoined = !!currentRoomId));

			dispatch(STATE_UPDATE, {
				connected,
				reconnecting,
				connectionId,
				connectionType,
				roomId: currentRoomId,
				roomJoined: currentRoomJoined,
				country,
			});
		}

		let roomJoinMessage = null;

		if (payload.connectionId) {
			if (!roomJoinMessage) {
				roomJoinMessage = {};
			}
			roomJoinMessage.connectionId = payload.connectionId;
		}

		if (payload.roomId) {
			if (!roomJoinMessage) {
				roomJoinMessage = {};
			}
			roomJoinMessage.roomId = payload.roomId;
		}

		if (payload.spectating) {
			if (!roomJoinMessage) {
				roomJoinMessage = {};
			}
			roomJoinMessage.spectating = true;
		}

		if (roomJoinMessage) {
			if (connectionType === CONNECTION_TYPE_MASTER) {
				dispatch(ROOM_JOIN_SLAVE, roomJoinMessage);
			}

			dispatch(ROOM_JOIN, roomJoinMessage);
		}
	} else if (type === ROOM_LEAVE) {
		if (payload && payload.roomId && payload.roomId === currentRoomId) {
			const roomLeaveMessage = {};
			if (payload.connectionId) {
				roomLeaveMessage.connectionId = payload.connectionId;
			}

			if (connectionType === CONNECTION_TYPE_MASTER) {
				dispatch(ROOM_LEAVE_SLAVE, roomLeaveMessage);
			}

			dispatch(ROOM_LEAVE, roomLeaveMessage);
		}
	} else if (type === ROOM_MESSAGE) {
		if (payload && payload.roomId && payload.roomId === currentRoomId && payload.message) {
			if (payload.connectionId) {
				dispatch(ROOM_MESSAGE, {
					connectionId: payload.connectionId,
					...payload.message,
				});
			} else {
				dispatch(ROOM_MESSAGE, payload.message);
			}
		}
	} else if (type === ROOM_CLOSE) {
		if (payload && payload.roomId && payload.roomId === currentRoomId) {
			setSessionStorageItem("roomJoined", (currentRoomJoined = false));
			if (connectionType === CONNECTION_TYPE_MASTER) {
				clearRoomClientsInterval();
			}
			dispatch(ROOM_CLOSE, {});
		}
	} else if (type === ROOM_FULL) {
		if (payload && payload.roomId && payload.roomId) {
			dispatch(ROOM_FULL, { roomId: payload.roomId });
		}
	} else if (type === ROOM_CLOSED) {
		if (payload && payload.roomId && payload.roomId) {
			dispatch(ROOM_CLOSED, { roomId: payload.roomId });
		}
	} else if (type === ROOM_CLIENTS) {
		if (payload && payload.roomId && payload.roomId === currentRoomId) {
			dispatch(ROOM_CLIENTS, payload.clients);
		}
	} else {
		console.warn("Unhandled message", type, payload);
	}
}

export function disconnect(shouldReset = true, stateUpdate = true) {
	clearPingInterval();
	clearReconnectTimeout();

	if (socket) {
		socket.onopen = null;
		socket.onerror = null;
		socket.onclose = null;
		socket.onmessage = null;
		try {
			socket.close();
		} catch (error) {
			console.error(error);
		}
		socket = null;
	}

	connected = false;
	reconnecting = false;

	setSessionStorageItem("roomJoined", (currentRoomJoined = false));
	if (connectionType === CONNECTION_TYPE_MASTER) {
		clearRoomClientsInterval();
	}

	if (shouldReset) {
		resetSessionStorage();
	}

	if (stateUpdate) {
		dispatch(STATE_UPDATE, {
			connected,
			reconnecting,
			connectionId,
			connectionType,
			roomId: currentRoomId,
			roomJoined: currentRoomJoined,
			country,
		});
	}
}

export function connect(url = null) {
	if (!url) {
		url = previousUrl;
	}
	previousUrl = url;

	disconnect(false, false);
	if (!url) {
		clearPingInterval();
		clearReconnectTimeout();
		if (connectionType === CONNECTION_TYPE_MASTER) {
			clearRoomClientsInterval();
		}
		return;
	}

	setSessionStorageItem("roomJoined", (currentRoomJoined = false));
	if (connectionType === CONNECTION_TYPE_MASTER) {
		clearRoomClientsInterval();
	}

	dispatch(STATE_UPDATE, {
		connected,
		reconnecting,
		connectionId,
		connectionType,
		roomId: currentRoomId,
		roomJoined: currentRoomJoined,
		country,
	});

	socket = getWebSocketWorker(url);
	if (socket) {
		socket.onopen = () => {
			restartPingInterval();
			clearReconnectTimeout();
			sendMessageQueue();
		};

		socket.onmessage = (event) => {
			try {
				const data = JSON.parse(event.data);
				if (data && data.length > 0) {
					const type = data[0];
					const payload = data.length > 1 ? data[1] : null;

					if (payload && payload.timestampToken && payload.serverTimestamp) {
						const timestamp = Date.now();
						if (timestampTokens.has(payload.timestampToken)) {
							const previousTimestamp = timestampTokens.get(payload.timestampToken);
							connectionLag = Math.max(Math.round((timestamp - previousTimestamp) / 2), 0);
							timestampTokens.delete(payload.timestampToken);
							connectionTimestampDiff = payload.serverTimestamp - connectionLag - previousTimestamp;
						}
					}

					onMessage(type, payload);
				}
			} catch (error) {
				console.error(error);
			}

			sendMessageQueue();
		};

		socket.onerror = () => {
			clearPingInterval();
			clearReconnectTimeout();

			if (socket) {
				socket.onopen = null;
				socket.onerror = null;
				socket.onclose = null;
				socket.onmessage = null;
				try {
					socket.close();
				} catch (error) {
					console.error(error);
				}
				socket = null;
			}

			connected = false;
			reconnecting = false;

			setSessionStorageItem("roomJoined", (currentRoomJoined = false));
			if (connectionType === CONNECTION_TYPE_MASTER) {
				clearRoomClientsInterval();
			}

			dispatch(STATE_UPDATE, {
				connected,
				reconnecting,
				connectionId,
				connectionType,
				roomId: currentRoomId,
				roomJoined: currentRoomJoined,
				country,
			});
		};

		socket.onclose = () => {
			clearPingInterval();
			clearReconnectTimeout();

			if (socket) {
				socket.onopen = null;
				socket.onerror = null;
				socket.onclose = null;
				socket.onmessage = null;
				socket = null;
			}

			if (connectionType === CONNECTION_TYPE_MASTER) {
				clearRoomClientsInterval();
			}

			reconnectTimeout = setTimeout(() => {
				reconnectTimeout = null;
				connect();
			}, RECONNECT_TIMEOUT);

			reconnecting = true;

			dispatch(STATE_UPDATE, {
				connected,
				reconnecting,
				connectionId,
				connectionType,
				roomId: currentRoomId,
				roomJoined: currentRoomJoined,
				country,
			});
		};
	}
}
