import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";

import Link from "next/link";
import { useRouter } from "next/router";
import { useImmer } from "use-immer";

import Modal from "@/components/Modal";
import Button from "@/components/interactives/Button";
import Settings from "@/components/pages/play/Settings";

import { ANSWER_RECEIVED_SOUNDS, sfx } from "@/helpers/audio";
import "@/helpers/fullscreenPolyfill";
import gsap from "@/helpers/gsap";
import { googleAnalyticsEvent } from "@/helpers/gtag";
import isApp from "@/helpers/isApp";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import useForwardRef from "@/hooks/useForwardRef";
import useRefMounted from "@/hooks/useRefMounted";
import useStatusSequence from "@/hooks/useStatusSequence";
import useStopHosting from "@/hooks/useStopHosting";

import AvatarIcon from "@/images/icons/icon-avatar.svg";
import EnterFullscreenIcon from "@/images/icons/icon-enter-fullscreen.svg";
import ExitFullscreenIcon from "@/images/icons/icon-exit-fullscreen.svg";
import NextIcon from "@/images/icons/icon-next.svg";
import PauseIcon from "@/images/icons/icon-pause.svg";
import PlayIcon from "@/images/icons/icon-play.svg";
import SettingsIcon from "@/images/icons/settings.svg";

import usePlayStore from "@/stores/play";
import useSettingModalStore from "@/stores/settingsModal";

import { BLACK, PINK } from "@/colors";
import {
	PLAYLIST_SKIP_STATUSES,
	PLAY_STATUES_SLIDE,
	PLAY_STATUS_LOBBY,
	QUIZ_VOTEMODE_ID,
	SLIDE_SKIP_STATUSES,
} from "@/constants";

import SettingsModal from "./SettingsModal";

function LogoImage() {
	return (
		<img
			src="/images/logo/quiz-multicolor.svg"
			width="80"
			height="15"
			alt={trans("Quiz.com")}
			draggable={false}
			className="md:h-8 md:w-30 sm:w-22 block w-20 h-auto"
		/>
	);
}

function Logo({ className }) {
	return (
		<div className={className}>
			{isApp ? (
				<LogoImage />
			) : (
				<Link legacyBehavior href="/" prefetch={false}>
					<a>
						<LogoImage />
					</a>
				</Link>
			)}
		</div>
	);
}

function Code({ code, className }) {
	return (
		<div className={className}>
			<span className="md:inline-block hidden pr-1 select-none">{trans("PIN")}</span>
			<span className="md:hidden inline-block select-none">#</span>
			{code &&
				`${code}`.split("").map((character, index) => (
					<span
						key={index}
						className={tailwindCascade("inline-block", {
							"mr-[0.125rem]": index === 2,
							"ml-[0.125rem]": index === 3,
						})}
					>
						{character}
					</span>
				))}
		</div>
	);
}

function QuizName({ className, children }) {
	return <div className={tailwindCascade("truncate", "select-none", className)}>{children}</div>;
}

function Players({ numberOfPlayers, playMode, className }) {
	return (
		<div
			className={tailwindCascade(
				"flex",
				"flex-row",
				"h-full",
				"items-center",
				"space-x-1",
				"select-none",
				className
			)}
		>
			<AvatarIcon className="w-5 h-5 my-1" />
			{playMode && <div>{numberOfPlayers}</div>}
			{!playMode && (
				<div>
					<span className="lg:inline-block whitespace-nowrap hidden">
						{trans("%d player(s) is still connected", numberOfPlayers)}
					</span>
					<span className="lg:hidden whitespace-nowrap inline-block pr-4">
						{trans("%d", numberOfPlayers)}
					</span>
				</div>
			)}
		</div>
	);
}

const Answers = forwardRef(function Answers({ numberOfPlayersAnswered, numberOfPlayers, className }, forwardedRef) {
	const ref = useForwardRef(forwardedRef);

	return (
		<div
			ref={ref}
			className={tailwindCascade(
				"flex",
				"flex-row",
				"h-full",
				"items-center",
				"space-x-1 select-none",
				className
			)}
		>
			<AvatarIcon className="sm:w-5 sm:h-5 w-4 h-4 my-1" />
			<div>
				<span className="inline-block pr-0.5">{numberOfPlayersAnswered}</span>
				<span className="opacity-80 inline-block">({numberOfPlayers})</span>
			</div>
		</div>
	);
});

function Slide({ currentSlide, numberOfSlides, className, quickMode, numQuestionsPerRound }) {
	return (
		<div
			className={tailwindCascade(
				"flex",
				"flex-row",
				"h-full",
				"items-center",
				"space-x-1",
				"select-none",
				className
			)}
		>
			{quickMode ? <span>{trans("Round")}</span> : <span className="sm:flex hidden">{trans("Slide")}</span>}
			<span>
				{quickMode ? Math.floor((currentSlide - 1) / numQuestionsPerRound) + 1 : currentSlide}/
				{quickMode ? Math.ceil(numberOfSlides / numQuestionsPerRound) : numberOfSlides}
			</span>
		</div>
	);
}

function PlayStorePauseButton() {
	const paused = usePlayStore((state) => state.paused);
	const setPaused = usePlayStore((state) => state.setPaused);
	const onClickPlay = useCallback(() => setPaused(true), [setPaused]);
	const onClickPause = useCallback(() => setPaused(false), [setPaused]);

	useEffect(() => {
		const onKeyDown = (event) => {
			if (event && event.keyCode === 32 && event.target === document.body) {
				setPaused(!paused);
			}
		};
		document.addEventListener("keydown", onKeyDown);
		return () => void document.removeEventListener("keydown", onKeyDown);
	}, [paused, setPaused]);

	return paused ? (
		<button onClick={onClickPause}>
			<PlayIcon className="sm:w-7 sm:h-7 w-5 h-5" />
		</button>
	) : (
		<button onClick={onClickPlay}>
			<PauseIcon className="sm:w-7 sm:h-7 w-5 h-5" />
		</button>
	);
}

function PlayStoreSkipToNextButton({ onClick }) {
	return onClick ? (
		<button
			onClick={() => {
				googleAnalyticsEvent("quiz_skip", "host");
				onClick();
			}}
		>
			<NextIcon className="sm:w-7 sm:h-7 w-5 h-5" />
		</button>
	) : (
		<div className="opacity-50">
			<NextIcon className="sm:w-7 sm:h-7 w-5 h-5" />
		</div>
	);
}

function SettingsButtonAndModal({ isHost = true, mute = false }) {
	const toggle = useSettingModalStore((state) => state.toggle);
	const onClick = useCallback(() => void toggle(), [toggle]);

	return (
		<>
			<SettingsModal isHost={isHost} mute={mute} />
			<button onClick={onClick}>
				<SettingsIcon className="sm:w-7 sm:h-7 w-5 h-5" />
			</button>
		</>
	);
}

function FullScreenButton() {
	const [fullscreenSupported, setFullscreenSupported] = useState(false);
	const [isFullscreen, setIsFullscreen] = useState(false);

	useEffect(() => {
		const documentElement = document.documentElement;

		const onFullscreenChange = () => void setIsFullscreen(!document.fullscreenElement);
		const onFullscreenError = () => void setIsFullscreen(!document.fullscreenElement);
		document.addEventListener("fullscreenchange", onFullscreenChange);
		document.addEventListener("fullscreenerror", onFullscreenError);

		setFullscreenSupported(!!(documentElement && documentElement.requestFullscreen && document.exitFullscreen));
		setIsFullscreen(!document.fullscreenElement);

		return () => {
			document.removeEventListener("fullscreenchange", onFullscreenChange);
			document.removeEventListener("fullscreenerror", onFullscreenError);
		};
	}, []);

	const onClick = useCallback(() => {
		if (fullscreenSupported) {
			const documentElement = document.documentElement;
			if (documentElement) {
				if (!document.fullscreenElement) {
					documentElement.requestFullscreen();
				} else {
					document.exitFullscreen();
				}
			}
		}
	}, [fullscreenSupported]);

	if (!fullscreenSupported) {
		return null;
	}

	return (
		<button className="sm:block hidden" onClick={onClick}>
			{isFullscreen ? (
				<EnterFullscreenIcon className="sm:w-7 sm:h-7 w-5 h-5" />
			) : (
				<ExitFullscreenIcon className="sm:w-7 sm:h-7 w-5 h-5" />
			)}
		</button>
	);
}

function DisconnectButton() {
	const stopHosting = useStopHosting();
	const onClickDisconnect = useCallback(() => void stopHosting(), [stopHosting]);

	return (
		<Button className="px-7 py-2 text-base text-white" color="pink" onClick={() => void onClickDisconnect()}>
			{trans("Stop hosting")}
		</Button>
	);
}

export default function InfoBar({
	connected = false,
	statusName = null,
	code = null,
	quizName = "",
	slideType = null,
	slideIndex = 0,
	numberOfPlayers = 0,
	numberOfPlayersAnswered = 0,
	numberOfSlides = 0,
	isHost = false,
	playMode = true,
	showCode = true,
	joinOpen = true,
	showInforBarIfDisconnected = false,
	mute = false,
	quickMode,
	numQuestionsPerRound,
	previewMode,
}) {
	const [mounted, mountedRef] = useRefMounted();

	const addSkippedSlide = usePlayStore((state) => state.addSkippedSlide);

	const isLobby = useMemo(() => statusName === PLAY_STATUS_LOBBY, [statusName]);
	const isLobbyRef = useRef(isLobby);
	useEffect(() => void (isLobbyRef.current = isLobby), [isLobby]);

	const currentSlide = useMemo(() => Math.min(numberOfSlides, slideIndex + 1), [slideIndex, numberOfSlides]);

	const { sequence } = useStatusSequence(slideType, { name: statusName, progress: 0 });
	const [showNumberOfAnswered, setShowNumberOfAnswered] = useState(sequence.indexOf(statusName) !== -1);
	useEffect(() => void setShowNumberOfAnswered(sequence.indexOf(statusName) !== -1), [sequence, statusName]);
	const showNumberOfAnsweredRef = useRef(showNumberOfAnswered);
	useEffect(() => void (showNumberOfAnsweredRef.current = showNumberOfAnswered), [showNumberOfAnswered]);

	const playModeRef = useRef(playMode);
	useEffect(() => void (playModeRef.current = playMode), [playMode]);

	const backgroundColorRef = useRef(null);
	const numberOfPlayersAnsweredShownRef = useRef(null);

	const [state, updateState] = useImmer({
		numberOfPlayers,
		numberOfPlayersAnswered,
	});
	const stateRef = useRef(state);
	useEffect(() => void (stateRef.current = state), [state]);

	useEffect(() => {
		const internalMountedRef = mountedRef;
		const backgroundColorElement = backgroundColorRef.current;
		const numberOfPlayersAnsweredShownElement = numberOfPlayersAnsweredShownRef.current;

		const numberOfPlayersAnsweredUpdated =
			numberOfPlayersAnswered > 0 && numberOfPlayersAnswered > stateRef.current.numberOfPlayersAnswered;
		const numberOfPlayersUpdated = numberOfPlayers > 0 && numberOfPlayers > stateRef.current.numberOfPlayers;

		if (showNumberOfAnsweredRef.current && playModeRef.current && numberOfPlayersAnsweredUpdated) {
			if (numberOfPlayersAnsweredUpdated && numberOfPlayersAnsweredShownElement) {
				gsap.set(numberOfPlayersAnsweredShownElement, {
					scale: 1.0,
				});
			}

			const timeline = gsap.timeline();
			timeline.add(() => void sfx.play(ANSWER_RECEIVED_SOUNDS, false, 0.4));
			if (backgroundColorElement) {
				timeline.to(backgroundColorElement, {
					duration: 0.125,
					opacity: 1.0,
					backgroundColor: PINK,
				});
			}
			if (numberOfPlayersAnsweredShownElement) {
				timeline.to(numberOfPlayersAnsweredShownElement, {
					duration: 0.25,
					scale: 2.0,
				});
			}
			timeline.add(() => {
				if (mountedRef.current) {
					updateState((draft) => void (draft.numberOfPlayersAnswered = numberOfPlayersAnswered));
				}
			});
			if (numberOfPlayersAnsweredShownElement) {
				timeline.to(numberOfPlayersAnsweredShownElement, {
					duration: 0.25,
					scale: 1.0,
				});
			}
			if (backgroundColorElement) {
				timeline.to(backgroundColorElement, {
					duration: 0.125,
					opacity: 0.3,
					backgroundColor: BLACK,
				});
			}

			timeline.play();

			return () => {
				timeline.kill();
				if (internalMountedRef.current) {
					updateState((draft) => {
						draft.numberOfPlayers = numberOfPlayers;
						draft.numberOfPlayersAnswered = numberOfPlayersAnswered;
					});
				}
			};
		} else {
			if (playModeRef.current && isLobbyRef.current && numberOfPlayersUpdated) {
				sfx.play(ANSWER_RECEIVED_SOUNDS, false, 0.3);
			}

			if (backgroundColorRef.current) {
				gsap.set(backgroundColorElement, {
					opacity: null,
					backgroundColor: null,
				});
			}

			if (numberOfPlayersAnsweredShownElement) {
				gsap.set(numberOfPlayersAnsweredShownElement, {
					scale: null,
				});
			}

			updateState((draft) => {
				draft.numberOfPlayers = numberOfPlayers;
				draft.numberOfPlayersAnswered = numberOfPlayersAnswered;
			});
		}
	}, [mountedRef, numberOfPlayers, numberOfPlayersAnswered, updateState]);

	return (connected || showInforBarIfDisconnected) && mounted ? (
		<>
			<div
				className={tailwindCascade("print:hidden md:h-12 h-9 fixed left-0 z-50 w-full", {
					"top-0": playMode,
					"bottom-0 h-12": !playMode,
				})}
			>
				<div
					ref={backgroundColorRef}
					className={tailwindCascade("absolute top-0 left-0 w-full h-full z-0 bg-pink-dark", {
						"opacity-30 bg-black": playMode,
						"animate-infobar": !playMode,
					})}
				/>
				<div className="whitespace-nowrap md:space-x-4 md:text-lg sm:space-x-2 sm:text-base relative flex flex-row items-center justify-start w-full h-full px-4 space-x-1 text-sm font-black leading-7 text-white">
					<Logo className={`lg:block pr-2 select-none ${isHost ? "hidden" : "block"}`} />
					{connected && code && showCode && joinOpen && !previewMode && (
						<Code
							className={tailwindCascade({
								"md:block hidden": !playMode,
							})}
							code={code}
						/>
					)}
					{connected && playMode && !isLobby && <QuizName className="md:block hidden">{quizName}</QuizName>}
					{connected && isLobby && <Players numberOfPlayers={numberOfPlayers} playMode={playMode} />}
					{connected && playMode && showNumberOfAnswered && !isLobby && (
						<Answers
							ref={numberOfPlayersAnsweredShownRef}
							numberOfPlayersAnswered={state.numberOfPlayersAnswered}
							numberOfPlayers={state.numberOfPlayers}
						/>
					)}
					{connected && (
						<div className="md:space-x-6 sm:space-x-4 flex flex-row items-center justify-end flex-1 h-full space-x-2">
							{playMode && !isLobby && (
								<Slide
									currentSlide={currentSlide}
									numberOfSlides={numberOfSlides}
									quickMode={quickMode}
									numQuestionsPerRound={numQuestionsPerRound}
								/>
							)}
							{playMode && !isLobby && isHost && <PlayStorePauseButton />}
							{playMode && !isLobby && isHost && (
								<PlayStoreSkipToNextButton
									onClick={
										SLIDE_SKIP_STATUSES.includes(statusName)
											? () => {
													if (PLAY_STATUES_SLIDE.includes(statusName)) {
														addSkippedSlide();
													}
													usePlayStore.getState().skipToNextSlide();
											  }
											: PLAYLIST_SKIP_STATUSES.includes(statusName)
											? () => void usePlayStore.getState().setPlaylistSkip(true)
											: undefined
									}
								/>
							)}
							{playMode && !isLobby && <SettingsButtonAndModal isHost={isHost} mute={mute} />}
							{playMode && isHost && <FullScreenButton />}
							{!playMode && <DisconnectButton />}
						</div>
					)}
				</div>
			</div>
		</>
	) : null;
}
