import create from "zustand";

const useSettingModalStore = create((set) => ({
	visible: false,
	show: () => set(() => ({ visible: true })),
	hide: () => set(() => ({ visible: false })),
	toggle: () => set((state) => ({ visible: !state.visible })),
}));

export default useSettingModalStore;
