import { WHITE } from "@/colors";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import useForwardRef from "@/hooks/useForwardRef";
import { forwardRef, useState } from "react";

export default forwardRef(function Button2(
	{ color, className, hoverClassName, children, elementType, disabled, ...props },
	forwardedRef
) {
	const ref = useForwardRef(forwardedRef);

	const [pressed, setPressed] = useState(false);

	const textColor = color ? color : WHITE;
	const Element = elementType || "button";

	return (
		<Element
			ref={ref}
			type="button"
			className={tailwindCascade(
				"rounded-lg",
				"w-full",
				"py-2.5",
				"text-lg",
				"font-bold",
				"group",
				"relative",
				"overflow-hidden",
				"touch-manipulation",
				{ "cursor-default": disabled },
				{ "opacity-80": pressed && !disabled },
				{ "opacity-50": disabled },
				className
			)}
			onMouseDown={() => {
				setPressed(true);
			}}
			onMouseUp={() => {
				setPressed(false);
			}}
			onMouseLeave={() => {
				setPressed(false);
			}}
			style={pressed ? { color: textColor } : { color: textColor }}
			{...props}
		>
			{children}

			<div
				className={tailwindCascade(
					"bg-fff",
					"absolute",
					"inset-0",
					"bg-opacity-0",
					{ "group-hover:bg-opacity-20": !disabled },
					hoverClassName
				)}
			/>
		</Element>
	);
});
