import { sprintf } from "sprintf-js";

export default function trans(text, ...args) {
	if (text && args && args.length > 0) {
		try {
			return sprintf(text, ...args);
		} catch (error) {
			console.error(error);
			return text;
		}
	}

	return text;
}
