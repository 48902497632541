import { getData, getData2, patchData, postData } from "@/api/helpers";
import { API_URL } from "@/constants";

export async function checkName(name) {
	const result = await getData(`/users/name/${name}`);
	return result;
}

export async function getUserData(uuid_or_username) {
	const result = await getData(`/users/${uuid_or_username}`);
	return result;
}

export function getUserData2(uuid_or_username) {
	return getData2(`/users/${uuid_or_username}`);
}

export async function getUserDataAdmin(uuid_or_username) {
	const result = await getData(`/users/admin/${uuid_or_username}`);

	return result;
}

export function getUserDataAdmin2(uuid_or_username) {
	return getData2(`/users/admin/${uuid_or_username}`);
}

export async function setNewPassword(token, password) {
	if (typeof window !== "undefined" && token) {
		const response = await window.fetch(`${API_URL}/users/password/`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				password: password,
			}),
		});
		if (response.ok) {
			return true;
		} else {
			return false;
		}
	}
}

export async function apiPatchUser(params) {
	const result = await patchData("/users/me", params);
}

export async function getUserSelf() {
	const user = await getData("/users/me/");
	return user;
}

export async function confirmEmail(token) {
	if (typeof window !== "undefined" && token) {
		const response = await window.fetch(`${API_URL}/users/email/confirm/`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		if (response.ok) {
			return true;
		} else {
			return false;
		}
	}
}
