import * as workerTimers from "worker-timers";

export function setTimeout(callback, delay = 0) {
	return workerTimers.setTimeout(callback, delay);
}

export function clearTimeout(id) {
	try {
		workerTimers.clearTimeout(id);
	} catch (error) {
		console.error(error);
	}
}

export function setInterval(callback, delay = 0) {
	return workerTimers.setInterval(callback, delay);
}

export function clearInterval(id) {
	try {
		workerTimers.clearInterval(id);
	} catch (error) {
		console.error(error);
	}
}

export function requestAnimationFrame(callback) {
	return setTimeout(() => callback(Date.now()), 1000 / 60);
}

export function cancelAnimationFrame(id) {
	clearTimeout(id);
}
