import { ROOM_CODE_PATTERN, ROOM_CODE_SEPARATOR, WEB_SOCKET_API_URL } from "@/constants";

export function roomCodeValid(roomCode) {
	const re = new RegExp(`^${ROOM_CODE_PATTERN}$`);
	return roomCode && re.test(roomCode);
}

export function roomExists(roomId, callback) {
	let disposed = false;

	fetch(`${WEB_SOCKET_API_URL}/room/exists/${roomId}`)
		.then((response) => {
			if (!disposed) {
				callback(response.ok);
			}
		})
		.catch(() => {
			if (!disposed) {
				callback(false);
			}
		});

	return () => void (disposed = true);
}

export function formatRoomCode(roomCode) {
	roomCode = roomCode.replace(/[^0-9]/g, "");
	return roomCode.substring(0, 3) + (roomCode.length > 3 ? ROOM_CODE_SEPARATOR + roomCode.substring(3) : "");
}
