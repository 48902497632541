const inlineWebsocketWorker = `(function(__function__){__function__(self);})(${function websocketWorker(self) {
	const CLOSE_INTERVAL = 1000;
	let socket = null;
	let closeInterval = null;
	self.onmessage = (event) => {
		if (event && event.data && event.data.length > 0) {
			const method = event.data[0];
			if (method === "connect") {
				if (socket) {
					socket.onopen = null;
					socket.onerror = null;
					socket.onclose = null;
					socket.onmessage = null;
					try {
						socket.close();
					} catch (error) {
						console.error(error);
					}
				}
				if (event.data.length > 1) {
					socket = new WebSocket(event.data[1]);
					socket.id = Date.now().toString();
					socket.onopen = () => self.postMessage(["onopen", socket.readyState]);
					socket.onmessage = (event) =>
						self.postMessage([
							"onmessage",
							socket.readyState,
							{ data: event && event.data ? event.data : null },
						]);
					socket.onerror = () => self.postMessage(["onerror", socket.readyState]);
					socket.onclose = () => self.postMessage(["onclose", socket.readyState]);
				}
			} else if (method === "send") {
				if (socket && event.data.length > 1) {
					try {
						socket.send(event.data[1]);
					} catch (error) {
						console.error(error);
					}
				}
			} else if (method === "close") {
				self.onmessage = null;

				if (socket) {
					socket.onopen = null;
					socket.onerror = null;
					socket.onmessage = null;
					socket.onclose = () => {
						if (closeInterval) {
							clearInterval(closeInterval);
							closeInterval = null;
						}
						self.close();
					};

					if (closeInterval) {
						clearInterval(closeInterval);
						closeInterval = null;
					}
					closeInterval = setInterval(() => {
						if (!socket || socket.readyState === WebSocket.CLOSED) {
							clearInterval(closeInterval);
							closeInterval = null;
							self.close();
						} else {
							try {
								socket.close();
							} catch (error) {
								console.error(error);
							}
						}
					}, CLOSE_INTERVAL);

					try {
						socket.close();
					} catch (error) {
						console.error(error);
					}
				} else {
					if (closeInterval) {
						clearInterval(closeInterval);
						closeInterval = null;
					}
					self.close();
				}
			}
		}
	};
}.toString()});`;

class WebSocketWorker {
	constructor(url) {
		if (window.Blob && window.URL && window.Worker) {
			console.log("Using WebSocketWorker");
			const blob = new window.Blob([inlineWebsocketWorker], { type: "application/javascript" });
			const scriptURL = window.URL.createObjectURL(blob);
			const worker = (this._worker = new window.Worker(scriptURL));

			this._readyState = WebSocketWorker.CONNECTING;

			this._onOpen = null;
			this._onMessage = null;
			this._onError = null;
			this._onClose = null;

			worker.onmessage = (event) => this._onWorkerMessage(event);

			worker.postMessage(["connect", url]);
		} else {
			console.log("Using WebSocket");
			return new WebSocket(url);
		}
	}

	_onWorkerMessage(event) {
		if (event && event.data && event.data.length > 0) {
			const callback = event.data[0];
			if (callback === "onopen") {
				this._readyState = event.data.length > 1 ? event.data[1] : WebSocketWorker.OPEN;

				if (this._onOpen) {
					this._onOpen();
				}
			} else if (callback === "onmessage") {
				if (event.data.length > 1) {
					this._readyState = event.data[1];
				}
				if (event.data.length > 2) {
					if (this._onMessage) {
						this._onMessage(event.data[2]);
					}
				}
			} else if (callback === "onerror") {
				if (event.data.length > 1) {
					this._readyState = event.data[1];
				} else if (this._readyState === WebSocketWorker.CONNECTING) {
					this._readyState = WebSocketWorker.CLOSED;
				}

				if (this._onError) {
					this._onError();
				}
			} else if (callback === "onclose") {
				this._readyState = event.data.length > 1 ? event.data[1] : WebSocketWorker.CLOSED;

				if (this._onClose) {
					this._onClose();
				}
			}
		}
	}

	get readyState() {
		return this._readyState;
	}

	set onopen(callback) {
		this._onOpen = callback;
	}
	get onopen() {
		return this._onOpen;
	}

	set onmessage(callback) {
		this._onMessage = callback;
	}
	get onmessage() {
		return this._onMessage;
	}

	set onerror(callback) {
		this._onError = callback;
	}
	get onerror() {
		return this._onError;
	}

	set onclose(callback) {
		this._onClose = callback;
	}
	get onclose() {
		return this._onClose;
	}

	send(message) {
		if (this._worker) {
			this._worker.postMessage(["send", message]);
		}
	}

	close() {
		this._readyState = WebSocketWorker.CLOSING;

		this._onOpen = null;
		this._onMessage = null;
		this._onError = null;
		this._onClose = null;

		if (this._worker) {
			this._worker.postMessage(["close"]);
			this._worker = null;
		}
	}
}

WebSocketWorker.CONNECTING = 0; // WebSocket.CONNECTING
WebSocketWorker.OPEN = 1; // WebSocket.OPEN
WebSocketWorker.CLOSING = 2; // WebSocket.CLOSING
WebSocketWorker.CLOSED = 3; // WebSocket.CLOSED

export function getWebSocketWorker(url) {
	if (typeof window !== "undefined") {
		if (window.__SOCKET__) {
			window.__SOCKET__.onopen = null;
			window.__SOCKET__.onerror = null;
			window.__SOCKET__.onclose = null;
			window.__SOCKET__.onmessage = null;

			try {
				window.__SOCKET__.close();
			} catch (error) {
				console.error(error);
			}

			window.__SOCKET__ = null;
		}
		window.__SOCKET__ = new WebSocketWorker(url);
		return window.__SOCKET__;
	}
	return null;
}
