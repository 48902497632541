import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import Link from "next/link";

import { useImmer } from "use-immer";
import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import onWindowResize from "@/helpers/onWindowResize";

import trans from "@/helpers/trans";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import CoverImage from "@/components/CoverImage";

import LinkButton from "@/components/interactives/LinkButton";
import Button from "@/components/interactives/Button";
import TopMenuSearchInput from "@/components/TopMenuSearchInput";

import useAuthStore from "@/stores/auth";
import useLayoutStore from "@/stores/layout";

import IconNavigationAdmin from "@/images/icons/navigation/icon-admin.svg";
import IconProfile from "@/images/icons/icon-profile.svg";
import IconHamburger from "@/images/icons/icon-hamburger.svg";
import IconLocked from "@/images/icons/icon-lock-locked.svg";
import IconUnlocked from "@/images/icons/icon-lock-unlocked.svg";

import useRefMounted from "@/hooks/useRefMounted";
import { RoomCodeInput } from "./interactives/JoinRoom";
import { useRouter } from "next/router";
import { sprintf } from "sprintf-js";
import { JOIN_URL } from "@/constants";

const ADD_EVENT_LISTENER_OPTIONS_PASSIVE = { passive: true };

export default function TopMenu({ isAdminPage = false, cookieConsentVisible = false, className }) {
	const [mounted] = useRefMounted();

	const user = useAuthStore((state) => state.user);
	const isAdmin = useMemo(() => user?.role === "admin", [user]);
	const adminFeaturesEnabled = useAuthStore((state) => state.adminFeaturesEnabled);

	const setLayoutStore = useLayoutStore((state) => state.set);

	const wrapperRef = useRef(null);
	const containerRef = useRef(null);

	const [state, updateState] = useImmer({
		fixedPosition: 0,
		isFixed: false,
		forceFixed: false,
		searchOpen: false,
		isDesktop: false,
	});

	const onClickNavigationCategories = useCallback(
		() =>
			void setLayoutStore((draft) => {
				draft.mobileNavigationCategoriesVisible = !draft.mobileNavigationCategoriesVisible;
			}),
		[setLayoutStore]
	);

	const onUpdateSearchInput = useCallback(
		(searchOpen) => void updateState((draft) => void (draft.searchOpen = searchOpen)),
		[updateState]
	);

	useEffect(() => {
		const update = () => {
			if (!state.forceFixed && window.innerWidth >= 768) {
				if (wrapperRef.current && containerRef.current) {
					const wrapperTop = wrapperRef.current.offsetTop;
					const wrapperHeight = wrapperRef.current.offsetHeight;
					const containerHeight = containerRef.current.offsetHeight;
					const fixedPosition = window.pageYOffset - (wrapperHeight + wrapperTop) - containerHeight;
					const shadowHeight = 8;

					updateState((draft) => {
						const isFixed = fixedPosition > -containerHeight;
						draft.fixedPosition = isFixed ? Math.min(fixedPosition - shadowHeight, 0) : 0;
						draft.isFixed = isFixed;
						draft.isDesktop = window.innerWidth >= 768;
					});
				}
			} else {
				if (wrapperRef.current) {
					const wrapperTop = wrapperRef.current.offsetTop;

					updateState((draft) => {
						draft.fixedPosition = 0;
						draft.isFixed = window.pageYOffset - wrapperTop >= 0;
						draft.isDesktop = window.innerWidth >= 768;
					});
				}
			}
		};

		update(); // Initial update

		if (wrapperRef.current && containerRef.current) {
			const disposeOnWindowResize = onWindowResize(update);
			window.addEventListener("scroll", update, ADD_EVENT_LISTENER_OPTIONS_PASSIVE);
			return () => {
				disposeOnWindowResize();
				window.removeEventListener("scroll", update, ADD_EVENT_LISTENER_OPTIONS_PASSIVE);
			};
		}
	}, [state.forceFixed, cookieConsentVisible, updateState]);

	useIsomorphicLayoutEffect(() => {
		if (containerRef.current) {
			containerRef.current.style.top = `${state.fixedPosition}px`;
		}
	}, [state.fixedPosition]);

	return (
		<>
			<nav
				ref={wrapperRef}
				className={tailwindCascade(
					"print:hidden",
					"z-35",
					"h-14",
					"relative",
					"w-full",
					{
						"md:h-32": !state.forceFixed,
					},
					className
				)}
			>
				<div
					ref={containerRef}
					className={tailwindCascade("relative", "top-0", "left-0", "w-full", "h-14", {
						"md:fixed shadow-soft-1 bg-theme": state.isFixed || state.forceFixed,
						"md:h-32": !state.isFixed && !state.forceFixed,
						fixed: state.isFixed || state.forceFixed,
					})}
				>
					<div
						className={tailwindCascade(
							"md:custom-container",
							"h-14",
							"md:h-full",
							"md:relative",
							"shadow-soft-1",
							"md:shadow-soft-0",
							"relative",
							"w-full",
							"px-2",
							"md:px-0",
							"md:mx-auto",
							"md:left-[calc(50vw-50%)]",
							{
								"bg-theme": state.isFixed || state.forceFixed,
							}
						)}
					>
						<div
							className={tailwindCascade(
								"flex",
								"flex-row",
								"items-center",
								"w-full",
								"h-full",
								"border-b-1",
								"border-transparent",
								{
									"md:border-black-15": !state.isFixed && !state.forceFixed,
								}
							)}
						>
							<Link legacyBehavior href="/" prefetch={false}>
								<a
									className={tailwindCascade(
										"block",
										"relative",
										"h-full",
										"cursor-pointer",
										"flex-shrink-0",
										"transition-[width]",
										"duration-300",
										"md:transition-none",
										"ease-in",
										"w-34",
										{
											"w-0": state.searchOpen,
											"md:w-46 lg:w-80": !state.isFixed && !state.forceFixed,
											"md:w-34": !(!state.isFixed && !state.forceFixed),
										}
									)}
								>
									<div className="absolute w-full h-full">
										<div
											className={tailwindCascade(
												"md:w-full",
												"relative",
												"flex",
												"flex-col",
												"justify-center",
												"h-full",
												"md:translate-x-0",
												"transform",
												"transition-transform",
												"duration-300",
												"md:transition-none",
												"w-32",
												{
													"-translate-x-[21rem]": state.searchOpen,
													"md:w-42 lg:w-76": !state.isFixed && !state.forceFixed,
													"md:w-32": !(!state.isFixed && !state.forceFixed),
												}
											)}
										>
											<img
												src="/images/logo/quiz-multicolor.svg"
												width="152"
												height="41"
												alt={trans("Quiz.com")}
												draggable={false}
												className="block object-contain object-left w-full h-full"
											/>
										</div>
									</div>
								</a>
							</Link>

							{mounted && (
								<div className="flex flex-row items-center justify-end flex-1 h-full space-x-2">
									{state.isDesktop && <JoinGame isFixed={state.isFixed} />}
									<TopMenuSearchInput onUpdate={onUpdateSearchInput} searchOpen={state.searchOpen} />
									<button
										className={tailwindCascade(
											"flex",
											"flex-col",
											"justify-center",
											"items-center",
											"bg-black",
											"bg-opacity-10",
											"w-10",
											"h-10",
											"flex-shrink-0",
											"rounded-full",
											"block",
											"md:hidden"
										)}
										onClick={onClickNavigationCategories}
									>
										<IconHamburger className="w-10 h-10" />
									</button>
									{!user && (
										<Link legacyBehavior href="/user/login/" prefetch={false}>
											<Button
												elementType="a"
												border={3}
												color="green-lighter"
												className="whitespace-nowrap md:px-8 md:block hidden h-10 px-6 py-0 text-base font-bold"
											>
												{trans("Sign in")}
											</Button>
										</Link>
									)}
									{user && (
										<Link legacyBehavior href="/user/library/" prefetch={false}>
											<span className="relative block">
												<a className="block cursor-pointer w-[42px] h-[42px] flex-shrink-0 overflow-hidden rounded-full text-base border-2 border-black border-solid relative">
													{user?.media && (
														<CoverImage media={user?.media} width={42} height={42} />
													)}
													{!user?.media && <IconProfile />}
												</a>

												{(user?.isActive ||
													(user?.endDate && new Date(user.endDate) >= new Date())) && (
													<img
														className="z-1 -bottom-1 -right-2 w-7 h-7 absolute block pointer-events-none"
														src="/images/icons/icon-boost.svg"
														alt={trans("Boost")}
													/>
												)}
											</span>
										</Link>
									)}
									{isAdmin && (
										<div className="flex flex-row">
											<LinkButton
												className="md:block hidden"
												isActive={isAdminPage}
												href="/admin/"
											>
												<div className="flex items-center justify-center w-5 h-10">
													<IconNavigationAdmin className="block w-5 h-5" />
												</div>
											</LinkButton>
											<button
												className="md:block hidden"
												onClick={() =>
													void useAuthStore
														.getState()
														.set(
															(state) =>
																void (state.adminFeaturesEnabled =
																	!adminFeaturesEnabled)
														)
												}
											>
												{adminFeaturesEnabled ? (
													<IconUnlocked className="hover:opacity-100 h-7 opacity-50" />
												) : (
													<IconLocked className="hover:opacity-100 h-7 opacity-50" />
												)}
											</button>
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</nav>
			{!state.isDesktop && (
				<div className="md:hidden block w-full pt-6">
					<JoinGame isFixed={false} />
				</div>
			)}
		</>
	);
}

function JoinGame({ isFixed }) {
	const router = useRouter();
	const [roomCodeError, setRoomCodeError] = useState(false);

	return (
		<div className="flex flex-1 px-4">
			<div
				className={tailwindCascade(
					"rounded-xl",
					"bg-answer4",
					"md:p-4",
					"flex",
					"flex-col",
					"items-center",
					"justify-center",
					"w-full",
					"gap-2",
					"p-4",
					"overflow-hidden",
					{
						"md:p-0": isFixed,
					}
				)}
			>
				<div
					className={tailwindCascade("justify-evenly", "flex", "flex-row", "items-center", "w-full", "px-2", {
						"py-1 px-8": isFixed,
					})}
				>
					<div
						className={tailwindCascade(
							"whitespace-nowrap",
							"flex",
							"flex-row",
							"items-center",
							"gap-4",
							"font-sans",
							"md:text-base",
							"lg:text-xl",
							"text-base",
							"font-black",
							"leading-tight",
							"tracking-normal",
							"text-black",
							"capitalize",
							{
								"text-base": isFixed,
							}
						)}
					>
						<div className="md:flex-row md:gap-2 flex flex-col items-center">
							<div className="lg:block md:hidden block">{trans("Join game?")}</div>
							<div className="lg:hidden md:block hidden">{trans("Join?")}</div>
							<div className="lg:block md:hidden block">{trans("Enter PIN:")}</div>
							<div className="lg:hidden md:block hidden">{trans("PIN:")}</div>
						</div>
						<RoomCodeInput
							placeholder="123 456"
							className="md:max-w-md justify-center w-full"
							inputClassName={tailwindCascade(
								"h-12",
								"lg:h-14",
								"text-base",
								"lg:text-xl",
								"bg-fff",
								"shadow-inner-hard-1",
								"border-black",
								"border-solid",
								"border-4",
								{
									"h-10 lg:h-10 text-base": isFixed,
									"border-error": roomCodeError,
								}
							)}
							onChange={(roomCode, isError) => {
								setRoomCodeError(isError);

								if (roomCode && !isError) {
									router.push(sprintf(JOIN_URL, roomCode.toLowerCase()));
								}
							}}
						/>
						{roomCodeError && (
							<div className="md:flex lg:text-base flex-row items-center hidden text-sm font-normal">
								{trans("Wrong PIN!")}
							</div>
						)}
					</div>
				</div>
				{roomCodeError && (
					<div className="md:hidden flex flex-row items-center text-sm font-normal text-center">
						{trans("Wrong PIN, try again!")}
					</div>
				)}
			</div>
		</div>
	);
}
