import create from "zustand";
import { apiGetDailyUsage } from "@/api/generator";
import { BOOST_STATUS_CANCEL, BOOST_STATUS_SUBSCRIBED, BOOST_TYPE_GENERATE_QUIZ } from "@/constants";
import { checkout as paddleCheckout } from "@/helpers/paddle";
import { getData, getData2 } from "@/api/helpers";

export const BOOST_DIALOG_STATE_HIDDEN = "";
export const BOOST_DIALOG_STATE_PENDING = "pending"; // initial poll to get user's subscription/usage
export const BOOST_DIALOG_STATE_PROMPT = "prompt";
export const BOOST_DIALOG_STATE_CHECKOUT = "checkout";
export const BOOST_DIALOG_STATE_VERIFY = "verify";
export const BOOST_DIALOG_STATE_ERROR = "error";

function reset() {
	return {
		dialogState: BOOST_DIALOG_STATE_HIDDEN,
		type: BOOST_TYPE_GENERATE_QUIZ,
		_callback: null,
		abort: null,
	};
}

const useBoostStore = create((set, get) => ({
	dialogState: BOOST_DIALOG_STATE_HIDDEN,
	type: BOOST_TYPE_GENERATE_QUIZ,

	_callback: null,
	abort: null,

	dailyUsage: {
		generateQuiz: 0,
		generateQuizLeft: 0,
		autoComplete: 0,
		autoCompleteLeft: 0,
	},

	show: async (type, callback) => {
		if (!callback) {
			console.error("boost dialog without callback not allowed");
			return;
		}

		const { promise, abort } = getData2("/paddle/poll");
		set({ dialogState: BOOST_DIALOG_STATE_PENDING, abort });

		promise
			.then(async ({ subscription, usage }) => {
				// console.log({ subscription, usage });
				if (subscription) {
					callback(BOOST_STATUS_SUBSCRIBED);
					set(reset());
				} else if (usage) {
					set({
						dialogState: BOOST_DIALOG_STATE_PROMPT,
						type: type,
						_callback: callback,
						dailyUsage: usage,
						abort: null,
					});
				} else {
					console.error("couldnt get subscription or usage info");
					set(reset());
				}
			})
			.catch(console.error);
	},

	checkout: async () => {
		set({ dialogState: BOOST_DIALOG_STATE_CHECKOUT });

		paddleCheckout()
			.then(async () => {
				set({ dialogState: BOOST_DIALOG_STATE_VERIFY });
				for (let i = 0; i < 10; i++) {
					const { subscription } = await getData("/paddle/poll");
					if (subscription) {
						get()._callback(BOOST_STATUS_SUBSCRIBED);
						set(reset());
						return;
					}
					await new Promise((resolve) => setTimeout(resolve, 3000));
				}
				set({ dialogState: BOOST_DIALOG_STATE_ERROR });
			})
			.catch(() => {
				get()._callback(BOOST_STATUS_CANCEL);
				set(reset());
			});
	},

	hide: (status = BOOST_STATUS_CANCEL) => {
		if (get().abort) {
			// console.log("ABORTING REQUEST");
			get().abort();
		}

		if (get().dialogState === BOOST_DIALOG_STATE_PROMPT) {
			set({ dialogState: BOOST_DIALOG_STATE_HIDDEN });
			get()._callback(status);
			set({ _callback: null });
		} else {
			set(reset());
		}
	},

	set: (callback) => set((state) => callback(state)),
}));

export default useBoostStore;
